import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import regex from "src/utils/regex";
import {
  createFinanceItem,
  deleteFinanceItem,
  updateIncome,
} from "../../store/slices/applicationFormSlice";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "lodash";
import { Button, IconButton, Stack } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import FinanceItem from "./FinanceItem";

export default function Income({ income }) {
  const dispatch = useDispatch();

  const [newIncomeItemName, setNewIncomeItemName] = useState("");

  const [fieldErrors, setFieldErrors] = useState({
    income: "",
  });

  const validationSchema = Yup.object({
    income: Yup.string()
      .optional()
      .matches(
        regex.fanciableItemNameRegex,
        "Only letters, dashes (-), forward slashes (/), commas (,), and spaces are allowed."
      )
      .max(25, "Maximum of 25 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };


 const handle = {
    income: (update) => {
      dispatch(updateIncome(update));
    }, 
    deleteIncome: async (del_data) => {
      await dispatch(
        deleteFinanceItem({
          data: { incomeItemId: del_data._id, incomeId: income._id },
          endpoint: "income",
        })
      ).unwrap();
    },
    createIncomeItem: async () => {
      try {
        if (fieldErrors["income"] === "") {
      await dispatch(
        createFinanceItem({
          data: {
            name: _.camelCase(newIncomeItemName),
            label: newIncomeItemName,
            incomeId: income._id,
          },
          endpoint: "income",
        })
      ).unwrap();
      setNewIncomeItemName("");
    }
  } catch (error) {
    const newErrors = {};
    error.inner.forEach((validationError) => {
      newErrors[validationError.path] = validationError.message;
    });
    setFieldErrors(newErrors);
  }
}
  }

  return (
    <Grid
      container
      item
      xs={3}
      spacing={0}
      style={{ alignContent: "flex-start" }}
    >
      <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>
        Income (monthly)
      </Typography>

      {/* <Grid item sm={12}> */}
      <Stack spacing={1}>
        {income?.items?.map((incomeItem) => (
          <FinanceItem
            item={incomeItem}
            handleUpdate={()=> handle.income()}
            handleDelete={()=> handle.deleteIncome(incomeItem)}
          />
        ))}

        <TextField
          id="outlined-basic"
          type="text"
          size="small"
          name={income}
          label="Add income"
          variant="outlined"
          onChange={async (event) => {
            let isValid = true;
            isValid = regex.fanciableItemNameRegex.test(event.target.value) && event.target.value?.length <= 25;
            if (!isValid) {
              await validateField('income', event.target.value);
              return;
            } else {
              setNewIncomeItemName(event.target.value)
              await validateField("income", event.target.value);
            }
          }}
          value={newIncomeItemName}
          error={fieldErrors?.income}
          helperText={fieldErrors?.income}
          placeholder="Income name"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(event) => {
                    if (newIncomeItemName) {
                      handle.createIncomeItem(event);
                    }
                  }}
                  style={{
                    background: newIncomeItemName ? "#616161" : "none",
                    color: newIncomeItemName ? "#fff" : "rgba(0, 0, 0, 0.6)",
                    height: "30px",
                  }}
                  edge="end"
                >
                  +
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Grid>
  );
}