import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import { reusableFetch } from "src/utils/useAxios";

export const getAllCustomers = createAsyncThunk(
  "customers/get",
  async (userId, { rejectWithValue }) => {
    const response = await reusableFetch(
      `customer/all`,
      "POST",
      rejectWithValue
    );
    return response;
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState: {},
  reducers: {
    saveValue: (state, action) => {
      return _.merge(state, action.payload);
    },
  },
  extraReducers: {
    [getAllCustomers.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
    },
  },
});

export const { saveValue } = customerSlice.actions;

export const customerSelector = (state) => state.customer;
