import { CardContent, Divider, Stack, Typography } from "@mui/material";
import { orderBy } from "lodash";
import React from "react";

const ActivityLogSidebar = ({ activityList, openActivity }) => {
  return (
    <div>
      <CardContent>
        <Typography style={{ marginBottom: 10 }} fontWeight={600}>
          Activity Log
        </Typography>
        <Stack>
          <Stack spacing={1} divider={<Divider></Divider>}>
            {orderBy(activityList, ["updatedAt"], ["asc"]).map((activity) => (
              <>
                <Typography style={{ fontSize: 12 }} fontWeight={600}>
                  {activity.createdAt === activity.updatedAt
                    ? "Created"
                    : "Updated"}{" "}
                  {activity.type
                    .replace(/([A-Z])/g, " $1")
                    // uppercase the first character
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })}
                </Typography>
                <Typography style={{ fontSize: 12 }} fontWeight={600}>
                  Date: {activity.updatedAt}
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  Data: {JSON.stringify(activity, null, 2)}
                </Typography>
              </>
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </div>
  );
};

export default ActivityLogSidebar;
