import criteria from "src/utils/criteria";
import { typeOfSaleList } from "src/constants"

const {
  PRIVATE_SALE,
  DEALER_SALE,
  SALE_BUYBACK_EQUITY,
  MID_TERM_REFINANCE,
  BALLOON_RESIDUAL_REFINANCE
} = typeOfSaleList

const {
  livingArrangements,
  nonGST,
  hasDeposit,
  gstRegistrationBetween,
  gstRegistrationGreaterThan,
  termIsGreaterThan,
  assetValueBetween,
  assetType,
  ageOfAssetBetween,
  ageOfAssetLessThan,
  ageOfAssetAtEndLessThan,
  loanAmountBetween,
  timeInBusinessGreaterThan,
  creditScoreGreaterThan,
  typeOfSale,
  brokerageIsBetween,
  brokerageIsEqualTo
} = criteria

export default {
  date: "01 Jul 2023",
  lender: "Plenti",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fplenti-logo.gif",
  fees: [
    {
      name: "Authorised supplier",
      value: 499,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(DEALER_SALE)],
    },
    {
      name: "Private sale",
      value: 599,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(PRIVATE_SALE)],
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 8,
      valueType: "percentage",
      criteria: [assetValueBetween(5000, 1000000)],
    },
  ],
  loading: [
    {
      name: "EV discount",
      value: -0.5,
      valueType: "percentage",
      criteria: [assetType(["Electric Motor Vehicle (<4.5 tonnes)"])],
    },
    {
      name: "Term >60",
      value: 0.50,
      valueType: "percentage",
      criteria: [termIsGreaterThan(60)],
    },
    {
      name: "Brokerage 5%",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(5)],
    },
    {
      name: "Brokerage 6%",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6)],
    },
    {
      name: "Brokerage 7%",
      value: 1.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(7)],
    },
    {
      name: "Brokerage 8%",
      value: 2,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(8)],
    },
  ],
  info: [
    `
      Property equity amount is greater than the amount being borrowed
    `,
    `
      Non asset backed, the same amount as 
      current repayments of all directors’ 
      personal and car loans, that can be used for 
      comparable credit must been running for 12 months or,
      that have been paid out in the last 6 months
    `
  ],
  productTiers: [
    {
      name: "Auto",
      rate: 8.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        typeOfSale([
          DEALER_SALE,
          SALE_BUYBACK_EQUITY,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE]
        ),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 8.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        typeOfSale([
          DEALER_SALE,
          SALE_BUYBACK_EQUITY,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE]
        ),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 9.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        typeOfSale([
          DEALER_SALE,
          SALE_BUYBACK_EQUITY,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE]
        ),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 9.84,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        typeOfSale([
          DEALER_SALE,
          SALE_BUYBACK_EQUITY,
          BALLOON_RESIDUAL_REFINANCE,
          MID_TERM_REFINANCE]
        ),
        creditScoreGreaterThan(550)
      ],
    },

    {
      name: "Auto",
      rate: 8.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        typeOfSale([PRIVATE_SALE]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 8.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        typeOfSale([PRIVATE_SALE]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 9.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        typeOfSale([PRIVATE_SALE]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 9.84,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        typeOfSale([PRIVATE_SALE]),
        creditScoreGreaterThan(550)
      ],
    },


    {
      name: "Auto",
      rate: 8.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 9.29,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 10.09,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 10.24,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },



    {
      name: "Auto",
      rate: 9.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(12),
        gstRegistrationBetween(0, 12),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 9.79,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(12),
        ageOfAssetBetween(12, 48),
        gstRegistrationBetween(0, 12),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 10.59,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(12),
        gstRegistrationBetween(0, 12),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 10.74,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(12),
        gstRegistrationBetween(0, 12),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },



    {
      name: "Auto",
      rate: 9.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(12),
        nonGST,
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 9.79,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(12),
        nonGST,
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 10.59,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(24),
        nonGST,
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 10.74,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(12),
        nonGST,
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Property owner"]),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },






    {
      name: "Auto",
      rate: 9.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 9.94,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 10.54,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 10.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 125000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },

    {
      name: "Auto",
      rate: 9.89,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 10.44,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 11.04,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 11.19,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(12, 24),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },



    {
      name: "Auto",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(0, 12),
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 10.94,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(0, 12),
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 11.54,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(0, 12),
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 11.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 40000),
        timeInBusinessGreaterThan(24),
        gstRegistrationBetween(0, 12),
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },



    {
      name: "Auto",
      rate: 10.39,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(24),
        nonGST,
        ageOfAssetLessThan(13),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 10.94,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(24),
        nonGST,
        ageOfAssetBetween(12, 48),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 11.54,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(24),
        nonGST,
        ageOfAssetBetween(48, 96),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
    {
      name: "Auto",
      rate: 11.69,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(5000, 30000),
        timeInBusinessGreaterThan(24),
        nonGST,
        ageOfAssetBetween(96, 144),
        ageOfAssetAtEndLessThan(16),
        livingArrangements(["Renting", "Boarding", "Living w/ parents"]),
        hasDeposit(20),
        assetType([
          "Motor Vehicle (<4.5 tonnes)",
          "Electric Motor Vehicle (<4.5 tonnes)"
        ]),
        creditScoreGreaterThan(550)
      ],
    },
  ],
};