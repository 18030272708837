import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import { Accordion, Box, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactSignatureCanvas from "react-signature-canvas";
import { submitPrivacy } from "src/store/slices/applicationFormSlice";

export default function Privacy() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  let sigCanvas = useRef()
  const [signature, setSignature] = useState()
  const [submitted, setSubmitted] = useState(false)

  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  console.log({ params });
  const customerId = params.cid;
  const applicationId = params.aid;
  const applicationType = params.type;

  useEffect(() => {
    // if (!customerId || !applicationId) navigate("/404")
  }, [])

  function trimSignature() {
    setSignature(sigCanvas.getTrimmedCanvas()
      .toDataURL('image/jpg'))
  }

  async function handleSubmitPrivacy() {
    await dispatch(
      submitPrivacy({ customerId, applicationId, buffer: signature, applicationType })
    ).unwrap()
    setSubmitted(true)
  }

  return <Grid
    container
    item
    xs={12}
    style={{
      padding: "30px 30px 0",
      background: "#efefef",
      minHeight: "calc(100vh - 64px)"
    }}
  >
    <Grid container item xs={12} justifyContent="center">
      <Grid item direction="column" sm={12} md={6} >

        {!submitted &&
          <>
            <Typography
              variant="h1"
              style={{
                fontSize: "24px",
                marginBottom: "20px"
              }}
            >
              Privacy Disclosure Statement
              {applicationType === "Consumer" && `& Credit Guide`}
            </Typography>
            <Typography
              variant="h2"
              style={{
                fontSize: "18px",
                marginBottom: "30px"
              }}>
              Please read the privacy statement
              {applicationType === "Consumer" && `and credit guide`}
              then sign at the bottom.
            </Typography>

            <Accordion style={{ marginBottom: "40px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: "5px 30px" }}
              >
                <Typography>Show privacy statement</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "20px 30px" }}>

                <Typography >

                  Application for Credit – Privacy Consents/ Acknowledgments
                  Privacy Act 1988 (Cth) and all related rules and regulations
                  (“Act”)

                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  To:
                  <br />
                  Financeable Pty Ltd ABN: 24 646 900 736 (“You”)
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  I acknowledge that You may collect, hold, use and disclose
                  personal information about me (including information required to
                  comply with Anti-Money Laundering and Counter-Terrorism
                  Financing Act 2006, rules and other subordinate instruments, the
                  National Consumer Credit Protection Act 2009 and the Personal
                  Property Securities Act 2009) to enable You to: assess my
                  personal and/or commercial credit worthiness; process my
                  application; provide, administer and manage the products and
                  services provided to me; audit and evaluate those products and
                  services; notify a credit reporting body or other credit
                  providers of my payment history or any default by me; model and
                  test data; communicate with me; improve and develop products and
                  services; conduct credit scoring, securitisation, research, risk
                  management and portfolio analysis; provide and administer any
                  related rewards program and deal with any complaints or
                  enquiries. I acknowledge that if I fail to provide complete
                  information or information that is inaccurate, You may not be
                  able to process or accept the application and transactions may
                  be delayed, blocked or refused.
                </Typography>

                <Typography style={{ padding: "20px 0" }} >
                  <span stlye="font-weight: 700;">
                    Authority for You to obtain certain credit information: </span>
                  I authorise You, Your agents and third parties who referred me
                  to You to seek, obtain and use, credit reporting information
                  about me (including from credit reporting bodies (“CRB”)) to:
                  assess an application by me for credit; manage my credit and
                  related funding arrangements; assess an application by me to be
                  a guarantor in relation to credit; review my credit on a
                  periodic basis as though assessing a new application; collect
                  overdue payments; and create assessment and rating of my credit
                  worthiness.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  <span style={{ fontWeight: 800 }}>
                    Authority to exchange information with credit reporting
                    bodies: </span>
                  I authorise You and Your agents to obtain credit reporting
                  information about me from a CRB or other business that provides
                  information about credit worthiness. I allow such an entity to
                  create or maintain credit information about me (before, during
                  or after the provision of credit to me). The information may
                  include: permitted identification particulars; the fact that I
                  have applied for credit and the amount and type of credit; the
                  fact that You are a credit provider to me; credit limit; loan
                  start/end dates; repayment history; ‘default information’ (in
                  addition to and without limiting repayment history information)
                  i.e. payments overdue for more than 60 days in specified
                  circumstances; in relation to those overdue payments, advice
                  about new payment arrangement or that those payments are no
                  longer overdue; in specified circumstances that in Your opinion
                  there has been a serious credit infringement (e.g. fraud); and
                  other credit worthiness information that can be disclosed under
                  the Privacy Act.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  <span style={{ fontWeight: 800 }}>
                    Authority to exchange information with other credit providers:
                  </span>
                  I authorise You to give to and obtain from Your agents (as
                  defined in the Act) and any credit providers information about
                  my credit worthiness, credit standing, credit history or credit
                  capacity, and to give and receive a banker’s opinion for
                  purposes connected with my creditworthiness, business, trade or
                  profession. The information may be exchanged for, but not
                  limited to, any of the following purposes: to assess an
                  application by me for credit or to be a guarantor, to determine
                  or confirm the status of my credit including any defaults; to
                  assess my creditworthiness at any time during or after the life
                  of a credit arrangement, to give or obtain an opinion on you;
                  and any other purpose permitted by law.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  <span style={{ fontWeight: 800 }}>
                    Authority to exchange information with other third parties: </span>
                  You may exchange my personal information with other related
                  companies; any suppliers or dealers of items the subject of my
                  application; persons with whom we have white label arrangements;
                  introducers, referrers and any other person associated with the
                  lodgement of this application; funders and related service
                  providers that assist in the processing of my application and
                  the management of my credit; as well as with service providers
                  including those relating to any associate rewards program and
                  the types of service providers described in Financeable Pty Ltd
                  Privacy Policy. Other authorised disclosures include to my
                  referees, my past and present employers, my past and present
                  landlords, my next of kin, any person acting on my behalf, (e.g.
                  financial adviser, solicitor, broker, accountant, executor,
                  administrator, trustee or guardian), rating agencies, insurers,
                  entity concerned with the supply or manufacture of assets to
                  me/applicant, valuers and debt collection agencies. You may also
                  disclose personal information to regulatory authorities (e.g.
                  tax authorities in Australia and overseas) in connection with
                  their lawful information requests or to meet legal obligations
                  in any relevant jurisdiction.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  <span style={{ fontWeight: 800 }}>Authority to exchange sensitive information: </span> In
                  some cases, sensitive information may be collected for specific
                  purposes (for example, information regarding my health to enable
                  the assessment of a hardship relief application). The references
                  in this Privacy Statement to personal information include
                  sensitive information such as my medical and health related
                  details, and I agree that You may exchange such information with
                  other parties listed in this Privacy Statement for the purpose
                  of assessing or processing such applications and may seek
                  further information from any medical attendant consulted by me.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  <span style={{ fontWeight: 800 }}>Authority to give information to guarantors: </span> I
                  authorise You to give to any person who guarantees or
                  indemnifies, or is to guarantee or indemnify, my obligations
                  (“Guarantor”) information about my credit worthiness, credit
                  standing, credit history or credit capacity for the purpose of
                  determining if that person wishes to act as a Guarantor and
                  keeping the Guarantor informed of the guarantee or indemnity,
                  and for any purposes related to the proposed or actual
                  enforcement of the guarantee or indemnity.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  <span style={{ fontWeight: 800 }}>
                    Authority to obtain information about guarantors: </span>
                  (This section addresses additional matters relevant if I am a
                  Guarantor) I authorise and consent to You or Your agents
                  collecting personal information about me and obtaining both my
                  personal and commercial credit reports from a CRB, to assess my
                  capacity as a guarantor. If I am accepted as a guarantor You may
                  disclose my personal information to external agents,
                  professional advisers and service providers, for the purpose of
                  managing the account and contacting me in relation to the
                  guarantee or indemnity provided. I also authorise You and Your
                  agents to provide to the person/s in respect of whom I am
                  Guarantor personal information about my credit worthiness,
                  credit standing, credit history or credit capacity for any
                  purposes related to the product or any proposed or actual
                  enforcement of the product, guarantee or indemnity. I
                  acknowledge and agree that if You provide the product, this
                  authority remains in force until the credit under the product is
                  fully and finally settled and discharged.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  <span style={{ fontWeight: 800 }}>
                    Authority to use information for administration processes: </span>
                  I authorise You to use any information collected in this
                  application and in subsequent administration processes for
                  future applications I may wish to make for other Financeable Pty
                  Ltd products and/or services and related services, and to
                  disclose this information to other members of the Financeable
                  Pty Ltd for similar use. Financeable Pty Ltd will only use
                  information collected for this purpose to the extent it is
                  permitted to do so at law.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  <span style={{ fontWeight: 800 }}>
                    Authority to provide information for funding purposes: </span>
                  I authorise You to disclose any personal information about me to
                  another person (including without limitation, any trustee,
                  servicer, credit enhancer, funder, ratings agency) in connection
                  with any funding of our business or any transactions including
                  by means of an arrangement involving securitisation.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  <span style={{ fontWeight: 800 }}>General Privacy Matters: </span> I acknowledge that You
                  collect personal information through interactions with me and my
                  agent(s) (including telephone, email or online), as well as from
                  public sources and third parties including information brokers
                  and service providers. Without this information, You may not be
                  able to process my application or provide me with an appropriate
                  level of service. I consent and agree that: (a) Financeable Pty
                  Ltd may give me notices or documents by electronic
                  communication, including by sending the notice or document to
                  the email address provided with my application or the last email
                  address I have otherwise notified; (b) if a notice of document
                  is so given, it will be taken to be given at the time when the
                  notice or document has entered my information system; (c) where
                  a notice or a document requires acceptance of the information
                  contained therein, such an intention can be met electronically
                  with the utilisation of an electronic signature; (d) paper
                  notices and documents may no longer be given to me; (e)
                  electronic communications should be regularly checked for
                  notices and documents; and (f) my consent to the giving of
                  notices and documents by electronic communication may be
                  withdrawn by me at any time.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  I acknowledge that where I have provided You with personal
                  information about someone else that I have obtained their
                  consent to provide their personal information based on this
                  Privacy Consent.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  I acknowledge that the third parties with whom You exchange
                  personal information whilst conducting ordinary business
                  activities, may operate outside of Australia. I understand and
                  agree that while the third parties may be subject to
                  confidentiality or privacy obligations, in relation to personal
                  information that is not ‘credit eligibility information’
                  (certain personal information from (or based on information
                  from) credit reporting bodies) they may not always follow the
                  particular requirements of Australian privacy laws.
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  You, your related companies and any third parties involved in
                  the introduction or referral of this application (including any
                  dealers or suppliers of items the subject of my application) or
                  with whom we have white label arrangements may exchange and use
                  my personal information to contact me on an ongoing basis by
                  telephone, electronic communications (like email), online and
                  other means to offer products or services that may be of
                  interest to me, including offers of banking, financial,
                  advisory, investment, insurance and funds management services,
                  and assets suited to leasing or finance (including vehicles,
                  medical equipment, computers, machinery and manufacturing
                  equipment).
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  In accordance with the Privacy Act, requests for access to and
                  correction of personal information can be made using the contact
                  details below:
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  Addressee: Privacy Officer Postal Address: PO Box 4369,
                  Castlecrag, NSW 2068
                </Typography>
                <Typography style={{ padding: "20px 0" }} >
                  By requesting a copy of Privacy Policies and (where applicable)
                  credit reporting policies, further information can be obtained
                  regarding the handling of personal information, access or
                  correction of personal information, how privacy concerns are
                  dealt with, website privacy, the credit reporting bodies used
                  and how to obtain free copies of your credit reporting
                  information from those bodies. Financeable Pty Ltd.’s Privacy
                  Policy and Credit Reporting Policy can be found at via
                  financeable.com.au. Further, Financeable Pty Ltd.’s (Privacy
                  Officer) can be contacted on ops@financeable.com.au
                </Typography>

              </AccordionDetails>
            </Accordion>

            {applicationType === "consumer" &&
              <Accordion style={{ marginBottom: "40px" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ padding: "5px 30px" }}
                >
                  <Typography>Show credit guide</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "20px 30px" }}>

                  <Typography >
                    This Credit Guide & Quote is provided by Fast Asset Finance Services Pty Ltd (ACN. 141 151 126), Credit Licence
                    number 523254. Our address is Suite 2.11, Building A, 11-13 Solent Circuit Norwest NSW 2153 and our contact details are
                    phone - (02) 8860 9188 and email - approvals@afsdirect.com.au
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}><strong>Our Credit Services:</strong></Typography>
                  <Typography style={{ marginBottom: "20px" }}>
                    We will assist you to select a loan or lease to meet your needs. We do this via:
                    <ol>
                      <li>Obtaining information from you to gain an understanding of your current financial situation and your
                        requirements
                        and objectives of the loan,</li>
                      <li>Making reasonable levels of inquiry about your financial situation and verifying the information as needed,</li>
                      <li>Where authorised by you, obtaining a copy of your credit report to assist us scale our inquiries accordingly,
                      </li>
                      <li>Obtaining further information from landlords, employers and accountants as needed,</li>
                      <li>Investigating suitable loans and providing you with information about one or more loans that meet your requirements,</li>
                      <li>Assessing that your chosen loan is not unsuitable for you,</li>
                      <li>Helping you to complete the application, compiling the documents required by the lender and submitting the application to the lender,</li>
                      <li>Liaising with the lender during the approval process and providing further information that may be required,
                      </li>
                      <li>Notifying you of the lender’s decision, and</li>
                      <li>Assisting with the finalisation of the loan as required.</li>
                    </ol>
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}><strong>Our Credit PROVIDERS:</strong></Typography>
                  <Typography style={{ marginBottom: "20px" }}>
                    The credit providers we most commonly use are Macquarie, Secure Funding (Liberty), Pepper Money, Latitude Financial
                    Services, Finance One and
                    Metro, although we do use others.
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}><strong>Information we will require from you:</strong></Typography>
                  <Typography style={{ marginBottom: "20px" }}>
                    We are obliged to ensure any loan or lease we suggest or assist you to apply for, including an increase to an
                    existing
                    credit limit, is not unsuitable for
                    your purposes. This includes if the loan or lease does not meet your requirements or objectives, if you are unable
                    to
                    make the repayments, or you
                    could only do so with substantial hardship. Therefore, we will need some information from you. It is important that
                    the
                    information you provide is
                    entirely accurate.
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}><strong>Copies of our Assessment:</strong></Typography>
                  <Typography style={{ marginBottom: "20px" }}>
                    At any time within 7 years of us providing you with credit assistance, you may request a copy of the preliminary
                    credit
                    assessment. We will provide you
                    with this documentation within 7 business days of receiving your request. However, if your request is more than 2
                    years
                    after the date of our Credit
                    Quote, we may take up to 21 business days after receiving the request.
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}><strong>Fees payable by you TO US:</strong></Typography>
                  <Typography style={{ marginBottom: "20px" }}>
                    We may charge you up to $1,990 (incl. GST) as an Origination Fee for our services when providing credit assistance,
                    although this is only payable should the loan proceed. It is a one-off fee, payable at loan settlement.
                    This fee may be paid directly to us, or part of the loan disbursements. If via loan
                    disbursement, this will increase your loan by the fee amount.
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}><strong>FEES PAYABLE BY YOU TO THIRD PARTIES:</strong></Typography>
                  <Typography style={{ marginBottom: "20px" }}>
                    When the application for a loan or lease is submitted and ultimately proceeds to settlement, you may be required to
                    pay
                    fees to third parties
                    associated with the application; such as the credit provider’s application fee, security registration fee,
                    inspection
                    fee or other fees. If applicable, these
                    will be detailed in the Credit Proposal document and provided to you before you apply for finance. You can ask us
                    how
                    they are worked out.
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}><strong>COMMISSIONS RECEIVED BY US:</strong></Typography>
                  <Typography style={{ marginBottom: "20px" }}>
                    Commissions may be payable to us by the credit provider for any loan or lease that is settled. The amount varies
                    between
                    lenders and the amount of
                    credit that you receive. These are not directly payable by you and these will be disclosed within the Credit
                    Proposal
                    document that will be provided to
                    you before applying for finance. You can ask us for an estimate of these commissions and how they are worked out.
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}><strong>COMMISSIONS PAYABLE BY US:</strong></Typography>
                  <Typography style={{ marginBottom: "20px" }}>
                    If you are referred to us by a third party, we may pay that entity a commission for the introduction of your
                    business.
                    For example, we may pay
                    commissions to real estate agents, car or boat dealerships and accountants. These commissions are not directly
                    payable
                    by you. You can ask us for
                    an estimate of these payments and how they are worked out.
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}><strong>COMPLAINTS:</strong></Typography>
                  <Typography style={{ marginBottom: "20px" }}>
                    We hope that you are pleased with our service, although if you do have a complaint you can contact our Complaints
                    Officer via phone - (02) 8860
                    9188, or email - approvals@afsdirect.com.au, or registered post to Suite 2.11, Building A, 11-13 Solent Circuit
                    Norwest
                    NSW 2153. Please provide us with as
                    much detail as possible in your complaint so we can address promptly and hopefully resolve it to your satisfaction.
                    If you are not satisfied with the outcome of our attempt to resolve your complaint, you may refer the matter to the
                    Australian Financial Complaints
                    Authority Australia on phone 1800 931 678 or by writing to GPO Box 3 Melbourne VIC 3001. The Australian Financial
                    Complaints Authority Australia is a nocharge
                    external and independent dispute resolution service.
                  </Typography>

                  <Typography style={{ marginBottom: "5px" }}><strong>YOUR ACKNOWLEDGEMENT and AGREEMENT:</strong></Typography>
                  <Typography style={{ marginBottom: "20px" }}>
                    <ul>
                      <li>I/We acknowledge that I/we have read the contents of this document and understand its content.</li>
                      <li>I/We acknowledge and agree that I/we will pay you up to the maximum quoted fees and charges for your services if
                        you obtain approval for our loan and it subsequently settles.</li>
                      <li>I/We understand that the fees in the amount of up to a maximum of $1,320 (incl. GST) will be included in the
                        loan amount or lease repaymentsunless I/we have instructed otherwise.</li>
                      <li>I/We acknowledge and provide my/our consent to receive this Credit Guide & Quote any other disclosure documents
                        such as the Credit</li>
                      <li>Proposal by electronic means. The consent I/we provided is based on my/our understanding that paper disclosure
                        documents may not be provided and therefore I/we will check nominated electronic communication means for receipt
                        of these documents.</li>
                    </ul>
                  </Typography>
                  <Typography style={{ marginBottom: "20px" }}>
                    I/We understand that I/we may withdraw my/our consent to receive these disclosure documents at any time by
                    contacting
                    the licensee as per above
                    contact details.
                  </Typography>

                </AccordionDetails>
              </Accordion>
            }

            <Grid container item xs={12} justifyContent="center" >
              <Grid item sm={12}>
                <Typography style={{ marginBottom: "30px", fontSize: "18px", fontWeight: 700 }}>
                  I confirm that I am authorised to provide the personal details presented and
                  I consent to my information being checked with the document issuer or
                  official record holder for the purpose of confirming my identity.
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Grid item sm={12}>

                <Box style={{
                  margin: "0 0 20px 0",
                  dotSize: 1,
                  // padding: "0 15px",
                  // width: "500px",
                  // borderBottom: "2px solid #333333",
                  zIndex: 10,
                  position: "relative",

                  // fontWeight: 700
                }}>Sign using the pad below:</Box>

                <ReactSignatureCanvas
                  ref={ref => sigCanvas = ref}
                  penColor='#2946bb'
                  clearOnResize={false}
                  onEnd={() => trimSignature()}
                  canvasProps={{
                    className: 'sigCanvas',
                    style: {
                      width: "100%",
                      // height: 180,
                      aspectRatio: "2/1",
                      position: "relative",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      borderRadius: "8px",
                      boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`
                    }
                  }} />
                <Box style={{
                  margin: "-50px auto 50px",
                  padding: "0",
                  width: "95%",
                  borderBottom: "2px solid #333333",
                  zIndex: 10,
                  position: "relative",

                }}>
                  {/* <Typography style={{ height: "30px" }}>Signature:</Typography> */}
                  {/* <Typography style={{ fontSize: "32px", userSelect: "none" }}>x</Typography> */}
                </Box>
                <Button variant="link" size="small" onClick={() => {
                  sigCanvas.clear()
                  setSignature(null)
                }} style={{ float: "right" }}>Clear signature</Button>
                <Grid item sm={12}>
                  <LoadingButton
                    fullWidth
                    disabled={!signature}
                    variant="contained"
                    onClick={() => handleSubmitPrivacy()}
                    // onClick={() => createPrivacy({ customerId, applicationId, signature: sigCanvas.toDataUrl() })}
                    style={{ margin: "30px 0 50px" }}
                  >
                    Accept Agreement
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        }

        {submitted &&
          <Grid container item direction="column" xs={12}>
            <Typography
              variant="h1"
              style={{
                fontSize: "24px",
                marginBottom: "30px"
              }}>
              Agreement Signed Successfully
            </Typography>
            <Typography
              variant="h2"
              style={{
                fontSize: "18px",
                marginBottom: "10px"
              }}
            >
              Too easy! You're one step closer and not far to go now.
              Enjoy the rest of your day.
            </Typography>
            <Typography
              // variant="h2"
              style={{
                fontSize: "14px",
                // marginBottom: "50px"
              }}
            >
              You can close this window.
            </Typography>
          </Grid>
        }
      </Grid>
    </Grid>
  </ Grid>
}