import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import _ from "lodash";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { updateFinanceItem } from "../../store/slices/applicationFormSlice";
import * as Yup from "yup";
import regex from "src/utils/regex";
export default function FinanceItem({ item, handleUpdate, handleDelete }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(item?.value);
  const [isHovering, setIsHovering] = useState({});

  const [fieldErrors, setFieldErrors] = useState({
    value: "",
  });

  const validationSchema = Yup.object({
    value: Yup.string()
      .optional()
      .matches(
        regex.allowOnlyNumber,
        "Only numbers and decimal points allowed."
      )
      .max(12, "Maximum of 12 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const onChangeField = async (value) => {
    let isValid = true;
    if (value === "") {
      return setValue(value);
    }

    isValid = value === '' ? true :
      regex.allowOnlyNumber.test(value) &&
      value?.length <= 12;
    if (!isValid) {
      await validateField("value", value);
      return;
    } else {
      setValue(value);
      await validateField("value", value);
    }
  };

  const blurFn = async (fieldName, value) => {
    try {
      if (value === "") return setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
      if (fieldErrors[fieldName] === "")
        dispatch(
          updateFinanceItem({
            itemId: item._id,
            value: value,
          })
        );
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });
      setFieldErrors(newErrors);
    }
  };

  return (
    <>
      <TextField
        key={item._id}
        id="outlined-basic"
        type="text"
        size="small"
        name={item.name}
        label={item.label}
        variant="filled"
        value={value}
        error={fieldErrors?.value}
        helperText={fieldErrors?.value}
        onMouseEnter={() => {
          setIsHovering({ ...isHovering, [item._id]: true });
        }}
        onMouseLeave={() => {
          setIsHovering({ ...isHovering, [item._id]: false });
        }}
        onChange={(event) => onChangeField(event.target.value)}
        onBlur={(e) => blurFn("value", value)}
        InputProps={{
          inputProps: { min: 0 },
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: isHovering[item._id] && (
            <InputAdornment position="end">
              <IconButton
                onClick={() =>
                  handleDelete({
                    itemId: item._id,
                  })
                }
                edge="end"
              >
                <DeleteForeverIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
