import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {
  saveValue,
  startApplication,
  applicationSelector,
  userDetailsSelector,
} from "../../store/slices/applicationFormSlice";

function StartApplicationDialog({ open, handleClose, startApplication }) {
  const arrayForItemBtn = [
    {
      label: "Consumer Asset",
      value: "consumer",
      key: "startConsumerAssetApp",
    },
    {
      label: "Commercial Asset",
      value: "commercial",
      key: "startCommercialAssetApp",
    },
    {
      label: "Personal Loan",
      value: "personal",
      key: "startCommercialAssetApp",
    },
  ];
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select application type</DialogTitle>
      <List sx={{ pt: 0 }}>
        {arrayForItemBtn?.map((item) => (
          <ListItem>
            <ListItemButton
              onClick={() => startApplication(item?.value)}
              key={item?.key}
            >
              <ListItemText primary={item?.label} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem>
          <ListItemButton
            disabled
            // onClick={() => handleListItemClick(email)} key={email}
          >
            <ListItemText primary="Unsecured Business Loan" />
          </ListItemButton>
        </ListItem>
      </List>
    </Dialog>
  );
}

export default function StartApplication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isNewApplication } = useSelector(userDetailsSelector);
  const { _id } = useSelector(applicationSelector);
  const [openDialog, setOpenDialog] = useState(false);

  const handle = {
    handleClose: () => {
      setOpenDialog(false);
    },
    startApplication: (applicationType) => {
      dispatch(startApplication(applicationType));
    },
  };

  useEffect(() => {
    if (isNewApplication && _id) {
      dispatch(saveValue({ isNewApplication: false }));
      navigate(`/application/${_id}`);
      handle.handleClose();
    }
  }, [isNewApplication]);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={async () => {
          setOpenDialog(true);
        }}
        style={{ marginRight: "30px" }}
      >
        New application
      </Button>
      <StartApplicationDialog
        open={openDialog}
        handleClose={handle.handleClose}
        startApplication={handle.startApplication}
      />
    </>
  );
}
