import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { debounce } from "lodash";

import {
  updateNotes,
  applicationSelector,
} from "src/store/slices/applicationFormSlice";
import { Typography } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const rawContent = {
  blocks: [
    {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: "2vm6d",
      text: "Type your notes here...",
      type: "normal",
      color: "#bbbbbb",
    },
  ],
  entityMap: {},
};

const Notes = () => {
  const dispatch = useDispatch();
  const application = useSelector(applicationSelector);

  const notes = application?.notes?.value;

  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(convertFromRaw(notes || rawContent))
  );

  const [rawText, setRawText] = React.useState(0);

  const onChange = (newState) => {
    if (JSON.stringify(editorState) !== JSON.stringify(newState)) {
      setRawText(newState);
      setEditorState(newState);
    }
  };

  const debounced = debounce(async () => {
    const blocks = convertToRaw(editorState.getCurrentContent());
    dispatch(
      updateNotes({
        value: blocks,
        application: application._id,
        _id: notes?._id,
      })
    );
  }, 3000);

  React.useEffect(() => {
    if (rawText !== 0) debounced(editorState);
    return () => debounced.cancel();
  }, [rawText, editorState]);

  return (
    <div style={{ marginTop: "20px", padding: "0 15px 20px 15px" }}>
      <Typography
        style={{
          marginBottom: 20,
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        Application Notes
      </Typography>
      <Editor
        toolbar={{
          options: ["fontSize", "list", "emoji", "history"],
          list: {
            indent: false,
            outdent: false,
          },
        }}
        editorState={editorState}
        onEditorStateChange={onChange}
      />
    </div>
  );
};

export default Notes;
