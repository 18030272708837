import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const DataGridTable = ({
  data,
  columns,
  onRowClick,
  rowsPerPageOptions,
  params,
  setParams,
  rowCount,
  onPageChange,
  isLoading,
  page,
  ...rest
}) => {

  const pageChangeHandle = (page) => {
    setParams({
      ...params,
      offset: page + 1
    })
  }

  return (
    <DataGrid
      autoHeight
      autoPageSize
      onCellClick={(params, event) => {
        event.defaultMuiPrevented = true;
      }}
      onRowClick={(row, event) => onRowClick(row?.row, event)} 
      rows={data || []}
      columns={columns}
      rowsPerPageOptions={rowsPerPageOptions}
      disableSelectionOnClick
      style={{ background: "#ffffff", border: "none" }}
      paginationMode={'server'}
      pageSize={10}
      rowCount={rowCount}
      onPageChange={(page) => pageChangeHandle(page)}
      loading={isLoading}
      page={page - 1 || 0}
      sx={{
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
          width: "7px",
          height: "7px",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
          backgroundColor: "#999",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
          background: "#616161",
        },
      }}
      {...rest}
    />
  );
};

export default DataGridTable;
