import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { Alert, Card, Grid, Stack, Snackbar, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import PersonIcon from "@mui/icons-material/PersonTwoTone";

import {
  saveValue,
  getApplication,
  applicationSelector,
  userDetailsSelector,
  clearState,
  getApplicationDownloadUrl,
  submitApplication,
  createCustomer,
} from "../../store/slices/applicationFormSlice";

import ProductSelector from "../ProductSelector";
import Asset from "src/components/Asset";
import LoanDetails from "src/components/LoanDetails";
import Entity from "../Entity/index..js";
import CustomerDetails from "../CustomerDetails";
import Supplier from "src/components/Supplier/index.js";
import PageLoader from "src/components/Application/PageLoader.js";
import convertToOrdinal from "src/utils/convertToOrdinal";
import Notes from "./Notes";
import ActivityLogSidebar from "./ActivityLogSidebar";
import Sidebar from "../HOC/Sidebar";

function openInNewTab(url) {
  var win = window?.open(url, "_blank");
  win?.focus();
}

const Application = React.memo(() => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openSMS, setOpenSMS] = useState(false);

  const [openNotes, setOpenNotes] = useState(false);

  const { linkSent, gotASICData } = useSelector(userDetailsSelector);

  const application = useSelector(applicationSelector);

  let { url, open, loadingSubmitApplication, gettingApplication } =
    useSelector(userDetailsSelector);

  const handle = {
    activityLog: () => {
      const extractKey = [
        "addresses",
        "customerAssets",
        "documents",
        "emails",
        "employers",
        "expenses",
        "income",
        "liabilities",
        "phoneNumbers",
      ];

      const list = _.flatMap(Object.keys(application), (item) => {
        if (["asset", "loanDetails"].includes(item)) {
          return [{ type: item, ...application[item] }];
        }

        if (["customers", "entities"].includes(item)) {
          return _.flatMap(application[item], (cusEnt) => {
            const result = [{ type: item, ...cusEnt }];
            _.forEach(cusEnt, (value, key) => {
              if (extractKey.includes(key) && value.createdAt) {
                result.push({ type: key, ...value });
              }
            });
            return result;
          });
        }
        return [];
      });

      return list;
    },
    applicationLoad: async () => {
      dispatch(saveValue({ gettingApplication: true }));
      if (applicationId) await dispatch(getApplication(applicationId)).unwrap();
    },
    addCustomer: () => {
      dispatch(
        createCustomer({
          applicationId: application._id,
          entities: application?.entities,
        })
      );
    },
  };

  const activityList = handle.activityLog();

  useEffect(() => {
    handle.applicationLoad();
    return function cleanUp() {
      dispatch(clearState());
    };
  }, [applicationId]);

  useEffect(() => {
    if (url) openInNewTab(url);
  }, [url]);

  const containerSize = 10;

  const applicationTypeString =
    application?.applicationType?.charAt(0).toUpperCase() +
    application?.applicationType?.slice(1);

  return (
    <React.Fragment>
      {gettingApplication ? (
        <Grid
          container
          style={{
            marginTop: "64px",
            padding: "30px 0px 100px",
            height: "calc(100vh - 100px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PageLoader text="Appplication loading..." />
        </Grid>
      ) : (
        <Grid container item>
          <Grid
            container
            item
            sm={containerSize}
            md={containerSize}
            lg={containerSize}
            style={{ padding: "20px 40px 20px 40px" }}
          >
            <Typography
              variant="h3"
              style={{
                fontSize: "22px",
                margin: "0 0 50px",
              }}
            >
              {applicationTypeString} Asset Application
            </Typography>

            <Asset applicationType={application.applicationType} />

            <LoanDetails applicationType={application.applicationType} />

            {application?.applicationType === "commercial" ?
              application?.entities?.map((entity) => (
                <Entity application={application} entitys={entity} />
              ))
              : null}

            {(application?.applicationType === "commercial" &&
              !application?.entities?.[0]) && <Entity application={application} />}

            {application?.customers?.length > 0 &&
              application?.customers?.map((customer, i) => (
                <CustomerDetails
                  key={application._id}
                  // numberOfCustomer={application?.customers?.length}
                  customer={customer}
                  applicationType={application.applicationType}
                  applicationId={application._id}
                  index={i}
                />
              ))}

            {application?.customers?.length < 1 && (
              <Grid
                container
                item
                xs={12}
                style={{
                  margin: "0 0 30px",
                  padding: "0 0 20px",
                  borderBottom: "1px solid rgba(0,0,0,0.12)",
                }}
              >
                <Grid item xs={12} md={2}>
                  <Stack direction="row">
                    <PersonIcon style={{ marginRight: "10px" }} />
                    <Typography>Guarantor</Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  style={{
                    padding: "0 0 0 10px",
                  }}
                >
                  <LoadingButton
                    variant="outlined"
                    onClick={() => handle.addCustomer()}
                  >
                    Add guarantor
                  </LoadingButton>
                </Grid>
              </Grid>
            )}

            {application?.customers?.length > 0 && (
              <Grid
                container
                item
                xs={12}
                style={{
                  margin: "0 0 30px",
                  padding: "0 0 20px",
                  borderBottom: "1px solid rgba(0,0,0,0.12)",
                }}
              >
                <Grid item xs={12} md={2}>
                  <Stack direction="row">
                    <PersonIcon style={{ marginRight: "10px" }} />
                    <Typography>Add Guarantor</Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  style={{
                    padding: "0 0 0 10px",
                  }}
                >
                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    onClick={handle.addCustomer}
                  >
                    Add {convertToOrdinal(application?.customers?.length)}{" "}
                    guarantor
                  </LoadingButton>
                </Grid>
              </Grid>
            )}
            <Supplier />
          </Grid>

          <Grid conatiner item sm={2} md={2} lg={2} style={{ padding: "20px" }}>
            <Sidebar
              open={open}
              title={"Expand lenders"}
              onClickFn={() => dispatch(saveValue({ open: !open }))}
              bottom={""}
              right={true}
              left={false}
              disabled={false}
              component={<ProductSelector />}
            />
          </Grid>

          <Sidebar
            open={openNotes}
            title={"Notes"}
            onClickFn={() => setOpenNotes(!openNotes)}
            bottom={""}
            right={false}
            left={true}
            dispatch={false}
            component={<Notes />}
          />

          <Sidebar
            open={openSMS}
            title={"SMS (coming soon)"}
            onClickFn={() => setOpenSMS(!openSMS)}
            bottom={370}
            right={false}
            left={true}
            disabled={true}
            component={""}
          />

          <Sidebar
            open={openEmail}
            title={"Emails (coming soon)"}
            onClickFn={() => setOpenEmail(!openEmail)}
            bottom={200}
            right={false}
            left={true}
            disabled={true}
            component={""}
          />

          <Sidebar
            open={openActivity}
            title={"Activity log"}
            onClickFn={() => setOpenActivity(!openActivity)}
            bottom={55}
            right={false}
            left={true}
            disabled={false}
            component={<ActivityLogSidebar activityList={activityList} />}
          />

          <Snackbar
            open={submitSuccess}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => setSubmitSuccess(false)}
          >
            <Alert
              onClose={() => setSubmitSuccess(false)}
              elevation={6}
              severity="success"
              sx={{ width: "100%" }}
            >
              Application submitted
            </Alert>
          </Snackbar>

          <Snackbar
            open={linkSent}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => dispatch(saveValue({ linkSent: false }))}
          >
            <Alert
              onClose={() => dispatch(saveValue({ linkSent: false }))}
              elevation={6}
              severity="success"
              sx={{ width: "100%" }}
            >
              Link sent
            </Alert>
          </Snackbar>

          <Snackbar
            open={gotASICData}
            autoHideDuration={8000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => dispatch(saveValue({ linkSent: false }))}
          >
            <Alert
              onClose={() => dispatch(saveValue({ linkSent: false }))}
              elevation={6}
              severity="success"
              sx={{ width: "100%" }}
            >
              ASIC Data retrieved
            </Alert>
          </Snackbar>
        </Grid>
      )}
    </React.Fragment>
  );
})

export default Application