import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Stack,
  Button,
  Typography,
  Grid,
  Skeleton,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
} from "@mui/material";
import {
  getCreditProfile,
  getCreditFile,
  saveValue,
  userDetailsSelector,
} from "../../store/slices/applicationFormSlice";
import { lime, orange, yellow } from "@mui/material/colors";
import { isEqual } from "lodash";
import CustomCardSending from "./CustomCardSending";

export default function CreditProfile({ customer, applicationId }) {
  const dispatch = useDispatch();
  const creditProfile = customer.creditProfile;
  const licenceNumber = customer?.documents?.find((doc) => {
    return doc.details?.licence_number ? doc.details?.licence_number : null;
  })?.details?.licence_number;

  const { gettingCreditProfile } = useSelector(userDetailsSelector, isEqual);

  const handleGetCreditProfile = () => {
    dispatch(
      getCreditProfile({
        customerId: customer._id,
        applicationId,
      })
    );
    // console.log("identityVerification", identityVerification);
  };

  const handleGetCreditFile = () => {
    dispatch(saveValue({ gettingCreditProfile: true }));
    dispatch(
      getCreditFile({
        customerId: customer._id,
        applicationId,
      })
    );
  };

  function getRiskFactorColor(text) {
    // console.log(text);
    if (text === "Greatly Increases Risk") return "error";
    if (text === "Moderately Increases Risk") return orange[700];
    if (text === "Marginally Increases Risk") return lime[800];
  }

  useEffect(() => {
    if (customer?._id) {
      // console.log("getting credit profile");
      // handleGetCreditProfile(customer?._id);
    }
  }, []);
  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Typography fontWeight={600} style={{ marginBottom: "15px" }}>
          Credit Profile
        </Typography>
        {/* <Button
          color="secondary"
          style={{ marginBottom: "5px" }}
          variant="outlined"
          disabled={
            (!licenceNumber || !customer.IDNumber) &&
            !customer.documents.find(
              (doc) => doc.name === "Privacy agreement"
            )
          }
          onClick={() => {
            handleGetCreditFile();
          }}
        >
          Get credit file
        </Button> */}
        {!creditProfile && (
           <Grid container spacing={2}>
          <Grid item sm={6}>
            <Card>
              <CardActionArea
                onClick={() => {
                  handleGetCreditFile();
                }}
              >
                <CustomCardSending
                  title={"Get Credit file"}
                  description={` Get ${customer.firstName}'s credit report using Equifax Access Seeker.`}
                  imagePath={"/static/equifax-logo.png"}
                  loadingState={false}
                  financialState={false}
                  icon={false}
                  lastTitle={"Sending SMS link..."}
                />
              </CardActionArea>
            </Card>
          </Grid>
          </Grid>
        )}
        {/* <br /> */}
        {!creditProfile && (
          <Typography variant="caption">
            Requires customer licence number & signed privacy statement
          </Typography>
        )}

        {gettingCreditProfile && (
          <>
            <Grid
              item
              container
              spacing={2}
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              <Grid item sm={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
              <Grid item sm={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item container>
                <Grid item sm={4}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid item container>
                  <Grid item sm={7}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={8}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={9}>
                    <Skeleton variant="text" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item sm={4}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid item container>
                  <Grid item sm={7}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={8}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={10}>
                    <Skeleton variant="text" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {creditProfile && (
          <>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  Credit score
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight={600}
                  style={{ fontSize: "28px" }}
                >
                  {creditProfile.score}
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  Age of file
                </Typography>
                <Typography variant="body2">
                  {creditProfile.ageOfFile}
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  Score card
                </Typography>
                <Typography variant="body2">
                  {creditProfile.scoreCard}
                </Typography>
              </Grid>

              <Grid item sm={4} xs={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  Defaults (last 60 months)
                </Typography>
                <Typography variant="body2">
                  {creditProfile.numberOfDefaults || "0"}
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  Enquiries (last 12 months)
                </Typography>
                <Typography variant="body2">
                  {creditProfile.numberOfEnquiries}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: "20px" }}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  Key Contributing Factors
                </Typography>
              </Grid>
              {creditProfile?.contributingFactors?.map((factor) => (
                <Grid item md={12} sm={12}>
                  <Typography variant="subtitle2">
                    {factor["vs:name"]._text}
                  </Typography>
                  <Typography variant="body2">
                    {factor["vs:description"]._text}
                  </Typography>
                  <Typography
                    variant="body2"
                    color={getRiskFactorColor(
                      factor["vs:score-impactor"]._text
                    )}
                  >
                    {factor["vs:score-impactor"]._text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
