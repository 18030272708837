import { configureStore } from "@reduxjs/toolkit";
import { formdataSlice } from "./slices/applicationFormSlice";
import { applicationsSlice } from "./slices/applicationsSlice";
import { customerSlice } from "./slices/customerSlice";
import { userSlice } from "./slices/userSlice";
import { usersSlice } from "./slices/usersSlice";
import { organisationSlice } from "./slices/organisationSlice";
import { applicationsAssetSlice } from "./slices/applicationAssetSlice";

const store = configureStore({
  reducer: {
    userDetails: formdataSlice.reducer,
    applications: applicationsSlice.reducer,
    assert: applicationsAssetSlice.reducer,
    user: userSlice.reducer,
    users: usersSlice.reducer,
    customer: customerSlice.reducer,
    organisation: organisationSlice.reducer,
  },
});
export default store;
