import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { Box, Grid, TextField, Typography, Slider } from "@mui/material";
import GoogleAddressSearch from "../Utils/GoogleAddressSearch";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import regex from "src/utils/regex";
import {
  updateEntity,
  saveValue,
  userDetailsSelector,
  saveEmployer,
  createEmployer,
} from "../../store/slices/applicationFormSlice";

import { getBusinessByABN, getBusinessByName } from "../../services/abr";
import readableABNString from "../../utils/readableABNString";
import readableACNString from "../../utils/readableACNString";

import { isDigitsOnly } from "src/utils/isDigitsOnly";
import { LoadingButton } from "@mui/lab";
import { width } from "@mui/system";
import convertToOrdinal from "src/utils/convertToOrdinal";
import RadioButtonGroup from "../Utils/RadioButtonGroup";
import { useMemo } from "react";

export default function Employer({ customer, employer, index }) {
  const dispatch = useDispatch();

  const { gettingEntityDetails } = useSelector(userDetailsSelector);

  const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);

  const [searchValue, setSearchValue] = useState({
    businessSearchValue: "",
    businessSearchInputText: "",
  });

  const [allValues, setAllValue] = useState({
    entityNameValue: employer?.entityName,
    employmentTypeValue: employer?.employmentType,
    timeOfEmploymentValue: employer.timeOfEmployment,
    occupationValue: employer?.occupation,
    managerValue: employer?.manager,
    phoneNumberValue: employer.phoneNumber,
    industryValue: employer.industry,
  });

  const validationSchema = Yup.object({
    occupationValue: Yup.string()
    .required("Occupation field is required")
      .matches(
        regex.nameRegex,
        "Only letters and ' - characters allowed."
      )
      .min(2, "Maximum of 2 characters")
      .max(50, "Maximum of 50 characters"),
    managerValue: Yup.string()
      .required("manager name is required")
      .matches(regex.nameRegex, "Only letters and ' - characters allowed.")
      .min(2, "Maximum of 2 characters")
      .max(50, "Maximum of 50 characters"),
    phoneNumberValue: Yup.string()
      .required("number field is required")
      .matches(regex.phoneNumber, "Enter a valid Australian mobile number."),
  });

  const [fieldErrors, setFieldErrors] = useState({
    occupationValue: "",
    managerValue: "",
    phoneNumberValue: "",
    supplierPhone: "",
    supplierEmail: "",
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      if (name === "occupationValue" || name === 'managerValue') {
        isValid = regex.nameRegex.test(e) && e?.length <= 50;
      } else if (name === "phoneNumberValue") {
        isValid = e === "" || regex.addressRegex.test(e);
      }

      if (!isValid) {
        await validateField(name, e);
        return;
      } else {
        setAllValue({ ...allValues, [name]: e });
        await validateField(name, e);
      }
    },
    onChangeRadio: (value, name) => {
      setAllValue({ ...allValues, [name]: value });
    },
    commonEventHandle: (value, name) => {
      dispatch(
        saveEmployer({
          [name]: value,
          employerId: employer._id,
          customer: customer._id,
        })
      );
    },
    addAddress: (address) => {
      console.log("update address", address);
      if (address) {
        dispatch(
          saveEmployer({
            employerId: employer._id,
            customer: customer._id,
            address: address.description,
          })
        );
      }
    },
  };

  const [ABREntityDetails, setABREntityDetails] = useState({});
  const [newBusinessValue, setNewBusinessValue] = useState("");

  // const parseTimeInBusiness = moment(entity?.timeInBusiness, "DD-MM-YYYY");

  const handleEntity = (update) => {
    // console.log("update phoneNumber");
    dispatch(updateEntity(update));
  };

  const debounced = useMemo(() =>
    debounce(async (inputValue) => {
      // console.log("inputValue", inputValue);
      if (inputValue) {
        setLoadingBusinessSearch(true);
        if (isDigitsOnly.test(inputValue)) {
          const businessByABN = await getBusinessByABN(inputValue);
          setLoadingBusinessSearch(false);
          if (businessByABN.Abn.length > 1) {
            return setEntityOptions([businessByABN]);
          }
        }

        const businesses = await getBusinessByName(inputValue);
        if (businesses?.Names?.length > 0) {
          setLoadingBusinessSearch(false);
          return setEntityOptions(
            businesses.Names.map((i) => {
              // return { label: `${i.Name} ${i.Abn}`, Abn: i.Abn, value: i };
              return i;
            })
          );
        }

        setEntityOptions([
          {
            Name: "No results found",
          },
        ]);
      }
    }, 500)
  );

  const handleEntityDetails = async (details) => {
    dispatch(saveValue({ gettingEntityDetails: true }));
    console.log("details", details);
    const abrData = await getBusinessByABN(details.Abn);
    console.log("ABREntityDetails", abrData);
    setABREntityDetails(abrData);
    console.log(abrData.EntityName !== employer?.entityName);
    console.log(abrData.EntityName, employer?.entityName);

    if (abrData.EntityName !== employer?.entityName) {
      // await dispatch(getCompanyProfile({ ACN: abrData.Acn })).unwrap();
      dispatch(createEmployer({ abrData, customerId: customer._id }));
    } else {
      dispatch(saveValue({ gettingEntityDetails: false }));
    }
  };

  useEffect(() => {
    debounced(searchValue?.businessSearchInputText);
    return () => debounced.cancel();
  }, [searchValue?.businessSearchValue, searchValue?.businessSearchInputText]);

  useEffect(() => {
    if (!gettingEntityDetails) {
      setSearchValue({
        businessSearchValue: "",
        businessSearchInputText: "",
      });
    }
  }, [gettingEntityDetails]);

  return (
    <>
      <Grid
        style={{
          margin: "0 0 30px 0",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        {employer?.entityName ? (
          <Typography style={{ marginBottom: "20px" }}>
            {`Employer ${++index}: ${employer?.entityName}`}{" "}
          </Typography>
        ) : (
          <Grid item md={7} sm={6}>
            <Grid item md={12} sm={12} style={{ margin: "0 0 20px" }}>
              <Typography fontWeight={600}>Employer details</Typography>
            </Grid>
            <TextField
              fullWidth
              // id="outlined-basic"
              type="text"
              name="entityNameValue"
              label={`Employer ${++index} name`}
              variant="filled"
              size="small"
              value={allValues?.entityNameValue}
              style={{ marginBottom: "20px" }}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "entityNameValue")
              }
              onBlur={() =>
                allValues?.entityNameValue
                  ? handle.commonEventHandle(
                      allValues?.entityNameValue,
                      "entityName"
                    )
                  : null
              }
            />
          </Grid>
        )}
        <Grid container spacing={1}>
          <Grid container item spacing={2} alignItems="center">

            <Grid item md={7} sm={6}>
              <Typography variant="subtitle2">Employment status</Typography>
              <RadioButtonGroup
                value={allValues?.employmentTypeValue}
                handleValue={(value) => {
                  handle.onChangeRadio(value, "employmentTypeValue");
                  handle.commonEventHandle(value, "employmentType");
                }}
                options={[
                  { icon: "House", label: "Full-time" },
                  { icon: "HomeWork", label: "Part-time" },
                  { icon: "Hotel", label: "Casual" },
                  { icon: "Hotel", label: "Contract" },
                ]}
              />
            </Grid>

            <Grid item md={5} sm={5}>
              <Typography variant="subtitle2">
                Length of employment (months)
              </Typography>
              <Box style={{ padding: "0 14px 0 7px" }}>
                <Slider
                  color="secondary"
                  size="large"
                  name="timeOfEmploymentValue"
                  marks={[
                    { value: 0, label: 0 },
                    // { value: 6, label: 6 },
                    // { value: 15, label: 15 },
                    { value: 12, label: 12 },
                    // { value: 25, label: 25 },
                    // { value: 18, label: 18 },
                    // { value: 35, label: 35 },
                    { value: 24, label: 24 },
                    { value: 36, label: 36 },
                    { value: 48, label: 48 },
                    { value: 60, label: "+60" },
                  ]}
                  step={6}
                  min={0}
                  max={60}
                  value={allValues?.timeOfEmploymentValue}
                  onChange={(event) =>
                    handle.onChangeField(
                      event?.target?.value,
                      "timeOfEmploymentValue"
                    )
                  }
                  onChangeCommitted={() =>
                    handle.commonEventHandle(
                      allValues?.timeOfEmploymentValue,
                      "timeOfEmployment"
                    )
                  }
                  aria-label="Default"
                  valueLabelDisplay="auto"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item md={4} sm={4}>
              <TextField
                fullWidth
                id="outlined-basic"
                type="text"
                name="occupationValue"
                label="Occupation"
                variant="filled"
                size="small"
                value={allValues?.occupationValue}
                error={fieldErrors?.occupationValue}
                helperText={fieldErrors?.occupationValue}
                onChange={(event) =>
                  handle.onChangeField(event?.target?.value, "occupationValue")
                }
                onBlur={() =>
                  allValues?.occupationValue
                    ? handle.commonEventHandle(
                        allValues?.occupationValue,
                        "occupation"
                      )
                    : null
                }
              />
            </Grid>

            <Grid item md={4} sm={4}>
              <TextField
                fullWidth
                id="outlined-basic"
                type="text"
                name="managerValue"
                label="Manager"
                variant="filled"
                size="small"
                value={allValues?.managerValue}
                error={fieldErrors?.managerValue}
                helperText={fieldErrors?.managerValue}
                onChange={(event) =>
                  handle.onChangeField(event?.target?.value, "managerValue")
                }
                onBlur={() =>
                  allValues?.managerValue
                    ? handle.commonEventHandle(
                        allValues?.managerValue,
                        "manager"
                      )
                    : null
                }
              />
            </Grid>

            <Grid item md={4} sm={4}>
              <InputMask
                mask="0999 999 999"
                value={allValues?.phoneNumberValue}
                disabled={false}
                onChange={(event) =>
                  handle.onChangeField(event?.target?.value, "phoneNumberValue")
                }
                onBlur={() =>
                  allValues?.phoneNumberValue
                    ? handle.commonEventHandle(
                        allValues?.phoneNumberValue,
                        "phoneNumber"
                      )
                    : null
                }
                maskChar=" "
              >
                {() => (
                  <TextField
                    id="outlined-basic"
                    label="Contact number"
                    variant="filled"
                    size="small"
                    error={fieldErrors?.phoneNumberValue}
                    helperText={fieldErrors?.phoneNumberValue}
                  />
                )}
              </InputMask>
            </Grid>
          </Grid>
          <Grid container item sm={12} spacing={1}>
            <Grid item md={4} sm={4}>
              <TextField
                fullWidth
                id="outlined-basic"
                type="text"
                name="industryValue"
                label="Industry"
                variant="filled"
                size="small"
                value={allValues?.industryValue}
                onChange={(event) =>
                  handle.onChangeField(event?.target?.value, "industryValue")
                }
                onBlur={() =>
                  allValues?.industryValue
                    ? handle.commonEventHandle(
                        allValues?.industryValue,
                        "industry"
                      )
                    : null
                }
              />
            </Grid>
            <Grid item md={8} sm={8}>
              <GoogleAddressSearch
                handleCreateAddress={handle.addAddress}
                address={employer?.address}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
