import React from "react";
import { Grid, Typography } from "@mui/material";

const BankStatementMetrics = ({ title, customer }) => {
  const handle = {
    renderFn: (metric, i) => {
      switch (title) {
        case "Credits":
          if (i < 3) handle.commonFn(metric);
          break;

        case "Responsible lending flags":
          if (i > 2 && i < 7) handle.commonFn(metric);
          break;

        case "Liabilities":
          if (i > 6 && i < 10) handle.commonFn(metric);
          break;

        case "Debits":
          if (i > 9 && metric.value * 1 !== 0) handle.commonFn(metric);
          break;
      }
    },
    commonFn: (metric) => (
      <Grid item xs={6}>
        <Typography variant="subtitle2">{metric.name}</Typography>
        <Typography variant="body2">{metric.value}</Typography>
      </Grid>
    ),
  };
  return (
    <React.Fragment>
      <Typography
        variant="subtitle2"
        fontWeight={600}
        style={{ marginBottom: "5px" }}
      >
        {title}
      </Typography>
      <Grid item container style={{ margin: "0 0 20px 0" }}>
        {customer?.bankStatements &&
          customer?.bankStatements?.decisionMetrics?.map((metric, i) =>
            handle.renderFn(metric, i)
          )}
      </Grid>
    </React.Fragment>
  );
};

export default BankStatementMetrics;
