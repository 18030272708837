import {
  createSlice,
  configureStore,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import {
  headers,
  reusableFetch,
  reusableFormDataFetch,
} from "src/utils/useAxios";

const token = localStorage.token;

export const startApplication = createAsyncThunk(
  "application/start",
  async (applicationType, { rejectWithValue }) => {
    const newApplication = await reusableFetch(
      `application/start/${applicationType}`,
      "GET",
      rejectWithValue
    );
    return newApplication;
  }
);

export const getApplication = createAsyncThunk(
  "userDetails/getApplication",
  async (applicationId, { rejectWithValue }) => {
    const application = await reusableFetch(
      `application/${applicationId}`,
      "GET",
      rejectWithValue
    );
    return application;
  }
);

export const updateApplication = createAsyncThunk(
  "application/",
  async (data, { rejectWithValue, getState }) => {
    const application = await reusableFetch(
      `application/update`,
      "POST",
      data,
      rejectWithValue
    );
    return application;
  }
);

export const submitApplication = createAsyncThunk(
  "application/submit",
  async (applicationId, { rejectWithValue }) => {
    const application = await reusableFetch(
      `application/submit/${applicationId}`,
      "GET",
      token,
      rejectWithValue
    );
    return application;
  }
);

export const getApplicationDownloadUrl = createAsyncThunk(
  "application/url",
  async (applicationId, { rejectWithValue }) => {
    const application = await reusableFetch(
      `application/url/${applicationId}`,
      "GET",
      rejectWithValue
    );
    return application;
  }
);

export const updateAsset = createAsyncThunk(
  "asset/updateAsset",
  async (data, { rejectWithValue }) => {
    const asset = await reusableFetch(`asset/`, "POST", data, rejectWithValue);
    return asset;
  }
);

export const updateAssets = createAsyncThunk(
  "assets/updateAssets",
  async (data, { rejectWithValue }) => {
    const assets = await reusableFetch(
      `customer-assets`,
      "PUT",
      data,
      rejectWithValue
    );
    return assets;
  }
);

export const uploadInvoice = createAsyncThunk(
  "asset/upload",
  async ({ formData, assetId }, { rejectWithValue }) => {
    const asset = await reusableFormDataFetch(
      `asset/upload/${assetId}`,
      "POST",
      formData,
      rejectWithValue
    );
    return asset;
  }
);

export const updateLoanDetails = createAsyncThunk(
  "loanDetails/update",
  async (data, { rejectWithValue }) => {
    const loanDetails = await reusableFetch(
      `loanDetails/`,
      "POST",
      data,
      rejectWithValue
    );
    return loanDetails;
  }
);

export const createEntity = createAsyncThunk(
  "entity/create",
  async (data, { rejectWithValue }) => {
    const application = await reusableFetch(
      `entity/`,
      "POST",
      data,
      rejectWithValue
    );
    return application;
  }
);

export const createEmployer = createAsyncThunk(
  "employer/create",
  async (data, { rejectWithValue }) => {
    const application = await reusableFetch(
      `employer/`,
      "POST",
      data,
      rejectWithValue
    );
    return application;
  }
);

export const saveEmployer = createAsyncThunk(
  "employer/save",
  async (data, { rejectWithValue }) => {
    const application = await reusableFetch(
      `employer/`,
      "PUT",
      data,
      rejectWithValue
    );
    return application;
  }
);

export const getEntity = createAsyncThunk(
  "entity/get",
  async (entityId, { rejectWithValue, getState }) => {
    const entity = await reusableFetch(
      `entity/${entityId}`,
      "GET",
      rejectWithValue
    );
    return entity;
  }
);

export const removeEntity = createAsyncThunk(
  "entity/delete",
  async ({ entityId, applicationId }, { rejectWithValue, getState }) => {
    const token = localStorage.token;
    try {
      const application = await axios({
        url: `${process.env.REACT_APP_API_URL}/entity?entityId=${entityId}&applicationId=${applicationId}`,
        method: "DELETE",
        headers: headers(token),
      });
      console.log("application:", application);
      if (application.status === "error") {
        console.log("application", application);
        return rejectWithValue(application);
      }
      return application;
    } catch (e) {
      console.log("Error", e.response.data);
      return rejectWithValue(e.response.data);
    }
  }
);

export const getASICData = createAsyncThunk(
  "entity/asic-data",
  async (data, { rejectWithValue }) => {
    const entity = await reusableFetch(
      `entity/asic-data`,
      "POST",
      data,
      rejectWithValue
    );
    return entity;
  }
);

export const updateEntity = createAsyncThunk(
  "entity/update",
  async (data, { rejectWithValue }) => {
    const entity = await reusableFetch(`entity`, "PUT", data, rejectWithValue);
    return entity;
  }
);

export const getCompanyProfile = createAsyncThunk(
  "entity/update",
  async ({ ACN }, { rejectWithValue }) => {
    const entity = await reusableFetch(
      `entity/search?acn=${ACN}`,
      "GET",
      rejectWithValue
    );
    return entity;
  }
);

export const createCustomer = createAsyncThunk(
  "customer/create",
  async (data, { rejectWithValue }) => {
    const newCustomer = await reusableFetch(
      `customer`,
      "POST",
      data,
      rejectWithValue
    );
    return newCustomer;
  }
);

export const getCustomer = createAsyncThunk(
  "customer/get",
  async (data, { rejectWithValue }) => {
    const customer = await reusableFetch(
      `customer/${data}`,
      "POST",
      rejectWithValue
    );
    return customer;
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/update",
  async (data, { rejectWithValue }) => {
    const customer = await reusableFetch(
      `customer/`,
      "PUT",
      data,
      rejectWithValue
    );
    return customer;
  }
);

export const removeCustomer = createAsyncThunk(
  "customer/remove",
  async (data, { rejectWithValue }) => {
    const customer = await reusableFetch(
      `customer/remove`,
      "PUT",
      data,
      rejectWithValue
    );
    return customer;
  }
);

export const startVerification = createAsyncThunk(
  "identity-verification/start",
  async (data, { rejectWithValue }) => {
    const newCustomer = await reusableFetch(
      `identity-verification/send`,
      "POST",
      data,
      rejectWithValue
    );
    return newCustomer;
  }
);

export const getIdentityVerification = createAsyncThunk(
  "identity-verification/get",
  async (data, { rejectWithValue, getState }) => {
    const customer = await reusableFetch(
      `identity-verification/search`,
      "POST",
      data,
      rejectWithValue
    );
    return customer;
  }
);

export const getDocuments = createAsyncThunk(
  "document/get",
  async (data, { rejectWithValue }) => {
    const document = await reusableFetch(
      `document/${data}`,
      "GET",
      rejectWithValue
    );
    return document;
  }
);

export const getFileUrl = createAsyncThunk(
  "document/file",
  async (data, { rejectWithValue }) => {
    const url = await reusableFetch(
      `document/file`,
      "POST",
      data,
      rejectWithValue
    );
    return url;
  }
);

export const getCreditFile = createAsyncThunk(
  "credit-profile/file",
  async (data, { rejectWithValue }) => {
    const file = await reusableFetch(
      `credit-profile/file`,
      "POST",
      data,
      rejectWithValue
    );
    return file;
  }
);

export const getCreditProfile = createAsyncThunk(
  "credit-profile/get",
  async ({ customerId, applicationId }, { rejectWithValue, getState }) => {
    const file = await reusableFetch(
      `credit-profile/?cid=${customerId}&aid=${applicationId}`,
      "POST",
      rejectWithValue
    );
    return file;
  }
);

export const sendPrivacyLink = createAsyncThunk(
  "document/privacy/link",
  async (data, { rejectWithValue }) => {
    const privacy = await reusableFetch(
      `document/privacy/link`,
      "POST",
      data,
      rejectWithValue
    );
    return privacy;
  }
);

export const sendBankStatementsLink = createAsyncThunk(
  "bankStatements/link",
  async (data, { rejectWithValue }) => {
    const privacy = await reusableFetch(
      `bank-statements/link`,
      "POST",
      data,
      rejectWithValue
    );
    return privacy;
  }
);

export const getAddress = createAsyncThunk(
  "address/get",
  async (addressId, { rejectWithValue }) => {
    const address = await reusableFetch(
      `address/${addressId}`,
      "GET",
      rejectWithValue
    );
    return address;
  }
);

export const addAddress = createAsyncThunk(
  "address/add",
  async (data, { rejectWithValue }) => {
    const address = await reusableFetch(
      `address`,
      "POST",
      data,
      rejectWithValue
    );
    return address;
  }
);

export const updateAddress = createAsyncThunk(
  "address/update",
  async (data, { rejectWithValue }) => {
    const address = await reusableFetch(
      `address`,
      "PUT",
      data,
      rejectWithValue
    );
    return address;
  }
);

export const addPhoneNumber = createAsyncThunk(
  "phoneNumber/add",
  async (data, { rejectWithValue, getState }) => {
    const customer = await reusableFetch(
      `phone-number`,
      "POST",
      data,
      rejectWithValue
    );
    return customer;
  }
);

export const addEmail = createAsyncThunk(
  "email/add",
  async (data, { rejectWithValue }) => {
    const customer = await reusableFetch(
      `email`,
      "POST",
      data,
      rejectWithValue
    );
    return customer;
  }
);

export const saveCustomerProfile = createAsyncThunk(
  "customerProfile/add",
  async (data, { rejectWithValue }) => {
    const customerProfile = await reusableFetch(
      `customer-profile`,
      "POST",
      data,
      rejectWithValue
    );
    return customerProfile;
  }
);

export const updateNotes = createAsyncThunk(
  "notes/update",
  async (data, { rejectWithValue }) => {
    const notes = await reusableFetch(`notes`, "POST", data, rejectWithValue);
    return notes;
  }
);

export const updateLiabilities = createAsyncThunk(
  "liabilities/update",
  async (data, { rejectWithValue }) => {
    const liabilities = await reusableFetch(
      `liabilities`,
      "PUT",
      data,
      rejectWithValue
    );
    return liabilities;
  }
);

export const updateExpenses = createAsyncThunk(
  "expenses/update",
  async (data, { rejectWithValue }) => {
    const expenses = await reusableFetch(
      `expenses`,
      "PUT",
      data,
      rejectWithValue
    );
    return expenses;
  }
);

export const updateIncome = createAsyncThunk(
  "income/update",
  async (data, { rejectWithValue }) => {
    const expenses = await reusableFetch(
      `income`,
      "PUT",
      data,
      rejectWithValue
    );
    return expenses;
  }
);

export const createFinanceItem = createAsyncThunk(
  "finance-item/create",
  async ({ data, endpoint }, { rejectWithValue }) => {
    const expenses = await reusableFetch(
      endpoint,
      "POST",
      data,
      rejectWithValue
    );
    return expenses;
  }
);

export const deleteFinanceItem = createAsyncThunk(
  "finance-item/delete",
  async ({ data, endpoint }, { rejectWithValue }) => {
    const expenses = await reusableFetch(
      endpoint,
      "DELETE",
      data,
      rejectWithValue
    );
    return expenses;
  }
);

export const uploadDocument = createAsyncThunk(
  "document/upload",
  async (formData, { rejectWithValue }) => {
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
    const formDataHeaders = headers(token);
    formDataHeaders[
      "Content-Type"
    ] = `multipart/form-data; boundary=${formData._boundary}`;
    delete formDataHeaders.Accept;
    try {
      if (formData) {
        const privacy = await axios({
          url: `${process.env.REACT_APP_API_URL}/document/upload`,
          method: "POST",
          headers: formDataHeaders,
          data: formData,
        });
        if (privacy.status === "error") {
          return rejectWithValue(privacy);
        }
        return privacy;
      }
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const updateDeclaration = createAsyncThunk(
  "declaration/update",
  async (data, { rejectWithValue }) => {
    const declaration = await reusableFetch(
      `declaration`,
      "POST",
      data,
      rejectWithValue
    );
    return declaration;
  }
);

export const updateSupplier = createAsyncThunk(
  "supplier/update",
  async (data, { rejectWithValue }) => {
    const supplier = await reusableFetch(
      `supplier`,
      "POST",
      data,
      rejectWithValue
    );
    return supplier;
  }
);

export const updateCreditProfile = createAsyncThunk(
  "credit-profile/get",
  async (data, { rejectWithValue, getState }) => {
    const file = await reusableFetch(
      `credit-profile`,
      "POST",
      data,
      rejectWithValue
    );
    return file;
  }
);

export const updateFinanceItem = createAsyncThunk(
  "financeItem/update",
  async (data, { rejectWithValue, getState }) => {
    const financeItem = await reusableFetch(
      `finance-item`,
      "PUT",
      data,
      rejectWithValue
    );
    return financeItem;
  }
);

export const submitPrivacy = createAsyncThunk(
  "document/privacy",
  async (data, { rejectWithValue, getState }) => {
    const privacy = await reusableFetch(
      `document/privacy`,
      "POST",
      data,
      rejectWithValue
    );
    return privacy;
  }
);

const initialState = {
  loadingSubmitApplication: false,
  linkSent: false,
  sendingIDLink: null,
  gettingASICData: null,
  gotASICData: null,
  gettingEntityDetails: false,
  gettingCreditProfile: false,
  canCalculateQuote: false,
  directors: [],
  shareHolders: [],
  beneficiaries: [],
  shares: [],
  businessAddresses: [],
  applicants: [],
  entityId: "",
  entity: {},
  identityVerification: [],
  identityDocument: [],
  address: {},
  customer: {},
  document: {},
  creditProfile: {},
  open: false,
  uploadPrivacyLoading: "",
  uploadIDLoading: "",
  documentUploadLoding: "",
  isUpdatedLoanDetails: null,
  gettingApplication: false,
  application: {
    asset: {},
    loanDetails: {},
  },
};

export const formdataSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    clearState: () => initialState,
    saveValue: (state, action) => {
      return Object.assign({}, state, action.payload);
    },
    saveAsset: (state, action) => {
      state.application.asset = _.merge(
        state.application.asset,
        action.payload
      );
    },
    saveLoanDetails: (state, action) => {
      state.application.loanDetails = _.merge(
        state.application.loanDetails,
        action.payload
      );
    },
  },
  extraReducers: {
    [startApplication.fulfilled]: (state, { payload }) => {
      console.log("start application", payload);
      state.isNewApplication = true;
      // state.successMessage = payload.message;
      state.application = _.merge(state.application, payload.data.application);
      state.gettingApplication = false;
    },
    [startApplication.pending]: (state) => { },
    [startApplication.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      // state.errorMessage = payload.message;
      state.gettingApplication = false;
    },

    [getApplication.fulfilled]: (state, { payload }) => {
      console.log("application", payload);
      // state.successMessage = payload.message;
      state.application = _.merge(state.application, payload.data);
      state.gettingApplication = false;
    },
    [getApplication.pending]: (state) => {
      state.gettingApplication = true;
    },
    [getApplication.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.gettingApplication = false;
      // state.errorMessage = payload.message;
    },

    [updateApplication.fulfilled]: (state, { payload }) => {
      console.log("application", payload);
      // state.successMessage = payload.message;
      state.application = _.merge(state.application, payload.data);
      state.gettingApplication = false;
    },
    [updateApplication.pending]: (state) => { },
    [updateApplication.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.gettingApplication = false;
      // state.errorMessage = payload.message;
    },

    [submitApplication.fulfilled]: (state, { payload }) => {
      console.log("application", payload);
      // state.successMessage = payload.message;
      state.loadingSubmitApplication = false;
    },
    [submitApplication.pending]: (state) => { },
    [submitApplication.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      // state.errorMessage = payload.message;
    },

    [getApplicationDownloadUrl.fulfilled]: (state, { payload }) => {
      console.log("application url", payload);
      state.successMessage = payload.message;
      state.url = payload.data.url;
    },
    [getApplicationDownloadUrl.pending]: (state) => { },
    [getApplicationDownloadUrl.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.errorMessage = payload.message;
    },

    [uploadInvoice.fulfilled]: (state, { payload }) => {
      _.merge(state.application.asset, payload.data);
    },

    [updateAsset.fulfilled]: (state, { payload }) => {
      // console.log("asset", payload.data);
      // state.isFetching = false;
      // state.isSuccess = true;
      // state.successMessage = payload.message;
      // _.merge(state.application.asset, payload.data);
    },
    [updateAsset.pending]: (state) => { },
    [updateAsset.rejected]: (state, { payload }) => {
      // console.log("payload:", payload);
      state.errorMessage = payload.message;
    },

    [updateAssets.fulfilled]: (state, { payload }) => {
      state.application.customers.filter(
        (customer) => customer._id === payload.data.customer
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data.customer
        )[0].customerAssets,
        payload.data
      );
    },

    [updateLoanDetails.fulfilled]: (state, { payload }) => {
      // console.log("loanDetails", payload.data);
      // state.isFetching = false;
      // state.isSuccess = true;
      // state.successMessage = payload.message;
      // state.application.loanDetails = payload.data;
      // state.isUpdatedLoanDetails = true;
    },

    [updateLoanDetails.pending]: (state) => { },
    [updateLoanDetails.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [createCustomer.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application = payload.data;
    },
    [createCustomer.pending]: (state) => { },
    [createCustomer.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [getCustomer.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.customer[payload.data._id] = payload.data;
    },
    [getCustomer.pending]: (state) => {
      // state.isFetching = true;
    },

    [removeCustomer.fulfilled]: (state, { payload }) => {
      state.application = payload.data;
    },
    [removeCustomer.pending]: (state) => { },
    [removeCustomer.rejected]: (state, { payload }) => {
      state.isFetching = false;
    },

    [getAddress.fulfilled]: (state, { payload }) => {
      state.successMessage = payload?.message;
      console.log(payload.data);
      return Object.assign({}, state.customer[payload.data._id], payload.data);
    },
    [getAddress.pending]: (state) => { },
    [getAddress.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [updateAddress.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;

      console.log(payload.data[0]);

      state.application.customers.filter(
        (customer) => customer._id === payload.data[0].customer
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data[0].customer
        )[0].addresses,
        payload.data
      );
    },

    [addPhoneNumber.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;

      const latestCustomerData = payload.data.slice(-1)[0];

      state.application.customers.forEach((customer) => {
        if (customer.phoneNumbers) {
          customer.phoneNumbers = [
            ...customer.phoneNumbers,
            latestCustomerData,
          ];
        }
      });
    },

    [addEmail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;


      state.application.customers.forEach((customer) => {
        if (customer.email) {
          customer.email = [
            ...customer.email,
            payload?.data?.address,
          ];
        }
      });
    },

    [saveCustomerProfile.fulfilled]: (state, { payload }) => {
      state.application.customers.filter(
        (customer) => customer._id === payload.data.customer
      )[0].customerProfile = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data.customer
        )[0].customerProfile,
        payload.data
      );
    },
    [saveCustomerProfile.pending]: (state) => { },
    [saveCustomerProfile.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [createEntity.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;

      state.application.entity = _.merge(
        state.application.entities,
        payload.data.entities
      );
      state.application.customers = _.merge(
        [],
        state.application.customers,
        payload.data.customers
      );
      state.gettingEntityDetails = false;
    },
    [createEntity.pending]: (state) => { },
    [createEntity.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [removeEntity.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.entities = payload.data.entities
      state.gettingEntityDetails = false;
    },
    [removeEntity.pending]: (state) => { },
    [removeEntity.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [createEmployer.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;

      state.application.customers.filter(
        (customer) => customer._id === payload.data[0].customer
      )[0].employers = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data[0].customer
        )[0].employers,
        payload.data
      );

      state.gettingEntityDetails = false;
    },
    [createEmployer.pending]: (state) => { },
    [createEmployer.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
      state.gettingEntityDetails = false;
    },

    [saveEmployer.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;

      state.application.customers.filter(
        (customer) => customer._id === payload.data[0].customer
      )[0].employers = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data[0].customer
        )[0].employers,
        payload.data
      );

      state.gettingEntityDetails = false;
    },
    [saveEmployer.pending]: (state) => { },
    [saveEmployer.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [getASICData.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;

      state.application.entity = _.merge(
        state.application.entities,
        payload.data.entities
      );
      state.application.customers = _.merge(
        [],
        state.application.customers,
        payload.data.customers
      );
      state.gettingASICData = false;
      state.gotASICData = true;
    },
    [getASICData.pending]: (state) => {
      // state.gotASICData = false;
    },
    [getASICData.rejected]: (state, { payload }) => {
      state.errorMessage = payload.message;
    },

    [updateEntity.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.entity = _.merge(
        state.application.entity,
        payload.data.entity
      );
    },
    [updateEntity.pending]: (state) => { },
    [updateEntity.rejected]: (state) => { },

    [getEntity.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      console.log("entity", payload.data);
      state.entity = Object.assign({}, state.entity, payload.data);
    },
    [getEntity.pending]: (state) => { },
    [getEntity.rejected]: (state) => { },

    [getIdentityVerification.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.customers.filter(
        (customer) => customer._id === payload.data._id
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        payload.data
      );

      JSON.stringify(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        null,
        2
      );
    },
    [getIdentityVerification.pending]: (state) => { },
    [getIdentityVerification.rejected]: (state) => { },

    [getDocuments.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.customers.filter(
        (customer) => customer._id === payload.data._id
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        payload.data
      );
    },
    [getCreditFile.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) => customer._id === payload.data._id
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        payload.data
      );
    },
    [getCreditProfile.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      if (payload.data?._id)
        state.creditProfile[payload.data?.customer] = payload?.data;
    },
    [sendPrivacyLink.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.privacyLoading = false;
      state.document[payload.data.documentId] = payload.data.url;
    },

    [sendBankStatementsLink.fulfilled]: (state, { payload }) => {
      state.document[payload.data.documentId] = payload.data.url;
      state.bankStatementsLoading = false;
    },
    [sendBankStatementsLink.rejected]: (state) => {
      state.bankStatementsLoading = false;
    },

    [getFileUrl.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      _.merge(state.document[payload.data.documentId], payload.data.url);
    },
    [updateNotes.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.notes = payload.data;
    },

    [uploadDocument.fulfilled]: (state, { payload }) => {
      state.application.customers.filter(
        (customer) => customer._id === payload.data._id
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data._id
        )[0],
        payload.data
      );
      if (state.uploadPrivacyLoading === true)
        state.uploadPrivacyLoading = false;

      if (state.documentUploadLoading === true) {
        state.documentUploadLoading = false;
      }
      if (state.uploadIDLoading === true) state.uploadIDLoading = false;
    },
    [uploadDocument.pending]: (state) => {
      state.documentUploadLoding = true;
    },
    [uploadDocument.rejected]: (state) => {
      state.documentUploadLoding = false;
      state.uploadPrivacyLoading = false;
      state.uploadIDLoading = false;
    },

    [updateLiabilities.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.application.customers.filter(
        (customer) => customer._id === payload.data.customer
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data.customer
        )[0].liabilities,
        payload.data
      );
    },
    [updateExpenses.fulfilled]: (state, { payload }) => {
      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) => customer._id === payload.data.customer
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data.customer
        )[0].expenses,
        payload.data
      );
    },
    [updateIncome.fulfilled]: (state, { payload }) => {
      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) => customer._id === payload.data.customer
      )[0] = _.merge(
        state.application.customers.filter(
          (customer) => customer._id === payload.data.customer
        )[0].income,
        payload.data
      );
    },

    [createFinanceItem.fulfilled]: (state, { payload }) => {
      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) =>
          customer._id === payload.data[Object.keys(payload.data)[0]].customer
      )[0][Object.keys(payload.data)[0]] =
        payload.data[Object.keys(payload.data)[0]];

      JSON.stringify(
        state.application.customers.filter(
          (customer) =>
            customer._id === payload.data[Object.keys(payload.data)[0]].customer
        )[0].null,
        2
      );
    },
    [deleteFinanceItem.fulfilled]: (state, { payload }) => {
      state.gettingCreditProfile = false;
      state.application.customers.filter(
        (customer) =>
          customer._id === payload.data[Object.keys(payload.data)[0]].customer
      )[0][Object.keys(payload.data)[0]] =
        payload.data[Object.keys(payload.data)[0]];

      JSON.stringify(
        state.application.customers.filter(
          (customer) =>
            customer._id === payload.data[Object.keys(payload.data)[0]].customer
        )[0].null,
        2
      );
    },
  },
  [updateCreditProfile.fulfilled]: (state, { payload }) => {
    state.isFetching = false;
    state.isSuccess = true;
    state.successMessage = payload.message;
    state.application.customers.filter(
      (customer) => customer._id === payload.data.customer
    )[0] = _.merge(
      state.application.customers.filter(
        (customer) => customer._id === payload.data.customer
      )[0].creditProfile,
      payload.data
    );
  },
});

export const { saveValue, saveAsset, saveLoanDetails, clearState } =
  formdataSlice.actions;

export const store = configureStore({
  reducer: {
    userDetails: formdataSlice.reducer,
  },
});

export const stateSelector = (state) => state;
export const userDetailsSelector = (state) => state.userDetails;
export const applicationSelector = (state) => state.userDetails.application;
export const assetSelector = (state) => state.userDetails.application.asset;
export const loanDetailsSelector = (state) =>
  state.userDetails.application.loanDetails;
export const entitySelector = (state) =>
  state.userDetails.application?.entities;
export const customersSelector = (state) =>
  state.userDetails.application.customers;
