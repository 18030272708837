import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import regex from "src/utils/regex";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  createFinanceItem,
  deleteFinanceItem,
  updateLiabilities,
} from "../../store/slices/applicationFormSlice";
import InputAdornment from "@mui/material/InputAdornment";
import _ from "lodash";
import { IconButton, Stack } from "@mui/material";
import FinanceItem from "./FinanceItem";

export default function Liabilities({ liabilities }) {
  const dispatch = useDispatch();
  const [newLiabilityName, setNewLiabilityName] = useState("");

  const [fieldErrors, setFieldErrors] = useState({
    liability: "",
  });

  const validationSchema = Yup.object({
    liability: Yup.string()
      .optional()
      .matches(
        regex.fanciableItemNameRegex,
        "Only letters, dashes (-), forward slashes (/), commas (,), and spaces are allowed."
      )
      .max(25, "Maximum of 25 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    liabilities: (update) => {
      dispatch(updateLiabilities(update));
    },
    createLiability: async () => {
      try {
        if (fieldErrors["liability"] === "") {
          await dispatch(
            createFinanceItem({
              data: {
                name: _.camelCase(newLiabilityName),
                label: newLiabilityName,
                liabilitiesId: liabilities._id,
              },
              endpoint: "liabilities",
            })
          ).unwrap();
          setNewLiabilityName("");
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
    deleteLiability: async (data) => {
      await dispatch(
        deleteFinanceItem({
          data: { liabilityId: data.itemId, liabilitiesId: liabilities._id },
          endpoint: "liabilities",
        })
      ).unwrap();
    },
  };

  return (
    <Grid
      container
      item
      xs={3}
      spacing={0}
      style={{ alignContent: "flex-start" }}
    >
      <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>
        Liabilities (total)
      </Typography>

      {/* <Grid item sm={12}> */}
      <Stack spacing={1}>
        {liabilities?.items?.map((liability) => (
          <FinanceItem
            item={liability}
            handleUpdate={handle.liabilities}
            handleDelete={handle.deleteLiability}
          />
        ))}

        <TextField
          id="outlined-basic"
          type="text"
          size="small"
          label="Add liability"
          variant="outlined"
          name="liability"
          onChange={async (event) => {
            let isValid = true;
            isValid = regex.fanciableItemNameRegex.test(event.target.value) && event.target.value?.length <= 25;
            if (!isValid) {
              await validateField('liability', event.target.value);
              return;
            } else {
              setNewLiabilityName(event.target.value);
              await validateField("liability", event.target.value);
            }
          }}
          value={newLiabilityName}
          error={fieldErrors?.liability}
          helperText={fieldErrors?.liability}
          placeholder="Liability name"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(event) => {
                    if (newLiabilityName) {
                      handle.createLiability(event);
                    }
                  }}
                  style={{
                    background: newLiabilityName ? "#616161" : "none",
                    color: newLiabilityName ? "#fff" : "rgba(0, 0, 0, 0.6)",
                    height: "30px",
                  }}
                  edge="end"
                >
                  +
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Grid>
  );
}
