import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Stack, Button } from "@mui/material";
import PersonIcon from "@mui/icons-material/PersonTwoTone";
import {
  entitySelector,
  getDocuments,
  removeCustomer,
} from "../../store/slices/applicationFormSlice";
import Identity from "./Identity";
import CreditProfile from "./CreditProfile";
import usePoll from "src/utils/usePoll";
import Employer from "./Employer";
import AddEmployer from "./AddEmployer";
import CustomerContactDetails from "./CustomerContactDetails";
import CustomerPersonalDetails from "./CustomerPersonalDetails";
import CustomerPersonalProfile from "./CustomerPersonalProfile";
import CustomerPersonalFinances from "./CustomerPersonalFinances.js";
import CustomerPrivacyAgreement from "./CustomerPrivacyAgreement";
import CustomerFinancialStatement from "./CustomerFinancialStatement";
import CustomerOtherDocuments from "./CustomerOtherDocuments";
import { userSelector } from "src/store/slices/userSlice";

const CustomerDetails = React.memo(({
  customer,
  applicationId,
  applicationType,
  index,
}) => {
  const dispatch = useDispatch();
  const entity = useSelector(entitySelector);
  const user = useSelector(userSelector)
  const [delay, setDelay] = useState(null);
  const [confirmRemoveCustomer, setConfirmRemoveCustomer] = useState({});

  usePoll(async () => {
    dispatch(getDocuments(customer._id));
  }, delay);

  const { role, appointmentDate, shares, employers, phoneNumbers } = customer;
  let temp = applicationId + "-" + index;
  return (
    <>
      <Grid
        container
        item
        xs={12}
        style={{
          padding: "0 0 30px",
        }}
      >
        <Grid item xs={12} md={2}>
          <Stack direction="row" style={{ marginBottom: "20px" }}>
            <PersonIcon style={{ marginRight: "15px" }} />
            <Typography fontWeight={600}>Guarantor #{index + 1}</Typography>
          </Stack>
          <Stack spacing={1}>
            {role && (
              <Typography style={{ fontSize: "14px" }}>
                <strong>Role:</strong> <br />
                {role}
              </Typography>
            )}
            {appointmentDate && (
              <Typography style={{ fontSize: "14px" }}>
                <strong>Appointment date:</strong> <br />
                {appointmentDate}
              </Typography>
            )}
            {shares && entity?.[0]?.shares?.[0]?.numberOfShares && (
              <Typography style={{ fontSize: "14px" }}>
                <strong>Shares:</strong> <br />
                {shares}/{entity?.[0]?.shares?.[0]?.numberOfShares} (
                {((shares / entity?.[0]?.shares?.[0]?.numberOfShares) * 100).toFixed(2)}
                )%
              </Typography>
            )}
          </Stack>
          {!confirmRemoveCustomer[customer._id] && (
            <Button

              variant="outlined"
              size="small"
              style={{
                padding: 0,
                margin: "10px 0 0 ",
                // color: "#555",
              }}
              onClick={() =>
                setConfirmRemoveCustomer({ [customer._id]: true })
              }
            >
              Remove
            </Button>
          )}
          {confirmRemoveCustomer[customer._id] && <>
            {/* <Stack style={{ margin: "10px 0 0" }} justifyContent="start"> */}
            <Typography>Are you sure?</Typography>
            <Button
              size="small"
              variant="link"
              style={{
                padding: 0,
                // marginLeft: "20px",
                // color: "#1976d2",
              }}
              onClick={() =>
                setConfirmRemoveCustomer({ [customer._id]: false })
              }
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="link"
              style={{
                padding: 0,
                // marginLeft: "20px",
                color: "#d32f2f",
              }}
              onClick={() => {
                dispatch(
                  removeCustomer({
                    customerId: customer._id,
                    applicationId
                  })
                );
                setConfirmRemoveCustomer({ [customer._id]: false });
              }}
            >
              Remove
            </Button>
            {/* </Stack> */}
          </>
          }
        </Grid>

        <Grid container item xs={12} md={10} style={{ padding: "0 0 0 10px" }}>
          {/* Customer Personal Details */}
          <CustomerPersonalDetails customer={customer} applicationId={applicationId} key={temp} />

          {/* Customer Contact Details */}
          <CustomerContactDetails customer={customer} applicationId={applicationId} />

          {/* Customer Personal Profile */}
          <CustomerPersonalProfile customer={customer} />

          {/* Customer Personal Finances */}
          <CustomerPersonalFinances customer={customer} applicationType={applicationType} />

          {(applicationType === "consumer" || applicationType === "personal") &&
            employers?.map((employer, index) => (
              <Employer key={employer.id} customer={customer} employer={employer} index={index} />
            ))}

          {((applicationType === "consumer" ||
            applicationType === "personal") &&
            (!customer?.employers?.[0] ||
              customer?.employers?.reduce((acc, item) => {
                console.log({ acc: item?.timeOfEmployment * 1 ? item?.timeOfEmployment * 1 + acc : acc, item: item?.timeOfEmployment })
                return item?.timeOfEmployment * 1 ? item?.timeOfEmployment * 1 + acc : acc
              }, 0)) < 12) && (
              <AddEmployer customerId={customer._id} numberOfEmployers={customer?.employers?.length} />
            )}

          {customer && (
            <CustomerPrivacyAgreement customer={customer} applicationId={applicationId} />
          )}

          {customer && <Identity customer={customer} applicationId={applicationId} />}

          {customer && user.userType === "master" && <CreditProfile customer={customer} applicationId={applicationId} />}

          {customer && user.userType === "master" && <Grid
            item
            xs={12}
            style={{
              margin: "0 0 30px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              paddingBottom: "30px",
            }}
          >
            {/* Customer Financial Statement */}
            {customer && user.userType === "master" && <CustomerFinancialStatement customer={customer} applicationId={applicationId} />}


          </Grid>}

          {/* Customer Other Documents */}
          <CustomerOtherDocuments customer={customer} applicationId={applicationId} />
        </Grid>
      </Grid>
    </>
  );
})

export default CustomerDetails