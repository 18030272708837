import React, { useState } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import {
  saveCustomerProfile,
  updateCustomer,
} from "src/store/slices/applicationFormSlice";
import moment from "moment";
import { DateField } from "@mui/x-date-pickers";
import RadioButtonGroup from "../Utils/RadioButtonGroup";
import regex from "src/utils/regex";
import { parse } from "path";

const CustomerPersonalDetails = React.memo(({ customer, applicationId, key }) => {

  const dispatch = useDispatch();
  const [allValues, setAllValue] = useState({
    firstName: customer?.firstName || "",
    middleNames: customer?.middleNames || "",
    lastName: customer?.lastName || "",
    IDNumber: customer?.IDNumber || "",
    dateOfBirth: (customer?.dateOfBirth && dayjs(customer?.dateOfBirth,'DD-MM-YYYY')) || "",
    residencyStatusValue: customer?.customerProfile?.residencyStatus,
    maritalStatusValue: customer?.customerProfile?.maritalStatus,
  });

  const [fieldErrors, setFieldErrors] = useState({
    firstName: "",
    middleNames: "",
    lastName: "",
    IDNumber: "",
    dateOfBirth: "",
  });

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("First Name is required")
      .matches(regex.nameRegex, "Only letters and ' - characters allowed.")
      .min(2, "Maximum of 2 characters")
      .max(50, "Maximum of 50 characters"),
    middleNames: Yup.string()
      .optional()
      .matches(regex.nameRegex, "Only letters and ' - characters allowed.")
      .max(50, "Maximum of 50 characters"),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(regex.nameRegex, "Only letters and ' - characters allowed.")
      .min(2, "Maximum of 2 characters")
      .max(50, "Maximum of 50 characters"),
    IDNumber: Yup.string()
      .optional()
      .matches(
        regex.driving_passport_regex,
        "Only alphanumeric, space and - characters allowed."
      )
      .min(6, "Maximum of 6 characters")
      .max(15, "Maximum of 15 characters"),
    dateOfBirth: Yup.date()
      .typeError("Please enter a valid date")
      .optional()
      .max(new Date(), "Date is not select in the future"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };
  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      if (
        name === "firstName" ||
        name === "middleNames" ||
        name === "lastName"
      ) {
        isValid = regex.nameRegex.test(e) && e?.length <= 50;
      } else if (name === "IDNumber") {
        isValid = regex.driving_passport_regex.test(e) && e?.length <= 15;
      }

      if (!isValid) {
        await validateField(name, e);
        return;
      }

      setAllValue({ ...allValues, [name]: e });
      await validateField(name, e);
    },
    onChangeRadio: (value, name) => {
      setAllValue({ ...allValues, [name]: value });
    },
    handleSaveCustomer: (data) => {
      dispatch(
        updateCustomer({
          customerId: customer._id,
          data: {
            application: applicationId,
            ...data,
          },
        })
      );
    },
    handleResidencyStatus: (value) => {
      dispatch(
        saveCustomerProfile({
          residencyStatus: value,
          customer: customer._id,
        })
      );
    },
    handleMaritalStatus: (value) => {
      dispatch(
        saveCustomerProfile({
          maritalStatus: value,
          customer: customer._id,
        })
      );
    },
    handleLicenseNumber: () =>
      customer
        ? customer?.documents?.find((doc) => {
            return doc.details?.licence_number
              ? doc.details?.licence_number
              : "";
          })?.details?.licence_number
        : "",
    blurFn: (fieldName, value) => {
      try {
        let fieldErr = fieldErrors[fieldName];
          if (fieldName == "middleNames") {
            let isValid = regex.nameOptionalRegex.test(value)
            if(isValid){
              fieldErr =""
            }else{
              fieldErr = "Only letters and ' - characters allowed.";
            }
          }else if(fieldName == "IDNumber") {
            let isValid = regex.drivingPassportRegexOptional.test(value)
            if(isValid){
              fieldErr =""
            }else{
              fieldErr = "Only alphanumeric, space and - characters allowed.";
            }
          }
          // else if(fieldName == "dateOfBirth"){
          //   let isValid = regex.dateOfBirthRegexOptional.test(value)
          //   if(isValid){
          //     fieldErr =""
          //   }else{
          //     fieldErr = "Only alphanumeric, space and - characters allowed.";
          //   }
          // }
          setFieldErrors((pre) => {
              return {
                ...pre,
                [fieldName]: fieldErr,
              };
          });
          if (fieldErr === "") {
            handle.handleSaveCustomer({
              [fieldName]:
                fieldName === "dateOfBirth"
                  ? dayjs(value)?.format("DD-MM-YYYY")
                  : value,
            });
          }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
  };

  return (
    <React.Fragment>
      <Grid
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Grid item md={12} sm={12} style={{ margin: "0 0 10px" }}>
          <Typography id="input-slider" fontWeight={600}>
            Personal details
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Stack spacing={1} direction="row" style={{ margin: "0 0 10px" }}>
            <Grid item md={4} sm={4}>
              <TextField
                fullWidth
                required
                id="outlined-basic"
                type="text"
                name="firstName"
                label="First name"
                variant="filled"
                size="small"
                value={allValues?.firstName}
                error={fieldErrors?.firstName}
                helperText={fieldErrors?.firstName}
                onChange={(event) =>
                  handle.onChangeField(event?.target?.value, "firstName")
                }
                onBlur={(e) => handle.blurFn("firstName", e.target.value)}
              />
            </Grid>

            <Grid item md={4} sm={4}>
              <TextField
                fullWidth
                id="outlined-basic"
                type="text"
                name="middleNames"
                label="Other given names"
                variant="filled"
                size="small"
                value={allValues?.middleNames}
                error={fieldErrors?.middleNames}
                helperText={fieldErrors?.middleNames}
                onChange={(event) =>
                  handle.onChangeField(event?.target?.value, "middleNames")
                }
                onBlur={(e) => handle.blurFn("middleNames", e.target.value)}
              />
            </Grid>

            <Grid item md={4} sm={4}>
              <TextField
                fullWidth
                required
                id="outlined-basic"
                type="text"
                name="lastName"
                label="Last name"
                variant="filled"
                size="small"
                value={allValues?.lastName}
                error={fieldErrors?.lastName}
                helperText={fieldErrors?.lastName}
                onChange={(event) =>
                  handle.onChangeField(event?.target?.value, "lastName")
                }
                onBlur={(e) => handle.blurFn("lastName", e.target.value)}
              />
            </Grid>
          </Stack>

          {/* <Grid item md={12} sm={12}>
            <Typography id="input-slider" gutterBottom>
            ID Number
            </Typography>
            </Grid> */}
          <Stack spacing={1} direction="row" style={{ margin: "0 0 10px" }}>
            <Grid item sm={6}>
              <DateField
                // required
                autoComplete={false}
                label="Date of birth"
                size="small"
                variant="filled"
                format="DD-MM-YYYY"
                value={allValues?.dateOfBirth || null}
                name="dateOfBirth"
                style={{ background: "#ffffff" }}
                // maxDate={new Date()}
                error={fieldErrors?.dateOfBirth ? true : false}
                helperText={fieldErrors?.dateOfBirth}
                onChange={(event) =>
                  handle.onChangeField(event?.$d || null, "dateOfBirth")
                }
                onBlur={(e) =>
                  handle.blurFn("dateOfBirth", allValues?.dateOfBirth)
                }
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                // required
                fullWidth
                id="outlined-basic"
                type="text"
                name="IDNumber"
                label="Driver licence/Passport no."
                variant="filled"
                size="small"
                value={allValues?.IDNumber}
                error={fieldErrors?.IDNumber}
                helperText={fieldErrors?.IDNumber}
                onChange={(event) =>
                  handle.onChangeField(event.target.value, "IDNumber")
                }
                onBlur={(e) => handle.blurFn("IDNumber", e.target.value)}
              />
            </Grid>
          </Stack>
          <Grid container spacing={1}>
            <Grid item sm={4}>
              <Stack>
                <Typography variant="subtitle2">Residency status</Typography>
                <RadioButtonGroup
                  required
                  name={"residencyStatusValue"}
                  value={allValues?.residencyStatusValue}
                  handleValue={(value) => {
                    handle.onChangeRadio(value, "residencyStatusValue");
                    handle.handleResidencyStatus(value);
                  }}
                  options={[
                    { icon: "House", label: "Citizen / PR" },
                    { icon: "HomeWork", label: "Working visa" },
                  ]}
                />
              </Stack>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2">Marital status</Typography>
              <RadioButtonGroup
                required
                value={allValues?.maritalStatusValue}
                name={"maritalStatusValue"}
                handleValue={(value) => {
                  handle.onChangeRadio(value, "maritalStatusValue");
                  handle.handleMaritalStatus(value);
                }}
                options={[
                  { icon: "House", label: "Married/Defacto" },
                  { icon: "HomeWork", label: "Single/Widowed" },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default CustomerPersonalDetails;
