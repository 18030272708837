import React from "react";
import { Grid, Typography } from "@mui/material";
import Assets from "./Assets";
import Liabilities from "./Liabilities";
import Income from "./Income";
import Expenses from "./Expenses";

const CustomerPersonalFinances = ({ customer, applicationType }) => {

  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Grid item md={12} sm={12} style={{ margin: "0 0 10px" }}>
          <Typography fontWeight={600}>Personal Finances</Typography>
        </Grid>

        <Grid container item xs={12} spacing={1}>
          <Assets assets={customer.customerAssets} customerId={customer._id} />
          <Liabilities
            liabilities={customer.liabilities}
            customerId={customer._id}
          />

          {(applicationType === "consumer" ||
            applicationType === "personal") && (
            <React.Fragment>
              <Income income={customer.income} customerId={customer._id} />

              <Expenses
                expenses={customer.expenses}
                customerId={customer._id}
              />
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CustomerPersonalFinances;
