import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useDispatch, useSelector } from "react-redux";
import { userSelector, resetPassword } from "src/store/slices/userSlice";
import _ from "lodash";
import { Typography } from "@mui/material";
import regex from "src/utils/regex";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { key } = useParams();

  const [isSame, setIsSame] = useState(null);

  const user = useSelector(userSelector);

  const [allValues, setAllValues] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const [passwordError, setPasswordError] = useState(false);

  const handle = {
    onChangeFn: (value, name) => {
      if (name === "newPassword") {
        if (regex.password.test(value)) setPasswordError(false);
        else setPasswordError(true);
      } else if (name === "confirmNewPassword") {
        if (value === allValues?.newPassword) setIsSame(true);
        else setIsSame(false);
      }
      setAllValues({
        ...allValues,
        [name]: value,
      });
    },
    requestResetPassword: async () => {
      let res = await dispatch(
        resetPassword({ newPassword: allValues?.newPassword, key })
      ).unwrap();
      if (res?.data?.token) navigate("/applications");
    },
  };

  useEffect(() => {
    if (user?._id) navigate("/dashboard/applications");
  }, [user]);

  return (
    <React.Fragment>
      <Grid
        container
        item
        height="calc(100vh - 64px)"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        marginTop="-64px"
      >
        <Grid
          container
          item
          xs={12}
          sm={6}
          // height="100vh"
          justifyContent="center"
          alignItems="center"
          direction="column"
          // textAlign="left"
          style={{ padding: "100px" }}
          // spacing={1}
        >
          <Typography
            variant="h4"
            style={{
              letterSpacing: "-1.8px",
              fontWeight: 800,
              marginBottom: "10px",
            }}
          >
            Welcome back!
          </Typography>
          {/* <br /> */}
          <Typography
            // variant="body1"
            fontWeight="400"
            style={{ marginBottom: "10px" }}
          >
            Sign into your account below.
          </Typography>

          <TextField
            fullWidth
            type="password"
            name="newPassword"
            label="Password"
            value={allValues?.newPassword}
            margin="dense"
            onChange={(event) =>
              handle.onChangeFn(event.target.value, "newPassword")
            }
            error={passwordError}
            helperText={
              passwordError
                ? "password must contain an uppercase letter, a number, a special character and be 8 or more characters in length."
                : null
            }
          />
          <TextField
            fullWidth
            type="password"
            name="confirmNewPassword"
            label="Password"
            value={allValues?.confirmNewPassword}
            margin="dense"
            onChange={(event) =>
              handle.onChangeFn(event.target.value, "confirmNewPassword")
            }
            error={isSame === false}
            helperText={isSame === false && "Your passwords do not match."}
          />
          <LoadingButton
            fullWidth
            loading={""}
            loadingPosition="end"
            variant="contained"
            disabled={!isSame}
            onClick={() => handle.requestResetPassword()}
            style={{ marginTop: "20px" }}
          >
            Login
          </LoadingButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
