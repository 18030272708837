import { Button, Card } from "@mui/material";
import React from "react";

const Sidebar = (props) => {
  const { open, title, onClickFn, bottom, right, left, disabled, component } =
    props;

  const handle = {
    paddingFn: () => {
      switch (title) {
        case "Expand lenders":
          return "12px 12px 12px 12px";
        case "Notes":
          return "12px 0px";
        case "Activity log":
          return "12px 0px";
        default:
          break;
      }
    },
    widthFn: () => {
      switch (title) {
        case "Expand lenders":
          return `calc(${open ? 75 : 20}vw - 25px)`;
        case "Notes":
          return `${open ? "calc(25vw - 25px)" : 0}`;
        case "Activity log":
          return `${open ? "calc(25vw - 25px)" : 0}`;
        default:
          break;
      }
    },
    leftFn: () => {
      switch (title) {
        case "Notes":
          return left ? `${open ? "calc(25vw - 34px)" : "-11px"}` : "";
        case "SMS (coming soon)":
          return left ? `${open ? "calc(25vw - 27px)" : "-60px"}` : "";
        case "Emails (coming soon)":
          return left ? `${open ? "calc(25vw - 27px)" : "-67px"}` : "";
        case "Activity log":
          return left ? `${open ? "calc(25vw - 35px)" : "-32px"}` : "";
        default:
          break;
      }
    },
  };

  return (
    <div>
      <Button
        disabled={disabled}
        color={`${open ? "primary" : "secondary"}`}
        elevation={1}
        variant={`${open ? "contained" : "outlined"}`}
        style={{
          transform: "rotate(90deg)",
          transition: "all 0.5s ease-in-out 0s",
          padding: "5px 5px",
          minWidth: 0,
          position: "fixed",
          borderColor: "rgba(0, 0, 0, 0.10)",
          right: right ? `calc(${open ? 75 : 20}vw - ${open ? "35px" : "70px"})` : "",
          left: handle.leftFn(),
          bottom: left && !bottom ? 480 : bottom ? bottom : "",
          writingMode: "vertical-rl",
          textOrientation: "sideways-right",
          marginTop: "25px",
          zIndex: open ? "999" : ""
        }}
        onClick={onClickFn}
      >
        {open ? "Close" : title}
      </Button>
      <Card
        id="productSelectorWrapper"
        elevation={2}
        style={{
          background: "#fff",
          padding: handle.paddingFn(),
          width: handle.widthFn(),
          position: "fixed",
          overflowY: "auto",
          overflowX: "hidden",
          height: "calc(100vh - 63px)",
          scrollbarColor: "white rebeccapurple",
          scrollbarWidth: "thin",
          right: right ? 0 : "",
          left: left ? 0 : "",
          top: 64,
          zIndex: 1,
          transition: "all 0.5s ease-in-out 0s",
        }}
        sx={{
          "&.MuiCard-root::-webkit-scrollbar": {
            width: "7px",
            height: "7px",
          },
          "&.MuiCard-root::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&.MuiCard-root::-webkit-scrollbar-thumb": {
            backgroundColor: "#999",
          },
          "&.MuiCard-root::-webkit-scrollbar-thumb:hover": {
            background: "#616161",
          },
        }}
      >
        {component}
      </Card>
    </div>
  );
};

export default Sidebar;
