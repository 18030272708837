import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";
import { addPhoneNumber } from "../../store/slices/applicationFormSlice";
import regex from "src/utils/regex";

export default function PhoneNumber({ phoneNumber, customerId }) {
  const dispatch = useDispatch();

  const [allValues, setAllValue] = useState({
    number: phoneNumber?.number,
  });

  const [fieldErrors, setFieldErrors] = useState({
    number: "",
  });

  const validationSchema = Yup.object({
    number: Yup.string()
      .required("number field is required")
      .matches(regex.phoneNumber, "Enter a valid Australian mobile number.")
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    onChangeField: async (e, name) => {
      setAllValue({ ...allValues, [name]: e });
      await validateField(name, e);
    },
    blurFn: (fieldName, value) => {
      try {
        if (fieldErrors[fieldName] === "") {
          dispatch(
            addPhoneNumber({
              phoneNumberId: phoneNumber?._id,
              customerId,
              number: value,
            })
          );
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
  };

  return (
    <InputMask
      mask="0999 999 999"
      value={allValues?.number || ""}
      disabled={false}
      onChange={(event) => handle.onChangeField(event?.target?.value, "number")}
      onBlur={(e) => handle.blurFn("number", e?.target?.value)}
      maskChar=" "
    >
      {() => (
        <TextField
          id="outlined-basic"
          label="Mobile number"
          variant="filled"
          size="small"
          error={fieldErrors?.number}
          helperText={fieldErrors?.number}
        />
      )}
    </InputMask>
  );
}
