import { assetTypes, typeOfSaleList } from "src/constants";
import criteria from "src/utils/criteria";

import lowDoc from "./lowDoc";
import others from "./others";

const {
  assetType,
  livingArrangements,
  termIsLessThan,
  typeOfSale,
  assetValueBetween,
  ageOfAssetGreaterThan,
  ageOfAssetAtEndGreaterThan,
  brokerageIsBetween,
  assetValueGreaterThan,
  brokerageIsEqualTo
} = criteria;

export default {
  date: "11 Sep 2023",
  lender: "resimac",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fresimac-logo.gif",
  fees: [
    {
      name: "Authorised dealer sale fee",
      value: 495,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE)]
    },
    {
      name: "Authorised electric vehicle dealer sale fee",
      value: 295,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.EV_SALE)]
    },
    {
      name: "Private sale fee",
      value: 695,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale(typeOfSaleList.DEALER_SALE)]
    },
    {
      name: "Private sale fee",
      value: 695,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")]
    },
    {
      name: "Origination fee",
      value: 990,
      capitalised: true,
      frequency: "upfront",
    },
  ],
  brokerage: [
    {
      value: 3,
      max: 3,
      valueType: "percentage",
      criteria: [assetValueBetween(10000, 250000)],
    },
  ],
  loading: [
    {
      name: "Electric vehicle discount",
      value: -0.1,
      valueType: "percentage",
      criteria: [assetType(["Electric Motor Vehicle (<4.5 tonnes)"])],
    },
    {
      name: "Classic car",
      value: 2,
      valueType: "percentage",
      criteria: [assetType(["Classic car"])],
    },
    {
      name: "Non property owner",
      value: 1.5,
      valueType: "percentage",
      criteria: [livingArrangements(["Renting", "Boarding", "Living w/ parents"]),],
    },
    {
      name: "Asset age >3 years",
      value: 0.5,
      valueType: "percentage",
      criteria: [ageOfAssetGreaterThan(36)],
    },
    {
      name: "Asset age >16 years",
      value: 1,
      valueType: "percentage",
      criteria: [ageOfAssetGreaterThan(192)],
    },
    {
      name: "Private sale",
      value: 1,
      valueType: "percentage",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Equity raise/Refinance",
      value: 1,
      valueType: "percentage",
      criteria: [typeOfSale(typeOfSaleList.SALE_BUYBACK_EQUITY)],
    },
    {
      name: "Refinance",
      value: 1,
      valueType: "percentage",
      criteria: [typeOfSale(typeOfSaleList.MID_TERM_REFINANCE)],
    },
    {
      name: "Term <24 months",
      value: 0.5,
      valueType: "percentage",
      criteria: [termIsLessThan(24)],
    },
    {
      name: "Age of asset at end >16 years",
      value: 1.5,
      valueType: "percentage",
      criteria: [ageOfAssetAtEndGreaterThan(16)],
    },
    
    {
      name: "Brokerage 6% for <$50000",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6), assetValueBetween(4999, 49999.99)],
    },
    {
      name: "Brokerage 7% for <$50000",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(7), assetValueBetween(4999, 49999.99)],
    },
    {
      name: "Brokerage 8% for <$50000",
      value: 1.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(8), assetValueBetween(4999, 49999.99)],
    },
    {
      name: "Brokerage 9% for <$50000",
      value: 2,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(9), assetValueBetween(4999, 49999.99)],
    },
    {
      name: "Brokerage 10% for <$50000",
      value: 2.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(10), assetValueBetween(4999, 49999.99)],
    },

    {
      name: "Brokerage 6% for >$50000",
      value: 0.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(6), assetValueGreaterThan(49999.99)],
    },
    {
      name: "Brokerage 7% for >$50000",
      value: 1,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(7), assetValueGreaterThan(49999.99)],
    },
    {
      name: "Brokerage 8% for >$50000",
      value: 1.5,
      valueType: "percentage",
      criteria: [brokerageIsEqualTo(8), assetValueGreaterThan(49999.99)],
    },
  ],
  info: [
    `Lite Doc, BAS and ATO portal require and may require for Bank Statements`,
    `Full Doc, Financials and Bank Statements`,
    `Max exposure, SME 350K, Large Corps 500K, Schools 250K, Government 2M`
  ],
  productTiers: [
    ...lowDoc,
    ...others
  ],
};