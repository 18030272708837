export const typeOfSaleList = {
  DEALER_SALE: "Authorised seller",
  EV_SALE: "Authorised electric vehicle seller",
  PRIVATE_SALE: "Private sale",
  MID_TERM_REFINANCE: "Mid-term refinance",
  BALLOON_RESIDUAL_REFINANCE: "Balloon / Residual Refinance",
  SALE_BUYBACK_EQUITY: "Sale & buyback / Equity",
};

export const assetTypes = {
  MOTOR_VEHICLE_UNDER_4_5_TONNES: "Motor Vehicle (<4.5 tonnes)",
  COMMERCIAL_SMALL_MEDIUM_LESS_THAN_12_TONNES:
    "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
  COMMERCIAL_HEAVY_VEHICLE_GREATER_THAN_12_TONNES:
    "Commercial (Heavy) Vehicles (>12 Tonnes)",
};

export const productTypes = [
  "Standard",
  "Low doc",
  "Light doc",
  "Full doc",
  "Adverse",
];

export const livingArrangementsTypes = {
  PROPERTY_OWNER: "Property owner",
  RENTING: "Renting",
  BOARDING: "Boarding",
  LIVING_WITH_PARENTS: "Living w/ parents",
};

export const criteriaTypes = ["No deposit", "No property"];

export const commercialLenderList = [
  "Angle Finance",
  "ANZ",
  "Azora Asset Finance",
  "Captial Finance",
  "Finance One",
  "Flexi",
  "Group & General Finance",
  "GRENKE",
  "Grow",
  "Liberty",
  "Macquarie",
  "Metro",
  "Pepper",
  "Plenti",
  "resimac",
  "ScotPac",
  "Selfco",
  "Shift",
  "Westpac",
  "Volkswagen Financial Services",
];

export const consumerLenderList = [
  "Affordable Car Loans",
  "Australian Premier Finance",
  "Car Start",
  "Finance One",
  "Gedda Money",
  "Greenlight",
  "Liberty",
  "Macquarie",
  "Money3",
  "MoneyPlace",
  "NOW",
  "Pepper",
  "Plenti",
  "WISR",
];

export const sortList = [
  ["fit", "desc"],
  ["repayments", "asc"],
  ["rate", "asc"],
  ["loanAmount", "asc"],
  ["fees", "asc"],
];

export const employmentTypes = {
  FULL_TIME: "Full-time",
  PART_TIME: "Part-time",
  CASUAL: "Casual",
  CONTRACT: "Contract",
  SELF_EMPLOYED: "Self employed",
  BENEFITS: "Benefits",
};

export const makeType = {
  Abarth: "Abarth",
  Alpine: "Alpine",
  Aston_Martin: "Aston Martin",
  Audi: "Audi",
  Bentley: "Bentley",
  BMW: "BMW",
  BMW_ALPINA: "BMW ALPINA",
  Caterham: "Caterham",
  Chevrolet: "Chevrolet",
  Chrysler: "Chrysler",
  Citroen: "Citroen",
  Ferrari: "Ferrari",
  Fiat: "Fiat",
  Ford: "Ford",
  Genesis: "Genesis",
  GWM: "GWM",
  Haval: "Haval",
  Honda: "Honda",
  Hyundai: "Hyundai",
  Isuzu: "Isuzu",
  Iveco: "Iveco",
  Jaguar: "Jaguar",
  Jeep: "Jeep",
  Kia: "Kia",
  Lamborghini: "Lamborghini",
  Land_Rover: "Land Rover",
  LDV: "LDV",
  Lexus: "Lexus",
  Lotus: "Lotus",
  Mahindra: "Mahindra",
  Maserati: "Maserati",
  Mazda: "Mazda",
  McLaren: "McLaren",
  Mercedes_Benz: "Mercedes-Benz",
  Maybach: "Maybach",
  MG: "MG",
  MINI: "MINI",
  Mitsubishi: "Mitsubishi",
  Morgan: "Morgan",
  Nissan: "Nissan",
  Peugeot: "Peugeot",
  Porsche: "Porsche",
  Ram: "Ram",
  Renault: "Renault",
  Rolls_Royce: "Rolls-Royce",
  Skoda: "Skoda",
  Ssangyong: "Ssangyong",
  Subaru: "Subaru",
  Suzuki: "Suzuki",
  Tesla: "Tesla",
  Toyota: "Toyota",
  Volkswagen: "Volkswagen",
  Volvo: "Volvo",
};
