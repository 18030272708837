import React from "react";
import { useDispatch } from "react-redux";
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { addAddress, updateAddress } from "src/store/slices/applicationFormSlice";
import Email from "./Email";
import PhoneNumber from "./PhoneNumber";
import GoogleAddressSearch from "../Utils/GoogleAddressSearch";

const CustomerContactDetails = React.memo(({ customer }) => {
  const dispatch = useDispatch();

  const handleAddAddress = (newAddressValue) => {
    if (newAddressValue) {
      dispatch(
        addAddress({
          customerId: customer._id,
          address: newAddressValue,
        })
      );
    }
  };

  const handleUpdateAddress = (newAddressValue) => {
    if (newAddressValue) {
      dispatch(
        updateAddress(
          newAddressValue,
        )
      );
    }
  };
  const timeAtAddressesRaw = customer?.addresses?.reduce((acc, item) => {
    acc[0] = item.yearsAt * 1 + acc[0]
    acc[1] = item.monthsAt * 1 + acc[1]

    return acc
  }, [0, 0])

  const timeAtAddressesLessThanSixMonths = (timeAtAddressesRaw) => {
    return (timeAtAddressesRaw[0] + (timeAtAddressesRaw[1] / 12)) < 2
  }

  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Grid item md={12} sm={12} style={{ margin: "0 0 10px" }}>
          <Typography id="input-slider" fontWeight={600}>
            Contact details
          </Typography>
        </Grid>
        <Stack spacing={1} direction="row" style={{ margin: "0 0 10px" }}>
          <Grid item sm={6}>
            <Email
              customerId={customer?._id}
              email={customer ? customer?.emails?.slice(-1)[0] : ""}
            />
          </Grid>
          <Grid item sm={6}>
            <PhoneNumber
              customerId={customer?._id}
              phoneNumber={customer ? customer?.phoneNumbers?.slice(-1)[0] : ""}
            />
          </Grid>
        </Stack>

        {customer?.addresses.map((address, i) => <Grid
          container
          item
          spacing={1}
          sm={12}
          md={12}
          key={address._id}
          alignItems="end"
          style={{ marginBottom: 5 }}
        >
          <Grid item sm={8} md={8} >
            <Typography variant="caption">{i < 1 ? "Current address" : "Previous address"}</Typography>
            <GoogleAddressSearch
              handleCreateAddress={handleAddAddress}
              address={address}
            />

          </Grid>
          <Grid item md={2} >
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel id="years-at-label">Years at</InputLabel>
              <Select
                labelId="years-at-label"
                id="demo-simple-select"
                value={address.yearsAt}
                label="Years at"
                onChange={event => {
                  handleUpdateAddress({ _id: address._id, yearsAt: event.target.value })
                }}
              >
                {[...Array(31).keys()].map(i => <MenuItem value={i}>{i}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel id="months-at-label">Months at</InputLabel>
              <Select
                labelId="months-at-label"
                id="demo-simple-select"
                value={address.monthsAt}
                label="Months at"
                onChange={event => {
                  handleUpdateAddress({ _id: address._id, monthsAt: event.target.value })
                }}
              >
                {[...Array(13).keys()].map(i => <MenuItem value={i}>{i}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        )}

        {(!customer?.addresses?.slice(-1)[0] || timeAtAddressesLessThanSixMonths(timeAtAddressesRaw))
          && <Grid container item spacing={3} sm={12} md={12} alignItems="end" >
            <Grid item sm={8} md={8} >
              {timeAtAddressesLessThanSixMonths(timeAtAddressesRaw) &&
                <Typography variant="caption">{!customer?.addresses?.slice(-1)[0] ? "Add current address" : "Add previous address"}</Typography>}
              <GoogleAddressSearch
                handleCreateAddress={handleAddAddress}
                // address={customer ? customer?.addresses?.slice(-1)[0] : ""}
              />

            </Grid>
            <Grid item md={2} >
              <FormControl fullWidth size="small" variant="filled"
              // disabled={!customer?.addresses?.slice(-1)[0]}
              >
                <InputLabel id="years-at-label">Years at</InputLabel>
                <Select

                  labelId="years-at-label"
                  id="demo-simple-select"
                  // value={age}
                  label="Years at"
                  onChange={event => {
                    handleUpdateAddress({ _id: customer?.addresses?.slice(-1)?.[0]?._id, yearsAt: event.target.value })
                  }}
                >
                  {[...Array(31).keys()].map(i => <MenuItem value={i}>{i}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl fullWidth size="small" variant="filled"
              // disabled={!customer?.addresses?.slice(-1)[0]}
              >
                <InputLabel id="months-at-label">Months at</InputLabel>
                <Select


                  labelId="months-at-label"
                  id="demo-simple-select"
                  // value={age}
                  label="Months at"
                  onChange={event => {
                    handleUpdateAddress({ _id: customer?.addresses?.slice(-1)?.[0]?._id, yearsAt: event.target.value })
                  }}
                >
                  {[...Array(13).keys()].map(i => <MenuItem value={i}>{i}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        }
      </Grid>
    </React.Fragment>
  );
});

export default CustomerContactDetails;