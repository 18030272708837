import React, { useState } from "react";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import convertToOrdinal from "src/utils/convertToOrdinal";
import readableABNString from "../../utils/readableABNString";
import * as Yup from "yup";
import regex from "src/utils/regex";

const AutoSearchHOC = (props) => {
  const {
    setBusinessSearchValue,
    setEntityOptions,
    application,
    loadingBusinessSearch,
    entityOptions,
    updatedSearch,
    businessSearchValue,
    applicationType,
    fieldErrors,
    setFieldErrors,
  } = props;

  const validationSchema = Yup.object({
    business_search: Yup.string()
      .optional()
      .matches(regex.ABN_regex, "Please proper business enter name or ABN")
      .max(1, "Minimum of 1 characters")
      .max(25, "Maximum of 25 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        autoSelect={true}
        autoComplete="none"
        name="business_search"
        value=""
        noOptionsText="Type to start search"
        loadingText="Searching..."
        defaultValue=""
        clearOnBlur={true}
        blurOnSelect={true}
        onChange={async(event, newValue, reason) => {
          let isValid = true;

          isValid = newValue?.Name === '' || newValue?.entityName === "" || regex.ABN_regex.test(newValue?.Name || newValue?.entityName);

          if (!isValid) {
            await validateField('business_search', newValue?.Name || newValue?.entityName);
            return;
          } 

          if (reason === "select-option") {
            if (newValue) {
              setBusinessSearchValue({
                searchValueBusiness: newValue?.Name || newValue?.entityName,
              });
              return;
            }
            setBusinessSearchValue({
              searchValueBusiness: "",
              businessSearchInputText: "",
            });
          }

          if (reason === "blur") {
            setBusinessSearchValue({
              searchValueBusiness: "",
              businessSearchInputText: "",
            });
            updatedSearch(newValue);
          }
        }}
        filterOptions={(x) => x}
        onInputChange={async (event, newInputValue) => {
          let isValid = true;

          isValid = (newInputValue === "" || regex.ABN_regex.test(newInputValue) && newInputValue?.length <= 25);

          if (!isValid) {
            await validateField('business_search', newInputValue);
            return;
          }

          await validateField("business_search", newInputValue);
          try {
            if (fieldErrors["business_search"] === "") {
              setBusinessSearchValue({
                ...businessSearchValue,
                businessSearchInputText: newInputValue,
              });
              if (newInputValue === "") setEntityOptions([]);
            }
          } catch (error) {
            const newErrors = {};
            error.inner.forEach((validationError) => {
              newErrors[validationError.path] = validationError.message;
            });
            setFieldErrors(newErrors);
          }
        }}
        getOptionLabel={(option) => {
          return option.Name
            ? `${option.Name} ${readableABNString(option.Abn)}`
            : "";
        }}
        options={entityOptions}
        loading={loadingBusinessSearch}
        renderInput={(params) => (
          <TextField
            variant="filled"
            {...params}
            label="Search with business name or ABN"
            value=""
            placeholder="Search with ABN or business name"
            error={fieldErrors?.business_search}
            helperText={fieldErrors?.business_search}
            InputProps={{
              ...params.InputProps,
              autoComplete: "none",
              endAdornment: (
                <>
                  {loadingBusinessSearch ? <CircularProgress /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </React.Fragment>
  );
};

export default AutoSearchHOC;
