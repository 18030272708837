import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  saveValue,
  sendBankStatementsLink,
} from "src/store/slices/applicationFormSlice";
import Document from "./Document";
import BankStatementMetrics from "./BankStatementMetrics";
import CustomCardSending from "./CustomCardSending";

const CustomerFinancialStatement = ({ customer, applicationId }) => {
  const dispatch = useDispatch();
  const bankStatementsLoading = "";
  const [delay, setDelay] = useState(null);

  const [buttonColorText, setButtonColorText] = useState({
    buttonBackground: "primary",
    buttonText: "Send Bank Statements Link",
  });

  const handle = {
    handleSendBankStatementsLink: (customerId, applicationId, mobile) => {
      dispatch(saveValue({ bankStatementsLoading: true }));
      dispatch(
        sendBankStatementsLink({ customerId, applicationId, mobile })
      ).then((res) => {
        if (res) {
          handle.bankStatementsButtonSuccessStyle();
        }
      });
    },
    bankStatementsButtonSuccessStyle: () => {
      setButtonColorText({
        buttonBackground: "success",
        buttonText: "link sent",
      });

      setTimeout(() => {
        setButtonColorText({
          buttonBackground: "secondary",
          buttonText: "Send bank statements link",
        });
      }, 6000);
    },
  };

  return (
    <React.Fragment>
      <Typography fontWeight={600} style={{ marginBottom: "15px" }}>
        Financial statements
      </Typography>

      {customer?.bankStatements && (
        <>
          <Typography
            variant="subtitle2"
            fontWeight={600}
            style={{ marginBottom: "5px" }}
          >
            Bank accounts summary (current balance)
          </Typography>
          <Grid item container style={{ margin: "0 0 20px 0" }} spacing={2}>
            {customer?.bankStatements &&
              customer?.bankStatements?.bankAccounts?.map((bank, i) => {
                return (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{bank.bankName}</Typography>
                    {bank.bankAccounts.map((account) => {
                      return (
                        <Typography variant="body2">
                          <strong>{account.accountName}</strong>:{" "}
                          {account.currentBalance}
                        </Typography>
                      );
                    })}
                  </Grid>
                );
              })}
          </Grid>
        </>
      )}

      {customer?.bankStatements && (
        <BankStatementMetrics title={"Credits"} customer={customer} />
      )}

      {customer?.bankStatements && (
        <BankStatementMetrics
          title={"Responsible lending flags"}
          customer={customer}
        />
      )}

      {customer?.bankStatements && (
        <BankStatementMetrics title={"Liabilities"} customer={customer} />
      )}

      {customer?.bankStatements && (
        <BankStatementMetrics title={"Debits"} customer={customer} />
      )}

      <Stack
        spacing={1}
        direction="row"
        style={{ margin: "0 0 20px" }}
        // divider={<Divider orientation="vertical" flexItem />}
      >
        {customer?.documents?.length > 0 &&
          customer?.documents
            ?.filter((doc) => ["Bank statements"].includes(doc.name))
            .map((doc) => <Document document={doc} customer={customer} />)}
      </Stack>
      <Grid item sm={6}>
        <Card>
          <CardActionArea
            onClick={() => {
              console.log("phone:", customer);
              handle.handleSendBankStatementsLink(
                customer._id,
                applicationId,
                customer?.phoneNumbers.slice(-1)[0]
              );
              dispatch(saveValue({ linkSent: true }));
              setDelay(30000);
            }}
          >
            <CustomCardSending
              title={"Get bank statements"}
              description={`Send a self service link to ${customer.firstName} to provide
                access to their bank statements via illion Bank Statements.`}
              imagePath={"/static/illion-logo.png"}
              loadingState={bankStatementsLoading}
              financialState={true}
              icon={false}
              lastTitle={'Sending SMS link...'}
            />
          </CardActionArea>
        </Card>
      </Grid>
    </React.Fragment>
  );
};

export default CustomerFinancialStatement;
