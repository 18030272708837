import React, { useEffect, useState, useMemo } from "react";
import { nanoid } from "@reduxjs/toolkit";
import _, { debounce } from "lodash";
import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
  applicationSelector,
  assetSelector,
  loanDetailsSelector,
  userDetailsSelector,
  entitySelector,
  customersSelector,
  saveValue as saveApplicationValue,
} from "src/store/slices/applicationFormSlice";

import commercialProducts from "src/products/commercial";
import consumerProducts from "src/products/consumer";

import calculateRepayments from "src/utils/calculateRepayments";
import {
  evaluateCriteria,
  calculateLoading,
  calculateFees,
  calculateExtras,
} from "src/utils/evaluateCriteria";
import getMonthlyRate from "src/utils/getMonthlyRate";

import ProductSelectorFilterBy from "./ProductSelectorFilterBy";
import Product from "./Product";
import {
  consumerLenderList,
  commercialLenderList,
  productTypes,
} from "src/constants";
import ProductSelectorOrderBy from "./ProductSelectorOrderBy";

import calculateComparisonRate from "src/utils/calculateComparisonRate";
import { saveValue, userSelector } from "src/store/slices/userSlice";

const ProductSelector = () => {
  const dispatch = useDispatch();

  const [fitFilterValue, setFitFilterValue] = useState("3 criteria off");

  const { open } = useSelector(userDetailsSelector);
  const { applicationType } = useSelector(applicationSelector);
  const isCommercial = applicationType === "commercial";
  const asset = useSelector(assetSelector);
  const loanDetails = useSelector(loanDetailsSelector);
  const entity = useSelector(entitySelector);
  const customers = useSelector(customersSelector);
  const user = useSelector(userSelector);
  const criteriaFilterListDefault = isCommercial
    ? [
      "ageOfAsset",
      "ageOfAssetAtEnd",
      "assetType",
      "deposit",
      "gst",
      "livingArrangements",
      "loanAmount",
      "score",
      "timeInBusiness",
      "typeOfSale",
    ]
    : [
      "ageOfAsset",
      "ageOfAssetAtEnd",
      "assetType",
      "dateOfBirth",
      "deposit",
      "employmentType",
      "livingArrangements",
      "loanAmount",
      "score",
      "timeOfEmployment",
      "typeOfSale",
    ];

  const [criteriaFilterValue, setCriteriaFilterValue] = useState([]);
  const [criteriaFilterList, setCriteriaFilterList] = useState(
    criteriaFilterListDefault
  );

  const products = isCommercial ? commercialProducts : consumerProducts;
  const lenderList = isCommercial ? commercialLenderList : consumerLenderList;

  const {
    assetValue = 0,
    ageOfAsset = "",
    assetType = "",
    typeOfSale,
  } = useSelector(assetSelector);

  const {
    balloon = 0,
    term = 0,
    tradeIn = 0,
    payout = 0,
    deposit = 0,
    brokerage = 0,
  } = useSelector(loanDetailsSelector);

  const [productsMap, setProductsMap] = useState([]);
  const [orderByParameters, setOrderByParameters] = useState([]);
  const [orderByParametersList, setOrderByParametersList] = useState([
    ["fit", "rate"],
    ["asc", "asc"],
  ]);

  const [filterByParameters, setFilterByParameters] = useState(lenderList);

  const [filterByProductType, setFilterByProductType] = useState(productTypes);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", handle.setDimension);
    return () => {
      window.removeEventListener("resize", handle.setDimension);
    };
  }, [screenSize]);

  const handle = {
    setDimension: () => {
      getDimension({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
      });
    },
    loanAmountCommercial: (fees, netAssetValue, brokerageAmount) => {
      return netAssetValue + brokerageAmount + fees;
    },
    loanAmountConsumer: (fees, netAssetValue) => {
      return netAssetValue + fees;
    },
    evaluateProducts: () => {
      const customerProfile = customers?.[0]?.customerProfile;
      const creditProfile = customers?.[0]?.creditProfile;
      const employer = customers?.[0]?.employers?.[0];

      const productList = products
        ?.flatMap((product) => {
          return product?.productTiers?.map((tier) => {
            if (!tier) return null;

            const defaultBrokerage = calculateExtras(product.brokerage, {
              asset,
              loanDetails,
              entity: entity?.[0],
            })[0];

            const newLoanDetails = {
              loanAmount: assetValue - deposit,
              term,
              deposit,
              balloon,
            };

            const FEESLIST = calculateFees(product.fees, {
              asset,
              loanDetails: newLoanDetails,
              entity: entity?.[0],
              customer: customers?.[0],
              customerProfile,
              creditProfile,
              employer,
            });

            const feesList = FEESLIST.map((fee) => {
              if (fee.calc === "percent") {
                const feeValue = fee.value * 1;
                const newFeeValue =
                  (feeValue / 100) * newLoanDetails.loanAmount;
                return { ...fee, value: newFeeValue };
              }
              return fee;
            });

            const monthlyFees = feesList.find(
              (fee) => fee.frequency === "monthly"
            );
            const feesTotal = feesList.reduce((accum, fee) => {
              if (fee.percentage) {
                return (fee.value / 100) * newLoanDetails.loanAmount + accum;
              }
              if (fee.frequency === "monthly") return accum;
              if (fee.capitalised) return fee.value + accum;
              return accum;
            }, 0);


            const netAssetValue = assetValue - deposit - tradeIn + payout;
            const adjustedBrokerage = brokerage * 1 
            const finalBrokerage = adjustedBrokerage > defaultBrokerage?.max ? defaultBrokerage?.max : adjustedBrokerage
            const brokerageAmount = netAssetValue * (finalBrokerage / 100);


            const loanAmount = isCommercial
              ? handle.loanAmountCommercial(feesTotal, netAssetValue, brokerageAmount)
              : handle.loanAmountConsumer(feesTotal, netAssetValue);

            const loadingList = calculateLoading(product.loading, {
              asset,
              loanDetails: {
                ...loanDetails,
                ...{
                  loanAmount,
                  brokerageRate: adjustedBrokerage,
                  deposit: 100 - ((assetValue - deposit) / assetValue) * 100,
                },
              },
              entity: entity?.[0],
              customerProfile,
            });

            const loadingTotal = loadingList.reduce((accum, item) => {
              return item.result === true ? item.value + accum : accum;
            }, 0);

            let RATE;
            if (Array.isArray(tier.rate)) {
              const scoreCriteria = tier.criteria.find(
                (criteria) => criteria.attribute === "score"
              );
              if (
                scoreCriteria &&
                creditProfile?.score >= scoreCriteria.value.min &&
                creditProfile?.score < scoreCriteria.value.max
              ) {
                const percentage =
                  ((creditProfile.score - scoreCriteria.value.max) /
                    (scoreCriteria.value.max - scoreCriteria.value.min)) *
                  100 +
                  100;
                RATE =
                  (percentage / 100) * (tier.rate[1] - tier.rate[0]) +
                  tier.rate[0];
              } else {
                RATE = tier.rate[0];
              }
            } else {
              RATE = tier.rate;
            }

            const quoteDetails = handle.calculateQuote(
              loanAmount,
              balloon,
              term,
              RATE + loadingTotal
            );

            const comparisonRateRaw = calculateComparisonRate(
              term,
              -quoteDetails.repayments,
              loanAmount - feesTotal,
              balloon,
              0,
              RATE / 100
            );

            const comparisonRate = comparisonRateRaw * 12 * 100;

            const LOAN_DETAILS = {
              ...loanDetails,
              ...{
                loanAmount,
                repayments: quoteDetails.repayments,
                deposit: 100 - ((assetValue - deposit) / assetValue) * 100,
              },
            };

            const productDetails = {
              lender: product.lender,
              productName: tier.name,
              name: tier.name,
              logo: product.logoURL,
            };

            const criteriaResults = evaluateCriteria(tier.criteria, {
              asset,
              loanDetails: LOAN_DETAILS,
              entity: entity?.[0],
              customer: customers?.[0],
              customerProfile,
              creditProfile,
              employer,
            }).filter((result) => {
              if (!result.criteria) return result;
              if (result.criteria.every((criteria) => criteria.result))
                return result;
              if (
                !result.criteria.some((criteria) => criteria.result) &&
                result.criteria.some(
                  (item) => result.primary === item.attribute
                )
              ) {
                return result.criteria.find(
                  (item) => result.primary === item.attribute
                );
              }
              return result;
            });

            const overallResult = criteriaResults.reduce(
              (count, item) => (item?.result ? count + 1 : count),
              0
            );

            function tierInfo() {
              return tier?.info?.[0] ? tier?.info : [];
            }

            return _.merge(productDetails, {
              comparisonRate: isCommercial ? null : comparisonRate,
              financeAmount: 1 * loanAmount,
              loanAmount: quoteDetails.repayments * term,
              repayments: quoteDetails.repayments + (monthlyFees?.value || 0),
              brokerage: finalBrokerage,
              brokerageAmount: brokerageAmount,
              monthlyFees,
              fees: feesList,
              feesTotal,
              loading: { loadingList, loadingTotal },
              criteriaResults,
              fit: criteriaResults.length - overallResult,
              criteria: `${overallResult}/${criteriaResults.length}`,
              qualification: criteriaResults.length - overallResult,
              productType: tier.productType,
              labels: [tier.productType],
              rate: (RATE + loadingTotal) * 1,
              info: [...tierInfo(), ...(product?.info || [])],
              date: product.date,
              isCommercial,
            });
          });
        })
        .filter(Boolean);

      const list = productList
        .flat()
        .filter((product) => product.repayments > 0)
        .filter((product) => filterByParameters?.includes(product?.lender))
        .filter((product) => filterByProductType?.includes(product?.productType))
        .filter((product) => handle.filterByCriteriaFn(product, criteriaFilterValue));

      const groupedList = list.reduce((acc, product) => {
        acc[product.lender] = acc[product.lender] || [];
        acc[product.lender].push(product);
        return acc;
      }, {});

      const prioritisedList = Object.values(groupedList).map(
        (products) =>
          products.sort((a, b) => {
            if (a.fit !== b.fit) return a.fit - b.fit;
            return a.rate - b.rate;
          })[0]
      );

      setProductsMap(_.orderBy(prioritisedList, ...orderByParametersList));
    },
    filterByCriteriaFn: (product, criteriaFilterValue) => {
      const criteriaResults = product.criteriaResults.map(
        (result) => result.attribute
      );
      const matches = Array.isArray(criteriaFilterValue)
        ? criteriaFilterValue.map((criteria) =>
          criteriaResults.includes(criteria)
        )
        : [criteriaResults.includes(criteriaFilterValue)];

      return matches.every((i) => i);
    },
    calculateQuote: (loanAmount, balloon, term, rate) => {
      const monthlyRate = getMonthlyRate(rate);
      const repayments = calculateRepayments(
        monthlyRate,
        term,
        -loanAmount,
        (balloon / 100) * assetValue,
        isCommercial ? 1 : 0
      );

      return {
        repayments: Number(repayments.toFixed(2)),
      };
    },
    orderByFn: (event) => {
      const targetValue = event.target.value;
      const params = targetValue.map((i) => i[0]);
      const order = targetValue.map((i) => i[1]);

      if (user.status === "new" && params[0] === "rate") {
        setOrderByParameters([params[0]]);
        setOrderByParametersList([params, order]);
        return dispatch(saveValue({ walkthroughIndex: 3 }));
      }

      setOrderByParameters(targetValue);
      setOrderByParametersList([params, order]);
    },
    filterByFn: (event) => {
      setFilterByParameters(event.target.value);
    },
    filterByProductTypeFn: (event) => {
      setFilterByProductType(event.target.value);
    },
    filterByFitFn(event) {
      setFitFilterValue(event.target.value);
    },
    filterByCriteria: (event) => {
      if (user?.status === "new") {
        if (event.target.value === "assetType") {
          setCriteriaFilterValue([event.target.value]);
          if (user?.walkthroughIndex < 6) {
            dispatch(saveValue({ walkthroughIndex: 6 }));
          }
          return;
        }
      }
      setCriteriaFilterValue(event.target.value);
    },
  };

  useEffect(() => {
    handle.evaluateProducts();
  }, [
    brokerage,
    customers,
    assetType,
    ageOfAsset,
    balloon,
    term,
    entity,
    orderByParameters,
    filterByParameters,
    filterByProductType,
    fitFilterValue,
    typeOfSale,
    criteriaFilterValue,
    // assetValue,
    deposit,
    tradeIn,
    payout,
  ]);

  const debounced = debounce(handle.evaluateProducts, 500);

  useEffect(() => {
    debounced();
  }, [assetValue]);

  useEffect(() => {
    setOrderByParametersList([
      ["fit", "asc"],
      ["repayments", "asc"],
    ]);
  }, []);

  const productsList = useMemo(
    () =>
      productsMap
        ?.slice(0, 50)
        .map((productDetails, i) => (
          <Product
            key={nanoid()}
            productDetails={productDetails}
            screenSize={screenSize}
          />
        )),
    [productsMap]
  );

  return (
    <React.Fragment>
      <Stack
        direction="row"
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        {open && (
          <Stack direction="row">
            <ProductSelectorFilterBy
              list={lenderList}
              handleFilterBy={handle.filterByFn}
              value={filterByParameters}
              title="Lenders"
              multiple
              screenSize={screenSize}
            />
            <ProductSelectorFilterBy
              list={productTypes}
              handleFilterBy={handle.filterByProductTypeFn}
              value={filterByProductType}
              title="Product type"
              multiple
              screenSize={screenSize}
            />
            <ProductSelectorFilterBy
              list={criteriaFilterList}
              handleFilterBy={handle.filterByCriteria}
              value={criteriaFilterValue}
              title="Must match"
              multiple
              screenSize={screenSize}
            />
          </Stack>
        )}
        <ProductSelectorOrderBy
          handleOrderBy={handle.orderByFn}
          value={orderByParameters}
          screenSize={screenSize}
        />
      </Stack>

      <Stack>{productsList}</Stack>

      {!productsMap?.[0] && (
        <Stack
          justifyContent="center"
          alignItems="center"
          style={{ height: "200px" }}
        >
          <Typography>No matching products</Typography>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default ProductSelector;