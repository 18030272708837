import React from "react";

import {
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";

import UploadFileIcon from "@mui/icons-material/UploadFile";


const CustomCardSending = (props) => {
  const { title, description, imagePath, loadingState, financialState, icon, lastTitle } =
    props;
  return (
    <React.Fragment>
      <CardContent>
        <Stack
          direction="row"
          spacing={2}
          style={{
            alignItems: "center",
            height: "40px",
            marginBottom: "10px",
          }}
        >
          {imagePath !== "" && <img height="40px" src={imagePath} />}
          {financialState && (
            <Typography
              fontWeight={800}
              style={{
                letterSpacing: "-1px",
              }}
            >
             {financialState}
            </Typography>
          )}
            {icon &&  <UploadFileIcon fontSize="large" />}
          <Typography fontWeight={600}>{title}</Typography>
        </Stack>
        <Typography fontSize={13}>{description}</Typography>
      </CardContent>
      {loadingState && (
        <Stack
          spacing={2}
          style={{
            background: "rgba(0,0,0, 0.5)",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            color: "#fff",
          }}
        >
          <CircularProgress />
          <Typography>{lastTitle}</Typography>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default CustomCardSending;
