import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import moment from "moment";
import * as Yup from "yup";
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Skeleton,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuoteTwoTone";
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';

import {
  createEntity,
  entitySelector,
  updateEntity,
  saveValue,
  getASICData,
  userDetailsSelector,
  removeEntity
} from "../../store/slices/applicationFormSlice";

import { getBusinessByABN, getBusinessByName } from "../../services/abr";
import readableABNString from "../../utils/readableABNString";
import readableACNString from "../../utils/readableACNString";
import { isDigitsOnly } from "src/utils/isDigitsOnly";
import AutoSearchHOC from "../HOC/AutoSearchHOC";
import regex from "src/utils/regex";

const Entity = ({ application, entitys, index }) => {
  const dispatch = useDispatch();

  const { gettingASICData, gettingEntityDetails } =
    useSelector(userDetailsSelector);

  const entity = useSelector(entitySelector);

  const [businessSearchValue, setBusinessSearchValue] = useState({
    searchValueBusiness: "",
    businessSearchInputText: "",
  });

  const [fieldErrors, setFieldErrors] = useState({
    business_search: "",
    turnoverValue: "",
    industryType: ""
  })

  const [turnoverValue, setTurnoverValue] = useState({});
  const [industryTypeValue, setIndustryTypeValue] = useState({});
  const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
  const [entityOptions, setEntityOptions] = useState([]);

  const numOfColumns = 3;
  const numOfRows = 2;

  const columnLayouts = [
    [4, 12],
    [4, 12, 12],
  ];

  const validationSchema = Yup.object({
    turnoverValue: Yup.string()
      .optional()
      .matches(
        regex.allowOnlyNumber,
        "Only numbers and decimal points allowed."
      )
      .max(12, "Maximum of 12 characters"),
    industryType: Yup.string()
      .optional()
      .matches(
        regex.alphaNumericAndSpecificChar,
        "Only letters, numbers and and . / ( ) - characters allowed."
      )
      .max(50, "Maximum of 50 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };


  const handle = {
    entityFn: (update) => {
      dispatch(updateEntity(update));
    },
    entityDetailsFn: async (details) => {
      dispatch(saveValue({ gettingEntityDetails: true }));
      setFieldErrors({ business_search: "" });
      const abrData = await getBusinessByABN(details.Abn);

      if (abrData.EntityName !== entity?.entityName) {
        dispatch(createEntity({ abrData, applicationId: application._id }));
      } else {
        dispatch(saveValue({ gettingEntityDetails: false }));
      }
    },
    loadingGridFirst: (
      <Grid item container spacing={2} style={{ marginBottom: "15px" }}>
        {Array.from({ length: numOfColumns * numOfRows }).map((_, index) => (
          <Grid key={index} item sm={4}>
            <Skeleton variant="text" animation="wave" />
          </Grid>
        ))}
      </Grid>
    ),
    loadingGridSecond: (
      <Grid item container spacing={2}>
        {columnLayouts.map((columns, rowIndex) => (
          <Grid key={rowIndex} item container>
            {columns.map((sm, columnIndex) => (
              <Grid key={columnIndex} item sm={sm}>
                <Skeleton variant="text" />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    ),
    onChangeFieldTurnover: async (e, name) => {
      let isValid = true;

      isValid = regex.allowOnlyNumber.test(e) && e?.length <= 12;

      if (!isValid) {
        await validateField(name, e);
        return;
      } else {
        setTurnoverValue({ [entitys._id]: e })
        await validateField(name, e);
      }
    },
    onChangeFieldIndustryType: async (e, name) => {
      let isValid = true;

      isValid = regex.alphaNumericAndSpecificChar.test(e) && e?.length <= 50;

      if (!isValid) {
        await validateField(name, e);
        return;
      } else {
        setIndustryTypeValue({ [entitys._id]: e })
        await validateField(name, e);
      }
    },
  };

  const debounced = debounce(async (inputValue) => {
    if (inputValue) {
      setLoadingBusinessSearch(true);
      if (isDigitsOnly.test(inputValue)) {
        const businessByABN = await getBusinessByABN(inputValue);
        setLoadingBusinessSearch(false);
        if (businessByABN) {
          setEntityOptions([businessByABN]);
          return;
        }
      }

      const businesses = await getBusinessByName(inputValue);
      setLoadingBusinessSearch(false);
      if (businesses?.Names?.length > 0) {
        setEntityOptions(businesses.Names);
      } else {
        setEntityOptions([
          {
            Name: "No results found",
          },
        ]);
      }
    }
  }, 500);

  useEffect(() => {
    if (!gettingASICData) {
      dispatch(saveValue({ gettingASICData: null }));
    }
  }, [gettingASICData]);

  useEffect(() => {
    debounced(businessSearchValue?.businessSearchInputText);
    return () => debounced.cancel();
  }, [
    businessSearchValue?.searchValueBusiness,
    businessSearchValue?.businessSearchInputText,
  ]);

  useEffect(() => {
    if (!gettingEntityDetails) {
      setBusinessSearchValue({
        searchValueBusiness: "",
        businessSearchInputText: "",
      });
    }
  }, [gettingEntityDetails]);

  return (
    <Grid
      container
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={12} sm={12} md={2}>
        <BusinessTwoToneIcon style={{ marginRight: "10px" }} />
        <Typography>{(isNaN(index) || index < 1) ? "Add entity" : `Entity ${(index + 1)}`}</Typography>
      </Grid>

      <Grid
        conatiner
        xs={12}
        sm={12}
        md={10}
        style={{
          padding: "0 0 0 10px",
        }}
      >

        {entitys && <Grid key={entitys?.entityName} style={{ margin: "0 0 40px 0" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ margin: "0 0 20px" }}>
            <Typography >{entitys?.entityName}</Typography>
            <Button variant="outlined" size="small" onClick={() => dispatch(
              removeEntity({ entityId: entitys._id, applicationId: application._id })
            )}>Remove entity</Button>
          </Stack>
          <Grid item container style={{ margin: " 0 0 10px" }}>
            {entitys?.abn && (
              <Grid item lg={4} md={4} sm={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  ABN
                </Typography>
                <Typography variant="body2">
                  {readableABNString(entitys?.abn)}
                </Typography>
              </Grid>
            )}

            {entitys?.acn && (
              <Grid item lg={4} md={4} sm={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  ACN
                </Typography>
                <Typography variant="body2">
                  {readableACNString(entitys?.acn)}
                </Typography>
              </Grid>
            )}

            {entitys?.gst && (
              <Grid item lg={4} md={4} sm={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  GST
                </Typography>
                <Typography variant="body2">
                  {entitys?.gst
                    ? `${moment().diff(moment(entitys?.gst), "months")} months`
                    : "Not registered"}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container style={{ margin: "0 0 10px 0" }}>
            {entitys?.entityName && (
              <Grid item lg={4} md={4} sm={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  Entity name
                </Typography>
                <Typography variant="body2">{entitys?.entityName}</Typography>
              </Grid>
            )}

            {entitys?.entityType && (
              <Grid item lg={4} md={4} sm={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  Entity type
                </Typography>
                <Typography variant="body2">{entitys?.entityType}</Typography>
              </Grid>
            )}

            {entitys?.timeInBusiness && (
              <Grid item lg={4} md={4} sm={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  Time in business
                </Typography>
                <Typography variant="body2">
                  {`${moment().diff(
                    moment(entitys?.timeInBusiness),
                    "months"
                  )} months`}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container style={{ margin: "0 0 10px 0" }}>
            <Grid item xs={12}>
              {entitys?.businessNames?.length > 0 && (
                <>
                  <Typography variant="subtitle2" fontWeight={600}>
                    Business names
                  </Typography>
                  <Typography variant="body2">
                    {entitys?.businessNames.map((name, i) => (
                      <span>
                        {entitys?.businessNames.length - 1 === i
                          ? `${name}`
                          : `${name}, `}
                      </span>
                    ))}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container style={{ margin: "0 0 20px 0" }}>
            {entitys?.addresses?.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" fontWeight={600}>
                  Business addresses
                </Typography>
                {entitys?.addresses.map((address) => (
                  <Typography variant="body2" style={{ margin: "0 0 5px" }}>
                    {address.fullAddress}
                  </Typography>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid container item style={{ margin: "-10px 0 30px 0" }}>
            <Grid container item spacing={1}>
              <Grid item md={4} sm={5}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  name="turnoverValue"
                  label="Turnover (yearly)"
                  variant="filled"
                  size="small"
                  value={
                    turnoverValue?.[entitys?._id] || entitys?.turnover || ""
                  }
                  error={fieldErrors?.turnoverValue}
                  helperText={fieldErrors?.turnoverValue}
                  onChange={(event) =>
                    handle.onChangeFieldTurnover(event?.target?.value, "turnoverValue")
                  }
                  onBlur={(event) => {
                    handle.entityFn({
                      entityId: entitys?._id,
                      turnover: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item md={8} sm={7}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  type="text"
                  name="industryType"
                  label="Industry or business type"
                  variant="filled"
                  size="small"
                  value={
                    industryTypeValue?.[entitys?._id] ||
                    entitys?.industryType ||
                    ""
                  }
                  error={fieldErrors?.industryType}
                  helperText={fieldErrors?.industryType}
                  onChange={(event) =>
                    handle.onChangeFieldIndustryType(event?.target?.value, "industryType")
                  }
                  onBlur={(event) => {
                    handle.entityFn({
                      entityId: entitys?._id,
                      industryType: event.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={6}>
            <Card
              onClick={() => {
                dispatch(saveValue({ gettingASICData: true }));
                dispatch(
                  getASICData({
                    acn: entitys?.acn,
                    applicationId: application?._id,
                    entityId: entitys?._id,
                  })
                );
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      alignItems: "center",
                      height: "40px",

                      marginBottom: "10px",
                    }}
                  >
                    <img height="40px" src="/static/asic-logo.png" />
                    <Typography fontWeight={600}>Get ASIC extract</Typography>
                  </Stack>
                  <Typography fontSize={13}>
                    Retrieve full ASIC extract including directors and
                    beneficial owners for {entitys?.entityName}.
                  </Typography>
                </CardContent>
                {gettingASICData && (
                  <Stack
                    spacing={2}
                    style={{
                      background: "rgba(0,0,0, 0.5)",
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                      color: "#fff",
                    }}
                  >
                    <CircularProgress />
                    <Typography>Getting entity data...</Typography>
                  </Stack>
                )}
              </CardActionArea>
            </Card>
          </Grid>
        </Grid >
        }


        {
          gettingEntityDetails && (
            <Grid style={{ margin: "0 0 40px" }}>
              <Grid>
                <Typography style={{ margin: "0 0 10px" }}>
                  Getting entity details...
                </Typography>
              </Grid>
              <Grid>
                {handle.loadingGridFirst}
                {handle.loadingGridFirst}
                {handle.loadingGridSecond}
              </Grid>
            </Grid>
          )
        }

        <Grid container style={{ margin: "0 0 20px 0" }}>
          <Grid item lg={9} md={12} sm={12}>
            <AutoSearchHOC
              setBusinessSearchValue={setBusinessSearchValue}
              setEntityOptions={setEntityOptions}
              application={application?.entities?.length}
              loadingBusinessSearch={loadingBusinessSearch}
              entityOptions={entityOptions}
              updatedSearch={handle.entityDetailsFn}
              businessSearchValue={businessSearchValue}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
            />
          </Grid>
        </Grid>
      </Grid >
    </Grid >
  );
};

export default Entity;