import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import regex from "src/utils/regex";
import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  createFinanceItem,
  deleteFinanceItem,
  updateAssets,
} from "../../store/slices/applicationFormSlice";
import _ from "lodash";
import FinanceItem from "./FinanceItem";

export default function Assets({ assets }) {
  const dispatch = useDispatch();
  const [newAssetName, setNewAssetName] = useState("");

  const [fieldErrors, setFieldErrors] = useState({
    asset: "",
  });

  const validationSchema = Yup.object({
    asset: Yup.string()
      .optional()
      .matches(
        regex.fanciableItemNameRegex,
        "Only letters, dashes (-), forward slashes (/), commas (,), and spaces are allowed."
      )
      .max(25, "Maximum of 25 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    assets: (update) => {
      dispatch(updateAssets({ ...update, assetsId: assets._id }));
    },
    deleteAsset: async (del_data) => {
      await dispatch(
        deleteFinanceItem({
          data: { assetId: del_data._id, assetsId: assets._id },
          endpoint: "customer-assets",
        })
      ).unwrap();
    },
    createAsset: async () => {
      try {
        if (fieldErrors['asset'] === "") {
          await dispatch(
            createFinanceItem({
              data: {
                name: _.camelCase(newAssetName),
                label: newAssetName,
                assetsId: assets._id,
              },
              endpoint: "customer-assets",
            })
          ).unwrap();
          setNewAssetName("");
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
  };

  return (
    <Grid
      container
      item
      xs={3}
      spacing={0}
      style={{ alignContent: "flex-start" }}
    >
      <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>
        Assets (Total)
      </Typography>

      {/* <Grid item sm={12}> */}
      <Stack spacing={1}>
        {assets?.items?.map((asset) => (
          <FinanceItem
            item={asset}
            handleUpdate={() => handle.assets()}
            handleDelete={() => handle.deleteAsset(asset)}
          />
        ))}

        <TextField
          id="outlined-basic"
          type="text"
          size="small"
          name="asset"
          label="Add asset"
          variant="outlined"
          onChange={async (event) => {
            let isValid = true;
            isValid = regex.fanciableItemNameRegex.test(event.target.value) && event.target.value?.length <= 25;
            if (!isValid) {
              await validateField('asset', event.target.value);
              return;
            } else {
              setNewAssetName(event.target.value)
              await validateField("asset", event.target.value);
            }
          }}
          value={newAssetName}
          error={fieldErrors?.asset}
          helperText={fieldErrors?.asset}
          placeholder="Asset name"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(event) => {
                    if (newAssetName) {
                      handle.createAsset(event);
                    }
                  }}
                  style={{
                    background: newAssetName ? "#616161" : "none",
                    color: newAssetName ? "#fff" : "rgba(0, 0, 0, 0.6)",
                    height: "30px",
                  }}
                  edge="end"
                >
                  +
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Grid>
  );
}
