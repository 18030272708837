import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import styled from "styled-components";
import {
  getDocuments,
  saveValue,
  sendPrivacyLink,
  uploadDocument,
} from "src/store/slices/applicationFormSlice";
import Document from "./Document";
import CustomCardSending from "./CustomCardSending";

const CustomerPrivacyAgreement = ({ customer, applicationId }) => {
  const dispatch = useDispatch();

  const fileInput = useRef();

  const Input = styled("input")({
    display: "none",
  });

  const [uploadPrivacyButtonBackground, setUploadPrivacyButtonBackground] =
    useState("secondary");
  const [uploadPrivacyButtonText, setUploadPrivacyButtonText] = useState(
    "Upload Privacy Agreement"
  );

  const [privacyButtonBackground, setPrivacyButtonBackground] =
    useState("secondary");
  const [privacyButtonText, setPrivacyButtonText] =
    useState("Send privacy link");

  const uploadPrivacyLoading = "";
  const privacyLoading = "";

  const [buttonPrivacyColorText, setButtonPrivacyColorText] = useState({
    buttonPrivacyBackground: "secondary",
    buttonPrivacyText: "Send privacy link",
  });

  const [buttonUploadPrivacyColorText, setButtonUploadPrivacyColorText] =
    useState({
      buttonUploadPrivacyBackground: "secondary",
      buttonUploadPrivacyText: "Upload Privacy Agreement",
    });

  const [delay, setDelay] = useState(null);

  const handle = {
    uploadPrivacy: (event, customerId) => {
      dispatch(saveValue({ uploadPrivacyLoading: true }));
      const formData = new FormData();
      // Update the formData object
      formData.append("file", event.target.files[0]);
      formData.append("applicationId", applicationId);
      formData.append("customerId", customerId);
      formData.append("name", "Privacy agreement");

      // Details of the uploaded file

      dispatch(uploadDocument(formData)).then((res) => {
        if (res) handle.uploadPrivacyButtonSuccessStyle();
      });
      event.target.input = null;
      // Request made to the backend api
      // Send formData object
    },
    uploadPrivacyButtonSuccessStyle: () => {
      setButtonUploadPrivacyColorText({
        buttonUploadPrivacyBackground: "success",
        buttonUploadPrivacyText: "Successfully uploaded",
      });

      setTimeout(() => {
        setButtonUploadPrivacyColorText({
          buttonUploadPrivacyBackground: "secondary",
          buttonUploadPrivacyText: "Upload Privacy Agreement",
        });
        dispatch(
          getDocuments(
            customer._id
            // application: applicationId,
          )
        );
      }, 6000);
    },
    handleSendPrivacyLink(customerId, applicationId, mobile) {
      setDelay(30000);
      dispatch(saveValue({ privacyLoading: true }));
      dispatch(sendPrivacyLink({ customerId, applicationId, mobile })).then(
        (res) => {
          if (res) handle.privacyButtonSuccessStyle();
        }
      );
    },
    privacyButtonSuccessStyle: () => {
      setButtonPrivacyColorText({
        buttonPrivacyBackground: "success",
        buttonPrivacyText: "link sent",
      });
      setTimeout(() => {
        setButtonPrivacyColorText({
          buttonPrivacyBackground: "secondary",
          buttonPrivacyText: "Send privacy link",
        });
      }, 6000);
    },
  };

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Typography
          id="input-slider"
          fontWeight={600}
          style={{ marginBottom: "10px" }}
        >
          Privacy Agreement
        </Typography>
        <Stack
          spacing={1}
          direction="row"
          style={{ margin: "0 0 20px" }}
          // divider={<Divider orientation="vertical" flexItem />}
        >
          {customer?.documents?.length > 0 &&
            customer?.documents
              ?.filter((doc) => doc.name === "Privacy agreement")
              .map((doc) => <Document document={doc} customer={customer} />)}
        </Stack>

        <Grid item spacing={2}>
          <Stack
            spacing={2}
            direction="row"
            // divider={<Divider orientation="vertical" flexItem />}
          >
            <Grid item xs={6}>
              <Card
                onClick={() => {
                  handle.handleSendPrivacyLink(
                    customer._id,
                    applicationId,
                    customer?.phoneNumbers.slice(-1)[0]
                  );
                  dispatch(saveValue({ linkSent: true }));
                  setDelay(30000);
                }}
              >
                <CardActionArea>
                  <CustomCardSending
                    title={"Get signed Privacy"}
                    description={`Send a self service link to ${customer.firstName} to digitally
              sign the privacy and consent statement.`}
                    imagePath={""}
                    loadingState={privacyLoading}
                    financialState={true}
                    icon={false}
                    lastTitle={"Sending SMS link..."}
                  />
                </CardActionArea>
              </Card>
              {!customer?.phoneNumbers.slice(-1)[0] && (
                <Typography variant="caption">
                  Sending a privacy link requires a customer mobile number
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <Card>
                <Input
                  accept="*"
                  ref={fileInput}
                  id={`upload-id-${customer._id}`}
                  multiple
                  type="file"
                  value={null}
                  onChange={(event) =>
                    handle.uploadPrivacy(event, customer._id)
                  }
                />
                <CardActionArea onClick={() => fileInput.current.click()}>
                  <CustomCardSending
                    title={"Upload sign privacy"}
                    description={`  Upload a signed privacy statement from files stored locally on
                your computer.`}
                    imagePath={""}
                    loadingState={uploadPrivacyLoading}
                    financialState={true}
                    icon={true}
                    lastTitle={"Upload privacy..."}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CustomerPrivacyAgreement;
