import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "@reduxjs/toolkit";
import { merge } from "lodash";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  CardActions,
  Button,
  Stack,
  Grid,
  Chip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  applicationSelector,
  userDetailsSelector,
  getApplicationDownloadUrl,
  submitApplication,
} from "src/store/slices/applicationFormSlice";
import { userSelector, saveValue } from "src/store/slices/userSlice";

const headerBackgroundColors = {
  0: "#e0f2f1",
  1: "#f1f8e9",
  2: "#fffde7",
  3: "#fff3e0",
};

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

const chipColor = {
  ["Standard"]: "#4527a0",
  ["Low doc"]: "#ba68c8",
  ["Light doc"]: "#64b5f6",
  ["Full doc"]: "#607d8b",
  ["Adverse"]: "#3f51b5",
};

export default function Product({ productDetails, screenSize }) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [expanded, setExpanded] = useState(false);
  const { open, url } = useSelector(userDetailsSelector);
  const application = useSelector(applicationSelector);

  const fit = productDetails?.fit;
  const qualification = productDetails?.qualification;

  const handle = {
    expandClickFn: () => {
      setExpanded(!expanded);
      if (user.status === "new" && !expanded) {
        dispatch(saveValue({ walkthroughIndex: 4 }));
      }
    },
    criteriaColorFn: (result) => {
      if (result) return "#2e7d32";
      if (result === undefined) return "#999999";
      return "#c62828";
    },
    extrasStyleFn: (result) => {
      if (result === false)
        return { color: "inherit", textDecoration: "line-through" };
      if (result === undefined) return { color: "#999999" };
      return { color: "inherit" };
    },
    setHeaderBackground: () => {
      return headerBackgroundColors[qualification] || "#fbe9e7";
    },
    criteriaIconsFn: (criteria) => {
      if (criteria.result === true)
        return (
          <CheckCircleIcon
            style={{
              fontSize: "14px",
              color: handle.criteriaColorFn(criteria.result),
            }}
          />
        );
      if (criteria.result === false)
        return (
          <CancelIcon
            style={{
              fontSize: "14px",
              color: handle.criteriaColorFn(criteria.result),
            }}
          />
        );
      return (
        <RemoveCircleIcon
          style={{
            fontSize: "14px",
            color: handle.criteriaColorFn(criteria.result),
          }}
        />
      );
    },
    saveQuote: async () => {
      // if (loanDetails._id)
      //   console.log({
      //     loanAmount: loanAmountValue,
      //     rate: rateValue,
      //     repayments: repaymentsValue,
      //     brokerage: brokerageValue,
      //     fees: feesValue,
      //   });
      // await dispatch(
      //   updateLoanDetails(
      //     merge({
      //       ...loanDetails,
      //       ...{
      //         loanAmount: loanAmountValue,
      //         rate: rateValue,
      //         repayments: repaymentsValue,
      //         brokerage: brokerageValue,
      //         fees: handleFees(),
      //       },
      //     })
      //   )
      // ).unwrap();
    },
  };

  return (
    <React.Fragment>
      {open ? (
        <Card
          className="tenth-step"
          elevation={1}
          style={{
            margin: "0 0 12px",
            background: handle.setHeaderBackground(),
            borderRadius: "8px",
          }}
        >
          <CardContent
            style={{
              padding: "0 20px",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              style={{ padding: "7px 5px 5px 0" }}
            >
              <Typography
                variant="body2"
                style={{
                  marginRight: "20px",

                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                <span>Criteria: {productDetails?.criteria}</span>
              </Typography>

              <Stack direction="row" spacing={1}>
                {productDetails?.labels.map((label) => (
                  <Chip
                    key={nanoid()}
                    size="small"
                    label={label}
                    style={{
                      padding: "0 2px",
                      height: "22px",
                      color: "#fff",
                      background: chipColor[label],
                    }}
                  />
                ))}
              </Stack>
            </Stack>

            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  xs={screenSize.dynamicWidth < 900 ? 5 : 8}
                  alignItems="center"
                >
                  <Stack
                    direction={screenSize.dynamicWidth < 900 ? "column" : "row"}
                    justifyContent="center"
                    alignItems={
                      screenSize.dynamicWidth < 900 ? "start" : "center"
                    }
                  >
                    <Grid
                      item
                      xs={screenSize.dynamicWidth < 900 ? 6 : 4}
                      style={{}}
                      alignItems="center"
                    >
                      <img
                        src={productDetails?.logo}
                        style={{
                          width: screenSize.dynamicWidth < 900 ? "100%" : "60%",
                          height: "auto",
                          margin: "auto",
                          display: "flex",
                        }}
                      />
                    </Grid>
                    <Grid item xs={screenSize.dynamicWidth < 900 ? 12 : 8}>
                      <Stack direction="column">
                        {screenSize.dynamicWidth > 900 && (
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="subtitle"
                              style={{ fontSize: "14px", fontWeight: "400" }}
                            >
                              {productDetails?.productName}
                            </Typography>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="subtitle"
                              style={{
                                marginRight: "5px",
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              {productDetails?.lender}
                            </Typography>
                          </>
                        )}
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="h5"
                          style={{
                            marginRight: "5px",
                            marginBottom: "-5px",
                            fontWeight: "700",
                          }}
                        >
                          {productDetails?.repayments?.toLocaleString(
                            "en-US",
                            dollarStringOptions
                          )}
                          /mth
                        </Typography>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="caption"
                          style={{ marginRight: "5px", fontSize: "11px" }}
                        >
                          {productDetails?.monthlyFees &&
                            `incl. ${productDetails?.monthlyFees?.value?.toLocaleString(
                              "en-US",
                              dollarStringOptions
                            )} monthly fees`}
                        </Typography>
                        {screenSize.dynamicWidth > 900 && (
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body1"
                            style={{ marginRight: "5px" }}
                          >
                            {(
                              productDetails?.repayments / 4.33
                            )?.toLocaleString("en-US", dollarStringOptions)}
                            /wk
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={screenSize.dynamicWidth < 900 ? 7 : 4}
                  alignItems="center"
                >
                  <Typography
                    sx={{ display: "inline" }}
                    variant="body2"
                    style={{
                      marginRight: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Lender rate:{" "}
                    <span>{(1 * productDetails?.rate).toFixed(2)}%</span>
                  </Typography>
                  {productDetails?.comparisonRate && (
                    <Typography
                      sx={{ display: "inline" }}
                      variant="body2"
                      style={{
                        marginRight: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Comparison rate:{" "}
                      <span>
                        {(1 * productDetails?.comparisonRate).toFixed(2)}%
                      </span>
                    </Typography>
                  )}

                  <Typography
                    sx={{ display: "inline" }}
                    // component="span"
                    variant="body2"
                    style={{
                      marginRight: "5px",

                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Finance amount:
                    <span>
                      {productDetails?.financeAmount?.toLocaleString(
                        "en-US",
                        dollarStringOptions
                      )}
                    </span>
                  </Typography>
                  <Typography
                    sx={{ display: "inline" }}
                    variant="body2"
                    style={{
                      marginRight: "5px",

                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Brokerage
                    <span>
                      {productDetails?.brokerage?.toFixed(2)}% / {productDetails?.brokerageAmount?.toLocaleString(
                        "en-US",
                        dollarStringOptions
                      )}
                    </span>
                  </Typography>
                  <Typography
                    sx={{ display: "inline" }}
                    variant="body2"
                    color={productDetails?.score ? "#222222" : "aaaaaa"}
                    style={{
                      marginRight: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack
                      direction="column"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <Stack direction="row" justifyContent="space-between">
                        <span>Total upfront fees:</span>
                        <span>
                          {productDetails?.feesTotal?.toLocaleString(
                            "en-US",
                            dollarStringOptions
                          )}
                        </span>
                      </Stack>
                    </Stack>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <CardActions
            disableSpacing
            style={{
              justifyContent: "space-between",
              padding: "10px 20px",
            }}
          >
            <Button
              className="fifth-step"
              size="small"
              expand={expanded}
              onClick={handle.expandClickFn}
              aria-expanded={expanded}
              aria-label="show more"
              color="secondary"
              style={{
                padding: "0 10px",
                minWidth: "auto",
                fontWeight: "bold",
              }}
            >
              {expanded ? (
                <React.Fragment>
                  Hide <ExpandLessIcon />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Show criteria <ExpandMoreIcon />
                </React.Fragment>
              )}
            </Button>

            <Stack spacing={1} direction="row" justifyContent="center">
              <LoadingButton
                color="secondary"
                endIcon={<FileCopyOutlinedIcon />}
                loadingPosition="end"
                variant="outlined"
                size="small"
                style={{ padding: "0 10px", minWidth: "auto", fontWeight: 600 }}
                disabled={!application?.customers?.[0]}
                onClick={async () => {
                  if (!url) {
                    handle.saveQuote();
                    await dispatch(
                      getApplicationDownloadUrl(application._id)
                    ).unwrap();
                  }
                }}
                href={url}
              >
                Download
              </LoadingButton>

              <LoadingButton
                color="secondary"
                endIcon={<SendOutlinedIcon />}
                loadingPosition="end"
                variant="outlined"
                size="small"
                disabled={!application?.customers?.[0]}
                style={{ padding: "0 10px", minWidth: "auto", fontWeight: 600 }}
                onClick={async () => {
                  handle.saveQuote();
                  await dispatch(submitApplication(application._id)).unwrap();
                }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent style={{ padding: "5px 20px 10px" }}>
              <Grid item container spacing={3}>
                <Grid
                  item
                  className="sixth-step"
                  xs={screenSize.dynamicWidth < 900 ? 7 : 4}
                >
                  <Typography variant="subtitle">
                    <strong>Criteria</strong>
                  </Typography>
                  {productDetails?.criteriaResults
                    ?.sort((x, y) => {
                      if (x.result && x.result !== y.result) return -1;
                      if (x.result === undefined && y.result) return 1;
                      if (y.result === undefined && !x.result) return 1;
                      if (x.result === y.result) return 0;
                      if (!y.result) return -1;
                    }) // sort from true to false
                    ?.map((criteria) => (
                      <Stack direction="row" alignItems="center">
                        {handle.criteriaIconsFn(criteria)}
                        <Typography
                          variant="body2"
                          style={{ marginLeft: "5px" }}
                        >
                          {criteria.label}
                        </Typography>
                      </Stack>
                    ))}
                </Grid>
                {(productDetails?.loading?.loadingList?.[0] ||
                  productDetails?.fees?.[0]) && (
                    <Grid item xs={screenSize.dynamicWidth < 900 ? 5 : 4}>
                      <Typography variant="subtitle">
                        <strong>Extras</strong>
                      </Typography>

                      <React.Fragment>
                        {productDetails?.loading?.loadingList
                          ?.sort((x, y) => {
                            if (x.result && x.result !== y.result) return -1;
                            if (x.result === undefined && y.result) return 1;
                            if (y.result === undefined && !x.result) return 1;
                            if (x.result === y.result) return 0;
                            if (!y.result) return -1;
                          })
                          ?.map((load) => (
                            <Stack
                              // key={fee?.name}
                              direction="row"
                              justifyContent="space-between"
                              style={handle.extrasStyleFn(load.result)}
                            >
                              <Typography
                                // key={load?.name}
                                variant="body2"
                              // color={handle.criteriaColorFn(load.result)}
                              >
                                {load?.name}:
                              </Typography>
                              <Typography
                                // key={load?.name}
                                variant="body2"
                              // color={handle.criteriaColorFn(load.result)}
                              >
                                {load?.value}%
                              </Typography>
                            </Stack>
                          ))}
                        {productDetails?.fees?.map((fee) => (
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">{fee?.name}:</Typography>
                            <Typography variant="body2">
                              {fee?.value?.toLocaleString(
                                "en-US",
                                dollarStringOptions
                              )}
                            </Typography>
                          </Stack>
                        ))}
                      </React.Fragment>
                    </Grid>
                  )}

                <Grid
                  item
                  xs={
                    productDetails?.loading?.loadingList?.[0] ||
                      productDetails?.fees?.[0]
                      ? screenSize.dynamicWidth < 900
                        ? 12
                        : 4
                      : screenSize.dynamicWidth < 900
                        ? 12
                        : 7
                  }
                >
                  {productDetails?.info?.[0] && (
                    <>
                      <Typography variant="subtitle">
                        <strong>Info & Requirements</strong>
                      </Typography>
                      <ul
                        style={{
                          fontSize: "12.5px",
                          listStyle: "inside",
                          textAlign: "justify",
                        }}
                      >
                        {productDetails?.info?.map((info) => (
                          <li>{info}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </Grid>
              </Grid>
              <Box
                style={{
                  margin: "10px 0 0",
                  textAlign: "left",
                }}
              >
                <Typography variant="caption">
                  Lender rates as of: {productDetails?.date}
                </Typography>
              </Box>
            </CardContent>
          </Collapse>
        </Card>
      ) : (
        <Card
          alignItems="flex-start"
          style={{
            margin: "0 0 10px 0",
            background: handle.setHeaderBackground(),
          }}
        >
          <CardContent style={{ padding: "5px 10px" }}>
            <Stack
              direction="row"
              spacing={2}
              style={{
                alignItems: "center",
                height: "60px",
              }}
            >
              <img width="60px" alt="Remy Sharp" src={productDetails.logo} />
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                  }}
                >
                  {productDetails.criteria} criteria
                </Typography>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  style={{ marginRight: "5px", fontSize: "14px" }}
                >
                  <span>${productDetails?.repayments?.toFixed(2)}/mth</span>
                </Typography>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  style={{ marginRight: "5px", fontSize: "14px" }}
                >
                  <span>{productDetails?.rate.toFixed(2)}%</span>
                </Typography>
                {/* <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  style={{ marginRight: "5px", fontSize: "14px" }}
                >
                  <span>
                    {productDetails?.brokerage?.toFixed(2)}% / {productDetails?.brokerageAmount?.toLocaleString(
                      "en-US",
                      dollarStringOptions
                    )}
                  </span>
                </Typography> */}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
}
