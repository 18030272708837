import { useState, useEffect, useMemo } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { Grid, Typography, TextField, Paper } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch, useSelector } from "react-redux";
import {
  saveValue,
  userSelector,
  updateUser,
  updatePhoneNumber,
  changePassword,
  getUser,
} from "src/store/slices/userSlice";

import regex from "src/utils/regex";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function UserProfile() {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { changePasswordSuccess, isChangingPassword, changePasswordFail } =
    useSelector(userSelector);

  const [changePasswordButtonText, setChangePasswordButtonText] =
    useState("Change password");
  const [changePasswordButtonColor, setChangePasswordButtonColor] =
    useState("secondary");
  const [canSubmit, setCanSubmit] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});

  const [allPassValues, setAllPassValues] = useState({
    newPassword: "",
    currentPassword: "",
  });

  const [newPasswordError, setNewPasswordError] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);

  const [allValues, setAllValues] = useState({
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    phoneNumber: user.phoneNumber || "",
  });

  const validationRules = {
    firstName: {
      validate: (value) => value.trim() !== "",
      errorMessage: "First name is required",
    },
    lastName: {
      validate: (value) => value.trim() !== "",
      errorMessage: "last name is required",
    },
    email: {
      validate: (value) => {
        return regex.email.test(value);
      },
      errorMessage: "Invalid email address",
    },
    phoneNumber: {
      validate: (value) => {
        return regex.phoneNumber.test(value);
      },
      errorMessage: "Invalid phone number",
    },
  };

  const handle = {
    onChangeField: (e, name) => {
      setAllValues({ ...allValues, [name]: e });
    },
    onChangePassFn: (value, name) => {
      if (name === "newPassword") {
        if (regex.password.test(value)) setNewPasswordError(false);
        else setNewPasswordError(true);
      } else if (name === "currentPassword")
        if (regex.password.test(value)) setCurrentPasswordError(false);
        else setCurrentPasswordError(true);

      setAllPassValues({
        ...allPassValues,
        [name]: value,
      });
    },
    blurFn: async (fieldName, value) => {
      const { validate } = validationRules[fieldName];
      const isValid = validate(value);
      if (isValid) {
        if (fieldName === "phoneNumber") {
          await dispatch(
            updatePhoneNumber({
              [fieldName]: value,
            })
          ).unwrap();
        } else {
          await dispatch(
            updateUser({
              [fieldName]: value,
            })
          ).unwrap();
        }
      }
      setAllValues((prevValues) => ({
        ...prevValues,
        [fieldName]: value,
      }));
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: isValid ? "" : validationRules[fieldName].errorMessage,
      }));
    },
    changePasswordFn: async () => {
      dispatch(saveValue({ isChangingPassword: true }));
      await dispatch(
        changePassword({
          newPassword: allPassValues?.newPassword,
          currentPassword: allPassValues?.currentPassword,
        })
      ).unwrap();
    },
  };

  useEffect(() => {
    if (
      allPassValues?.currentPassword &&
      allPassValues?.newPassword &&
      !currentPasswordError &&
      !newPasswordError
    )
      return setCanSubmit(true);

    setCanSubmit(false);
  }, [
    allPassValues?.currentPassword,
    currentPasswordError,
    allPassValues?.newPassword,
    newPasswordError,
  ]);

   useEffect(() => {
    if (changePasswordSuccess) {
      setAllPassValues({
        newPassword: "",
        currentPassword: "",
      });
      setChangePasswordButtonText("Password changed succesfully");
      setChangePasswordButtonColor("success");
      setTimeout(() => {
        setChangePasswordButtonText("Change password");
        setChangePasswordButtonColor("secondary");
        dispatch(saveValue({ changePasswordSuccess: false }));
      }, 4000);
    }
  }, [changePasswordSuccess]);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
        style={{ marginTop: "64px" }}
      >
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={10}
          xl={8}
          height="100vh"
          alignItems="center"
          direction="column"
          style={{ padding: "0 100px", marginTop: "64px" }}
        >
          <Paper style={{ padding: "40px" }}>
            <Typography
              variant="h5"
              fontWeight="800"
              style={{ letterSpacing: "-1.2px" }}
            >
              User profile
            </Typography>
            <br />
            <Typography style={{ textAlign: "left", margin: "20px 0 10px" }}>
              Organisation
            </Typography>
            <Typography
              variant="body2"
              style={{ textAlign: "left", margin: "0 0 40px" }}
            >
              {user?.organisation?.name}
            </Typography>
            <Typography style={{ textAlign: "left", marginBottom: "10px" }}>
              Personal details
            </Typography>
            <TextField
              fullWidth
              type="text"
              name="firstName"
              label="First name"
              size="small"
              value={allValues?.firstName}
              inputProps={{ maxLength: 24 }}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "firstName")
              }
              margin="dense"
              error={fieldErrors?.firstName}
              helperText={fieldErrors?.firstName}
              onBlur={(e) => handle.blurFn("firstName", e.target.value)}
            />

            <TextField
              fullWidth
              type="text"
              name="lastName"
              label="Last name"
              size="small"
              value={allValues?.lastName}
              inputProps={{ maxLength: 24 }}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "lastName")
              }
              margin="dense"
              error={fieldErrors?.lastName}
              helperText={fieldErrors?.lastName}
              onBlur={(e) => handle.blurFn("lastName", e.target.value)}
            />

            <TextField
              fullWidth
              type="email"
              name="email"
              label="Email"
              size="small"
              disabled
              value={allValues?.email}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "email")
              }
              margin="dense"
              error={fieldErrors?.email}
              helperText={fieldErrors?.email}
              onBlur={(e) => handle.blurFn("email", e.target.value)}
              inputProps={{ maxLength: 50 }}
            />

            <TextField
              fullWidth
              type="tel"
              name="phoneNumber"
              label="Phone number"
              autoComplete="tel"
              size="small"
              value={allValues?.phoneNumber}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "phoneNumber")
              }
              margin="dense"
              error={fieldErrors?.phoneNumber}
              helperText={fieldErrors?.phoneNumber}
              onBlur={(e) => handle.blurFn("phoneNumber", e.target.value)}
              inputProps={{ maxLength: 15 }}
            />

            <Typography style={{ textAlign: "left", margin: "20px 0 10px" }}>
              Change password
            </Typography>

            <TextField
              fullWidth
              type="password"
              name="currentPassword"
              label="Current password"
              autocomplete="current-password"
              size="small"
              value={allPassValues?.currentPassword}
              margin="dense"
              inputProps={{ maxLength: 32 }}
              onChange={(event) =>
                handle.onChangePassFn(event.target.value, "currentPassword")
              }
              error={currentPasswordError}
              helperText={
                currentPasswordError
                  ? "password must contain an uppercase letter, a number, a special character and be 8 or more characters in length."
                  : null
              }
            />

            <TextField
              fullWidth
              type="password"
              name="newPassword"
              label="New password"
              autocomplete="new-password"
              size="small"
              value={allPassValues?.newPassword}
              onChange={(event) =>
                handle.onChangePassFn(event.target.value, "newPassword")
              }
              error={newPasswordError}
              margin="dense"
              helperText="Must conatain an uppercase letter, a number, a special character and be 8 or more characters in length."
              inputProps={{ maxLength: 32 }}
            />

            <LoadingButton
              loading={isChangingPassword}
              loadingPosition="end"
              variant="contained"
              disabled={!canSubmit && !changePasswordSuccess}
              color={changePasswordButtonColor}
              style={{ margin: "20px 0 40px", justifySelf: "start" }}
              onClick={() => handle.changePasswordFn()}
            >
              {changePasswordButtonText}
            </LoadingButton>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
