import { Box, Grid, Slider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  saveCustomer,
  saveCustomerProfile,
  updateCustomer,
} from "src/store/slices/applicationFormSlice";
import RadioButtonGroup from "../Utils/RadioButtonGroup";

const CustomerPersonalProfile = React.memo(({ customer }) => {
  const dispatch = useDispatch();

  const [allValues, setAllValue] = useState({
    livingArrangementsValue: customer?.customerProfile?.livingArrangements,
    dependentsValue: customer?.customerProfile?.numberOfDependants
  });

  const handle = {
    onChangeRadio: (value, name) => {
      setAllValue({ ...allValues, [name]: value });
    },
    handleLivingArrangements: (value) => {
      dispatch(
        saveCustomerProfile({
          livingArrangements: value,
          customer: customer._id,
        })
      );
    },
    numberOfDependant : (event, value) => {
        dispatch(
          saveCustomerProfile({
            numberOfDependants: value,
            customer: customer._id,
          })
        );
      }
  };

  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Grid item md={12} sm={12} style={{ margin: "0 0 10px" }}>
          <Typography fontWeight={600}>Personal Profile</Typography>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item sm={6}>
            <Typography variant="subtitle2">
              Property/Living arrangements
            </Typography>
            <RadioButtonGroup
              value={allValues?.livingArrangementsValue}
              name={"livingArrangementsValue"}
              handleValue={(value) => {
                handle.onChangeRadio(value, "livingArrangementsValue");
                handle.handleLivingArrangements(value);
              }}
              options={[
                { icon: "House", label: "Property owner" },
                { icon: "HomeWork", label: "Renting" },
                { icon: "Hotel", label: "Boarding" },
              ]}
            />
          </Grid>

          <Grid item sm={6} style={{ margin: "0 0 10px" }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              // style={{ marginBottom: "40px" }}
            >
              Number of dependents
            </Typography>
            <Box style={{ padding: "0 7px" }}>
              <Slider
                required
                color="secondary"
                size="large"
                marks={[
                  { value: 0, label: 0 },
                  { value: 1, label: 1 },
                  { value: 2, label: 2 },
                  { value: 3, label: 3 },
                  { value: 4, label: 4 },
                  { value: 5, label: 5 },
                  { value: 6, label: 6 },
                  { value: 7, label: 7 },
                  { value: 8, label: 8 },
                  { value: 9, label: 9 },
                  { value: 10, label: "10+" },
                ]}
                step={1}
                min={0}
                max={10}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(event) => setAllValue({...allValues, dependentsValue:event.target.value })}
                defaultValue={allValues?.dependentsValue}
                onChangeCommitted={handle.numberOfDependant}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default CustomerPersonalProfile;
