import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as Yup from "yup";

import {
  Autocomplete,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";

import {
  assetSelector,
  loanDetailsSelector,
  saveAsset,
  updateAsset,
} from "../../store/slices/applicationFormSlice";

import { makeType, typeOfSaleList } from "src/constants";
import { assetTypes } from "src/assetTypes.json";
import consumerAssetTypes from "src/utils/consumerAssetTypes";
import generateArrayOfYears from "src/utils/generateArrayOfYears";
import regex from "src/utils/regex";
import { eventNames } from "process";
import checkAlphabetRestrict from "src/utils/checkAlphabetRestrict";

const Asset = ({ applicationType }) => {
  const dispatch = useDispatch();

  const asset = useSelector(assetSelector);
  const { term } = useSelector(loanDetailsSelector);

  const assetTypesList =
    applicationType === "commercial" ? assetTypes : consumerAssetTypes;

  const {
    _id,
    assetValue,
    ageOfAsset = "",
    assetType = "",
    make = "",
    model = "",
    typeOfSale = "",
  } = asset;

  const [fieldErrors, setFieldErrors] = useState({
    assetValue: "",
    make: "",
    model: "",
  });

  const [allValues, setAllValue] = useState({
    assetValue: assetValue || "",
    assetTypeValue: assetType || "",
    ageOfAssetValue: ageOfAsset || "",
    make: make || "",
    model: model || "",
    typeOfSaleValue: typeOfSale || "",
  });

  const validationSchema = Yup.object({
    assetValue: Yup.string()
      .required("Asset value is required")
      .matches(
        regex.allowOnlyNumber,
        "Only numbers and decimal points allowed."
      )
      .min(3, "Minimum of 3 characters")
      .max(7, "Maximum of 7 characters"),
    model: Yup.string()
      .optional()
      .matches(
        regex.alphaNumericAndSpecificChar,
        "Only letters, numbers and . ! / ( ) - + characters allowed."
      )
      .max(50, "Maximum of 50 characters"),
    make: Yup.string()
      .optional()
      .matches(
        regex.alphaNumericAndSpecificChar,
        "Only letters, numbers and . ! / ( ) - + characters allowed."
      )
      .max(50, "Maximum of 50 characters"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    updateAsset: async (value) => {
      dispatch(saveAsset(value));
      dispatch(updateAsset({ _id, ...value }));
    },
    ageOfAsset: (newValue) => {
      const termInYears = term / 12;
      const ageOfAssetAtEnd = moment()
        .add(termInYears, "years")
        .diff(moment(newValue, "YYYY"), "years");
      dispatch(updateAsset({ _id, ageOfAsset: newValue, ageOfAssetAtEnd }));
    },
    onChangeField: async (e, name) => {
      let isValid = true;

      if (name === "model" || name === "make") {
        isValid = regex.alphaNumericAndSpecificChar.test(e) && e?.length <= 50;
      } else if (name === "assetValue") {
        isValid = (e === "" || regex.allowOnlyNumber.test(e)) && e?.length <= 7;
      }

      if (!isValid) {
        await validateField(name, e);
        return;
      } else {
        setAllValue({ ...allValues, [name]: e });
        await validateField(name, e);
      }
    },
    blurFn: async (fieldName, value) => {
      try {
        if (fieldErrors[fieldName] === "")
          handle.updateAsset({ [fieldName]: value });
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
  };

  return (
    <Grid
      container
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={12} sm={12} md={2}>
        <DirectionsCarIcon style={{ marginRight: "10px" }} />
        <Typography>Asset</Typography>
      </Grid>

      <Grid
        container
        xs={12}
        sm={12}
        md={10}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ margin: "0 0 0px" }}
        >
          <Grid item md={3} sm={3}>
            <TextField
              id={"assetValue"}
              fullWidth
              variant="filled"
              type="text"
              name="assetValue"
              label="Asset value"
              size="small"
              value={allValues?.assetValue}
              error={fieldErrors?.assetValue}
              helperText={fieldErrors?.assetValue}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "assetValue")
              }
              onBlur={(e) => handle.blurFn("assetValue", e.target.value)}
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="filled"
              style={{ background: "#ffffff" }}
              select
              value={allValues?.assetTypeValue}
              label="Asset type"
              name="assetTypeValue"
              size="small"
              onChange={(event) => {
                handle.onChangeField(event?.target?.value, "assetTypeValue");
                handle.updateAsset({ assetType: event.target.value });
              }}
            >
              {assetTypesList?.map((asset, i) => (
                <MenuItem value={asset.label}>{asset.label}</MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item md={3} sm={3} xs={12}>
            <TextField
              fullWidth
              variant="filled"
              style={{ background: "#ffffff" }}
              select
              value={allValues?.typeOfSaleValue}
              name="typeOfSaleValue"
              label="Type of sale"
              size="small"
              onChange={(event) => {
                handle.onChangeField(event?.target?.value, "typeOfSaleValue");
                handle.updateAsset({ typeOfSale: event.target.value });
              }}
            >
              {Object.keys(typeOfSaleList)
                .map((item, i) => {
                  if (i === 0)
                    return { label: typeOfSaleList[item], default: true };
                  return { label: typeOfSaleList[item] };
                })
                .map((asset, i) => (
                  <MenuItem default={asset.default} value={asset.label}>
                    {asset.label}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          direction="row"
          style={{ margin: "0 0 0px" }}
        >
          <Grid item md={3} sm={12} xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="text"
              name="make"
              label="Make"
              value={allValues?.make}
              error={fieldErrors?.make}
              helperText={fieldErrors?.make}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "make")
              }
              onBlur={(e) => handle.blurFn("make", e.target.value)}
              variant="filled"
              size="small"
            // inputProps={{ maxLength: 7 }}
            />
            {/* <TextField
              fullWidth
              variant="filled"
              style={{ background: "#ffffff" }}
              select
              value={allValues?.make}
              name="make"
              label="Make"
              size="small"
              onChange={(event) => {
                handle.onChangeField(event?.target?.value, "make");
                handle.updateAsset({ make: event.target.value });
              }}
            >
              {Object.keys(makeType)
                .map((item, i) => {
                  if (i === 0)
                    return { label: makeType[item], default: true };
                  return { label: makeType[item] };
                })
                .map((asset, i) => (
                  <MenuItem default={asset.default} value={asset.label}>
                    {asset.label}
                  </MenuItem>
                ))}
            </TextField> */}
          </Grid>

          <Grid item md={6} sm={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="text"
              name="model"
              label="Model"
              value={allValues?.model}
              error={fieldErrors?.model}
              helperText={fieldErrors?.model}
              onChange={(event) =>
                handle.onChangeField(event?.target?.value, "model")
              }
              onBlur={(e) => handle.blurFn("model", e.target.value)}
              variant="filled"
              size="small"
            />
          </Grid>

          <Grid item md={3} sm={12} xs={12}>
            <Autocomplete
              value={allValues?.ageOfAssetValue?.toString()}
              options={generateArrayOfYears()}
              onChange={(event, newValue) => {
                handle.onChangeField(newValue, "ageOfAssetValue");
                handle.ageOfAsset(newValue);
              }}
              onKeyPress={(e) => checkAlphabetRestrict(e, "ageOfAssetValue")}
              size="small"
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label="Year of manufacture"
                  variant="filled"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Asset;
