import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  applicationsSelector,
  getAllApplications,
  saveValue,
} from "src/store/slices/applicationsSlice";
import _ from "lodash";
import { useEffect } from "react";
import { userSelector } from "src/store/slices/userSlice";
import { useNavigate, userNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import DataGridTable from "../dataGridTable/DataGridTable";

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

const masterViewColumns = [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: "humanId",
    headerName: "ID",
    type: "string",
    width: 90,
    editable: false,
  },
  {
    field: "startDate",
    headerName: "Date",
    type: "string",
    width: 80,
    editable: false,
    renderCell: (params) => (
      <div>
        <Typography style={{ fontSize: "14px" }}>
          {params.value.date}
        </Typography>
        <Typography style={{ fontSize: "11px", color: "#999" }}>
          {params.value.time}
        </Typography>
      </div>
    ),
  },
  // {
  //   field: "time",
  //   headerName: "Time",
  //   type: "string",
  //   width: 80,
  //   editable: false,
  // },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    width: 100,
    editable: false,
  },
  {
    field: "applicationType",
    headerName: "Application Type",
    type: "string",
    width: 110,
    editable: false,
  },
  {
    field: "broker",
    headerName: "Broker",
    type: "string",
    width: 120,
    editable: false,
    renderCell: (params) => (
      <div>
        <Typography style={{ fontSize: "14px" }}>{params.value.firstName} {params.value.lastName}</Typography>
        <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.org}</Typography>
      </div>
    ),
  },
  {
    field: "assetType",
    headerName: "Asset type",
    type: "string",
    width: 200,
    editable: false,
    renderCell: (params) => (
      <div>
        <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
          {params.value}
        </Typography>
      </div>
    ),
  },
  {
    field: "assetValue",
    headerName: "Asset value",
    type: "string",
    width: 110,
    editable: false,
  },
  {
    field: "customer",
    headerName: "Customer",
    width: 220,
    editable: false,
    renderCell: (params) => (
      <div>
        <Typography style={{ fontSize: "14px", textWrap: "initial" }}>
          {params.value}
        </Typography>
      </div>
    ),
  },
  // {
  //   field: "entityName",
  //   headerName: "Entity",
  //   width: 250,
  //   // editable: true,
  // },
  // {
  //   field: "firstName",
  //   headerName: "First name",
  //   width: 150,
  //   // editable: true,
  // },
  // {
  //   field: "lastName",
  //   headerName: "Last name",
  //   width: 150,
  //   // editable: true,
  // },

  // {
  //   field: "term",
  //   headerName: "Term",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },
  // {
  //   field: "serviceFees",
  //   headerName: "3rd Party fees",
  //   type: "string",
  //   width: 110,
  //   editable: true,
  // },
];

export default function QuotesList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const { applications, page, isLoading } = useSelector(applicationsSelector);

  console.log(applications)

  const [rows, setRows] = useState([]);

  const [params, setParams] = useState({ offset: 1 })

  const handle = {
    getAllApplicationsFn: (userId, cursor) => {
      dispatch(getAllApplications({ userId, cursor }));
    },
  };

  useEffect(() => {
    if (params?.offset) {
      handle.getAllApplicationsFn(user, params?.offset);
      dispatch(saveValue({ page: params?.offset }));
    }
  }, [params?.offset])

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(
    applications?.totalDocs || 0
  );

  useEffect(() => {
    setRowCountState(
      (prevRowCountState) => applications?.totalDocs ?? prevRowCountState
    );
  }, [applications?.totalDocs]);

  useEffect(() => {
    if (user) handle.getAllApplicationsFn(user, page || 1);
  }, [user]);

  useEffect(() => {
    const transformApplication = (doc) => {
      const assetValueCheck = doc?.asset?.assetValue ? _.get(
        doc,
        "asset.assetValue",
      ).toLocaleString("en-US", dollarStringOptions) : 'No Value';

      const date = new Date(_.get(doc, "createdAt"));
      const entityName = _.get(doc, "entities[0].entityName");
      const firstName = _.get(doc, "customers[0].firstName", "No");
      const lastName = _.get(doc, "customers[0].lastName", "customer");
      const assetType = _.get(doc, "asset.assetType", "Type not entered");
      const assetValue = assetValueCheck;

      const capitalizedStatus = _.get(doc, "status", "").charAt(0).toUpperCase() + _.get(doc, "status", "").slice(1);
      const capitalizedApplicationType = _.get(doc, "applicationType", "").charAt(0).toUpperCase() + _.get(doc, "applicationType", "").slice(1);

      return _.pickBy(
        {
          humanId: _.get(doc, "humanId"),
          customer: entityName ? entityName : `${firstName} ${lastName}`,
          applicationType: capitalizedApplicationType,
          assetType,
          assetValue,
          loanAmount: _.get(doc, "loanDetails.loanAmount"),
          term: _.get(doc, "loanDetails.term"),
          id: _.get(doc, "_id"),
          startDate: {
            date: moment(date).format("D-M-YY"),
            time: moment(date).format("h:mm a"),
          },
          broker: {
            firstName: _.get(doc, "user.firstName"),
            lastName: _.get(doc, "user.lastName"),
            org: _.get(doc, "user.organisation.name")
          },
          status: capitalizedStatus,
        },
        _.identity
      );
    };

    const transformedRows = _.map(_.get(applications, "docs"), transformApplication);
    setRows(transformedRows);
    // time: moment(date).format("h:mm a"),

  }, [applications]);

  return (
    <Grid
      container
      item
      direction="column"
      spacing={0}
      style={{ padding: "20px" }}
    // style={{ marginTop: "64px", }}
    >
      <Grid item>
        <Typography
          variant="h1"
          style={{
            fontSize: "24px",
            fontWeight: 800,
            letterSpacing: "-0.5px",
            marginBottom: "10px",
            paddingBottom: "10px",
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          Applications
        </Typography>
      </Grid>
      <Grid item>

        <DataGridTable
          data={rows || []}
          columns={masterViewColumns}
          onRowClick={(event) => {
            navigate(`/application/${event.id}`);
          }}
          rowsPerPageOptions={[10]}
          pageSize={10}
          rowCount={rowCountState}
          params={params}
          setParams={setParams}
          page={page}
          isLoading={isLoading}
        />

        {/* <DataGrid
          autoHeight
          autoPageSize
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          onRowClick={(event) => {
            console.log(event);
            navigate(`/application/${event.id}`);
          }}
          rows={rows || []}
          columns={masterViewColumns}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          style={{ background: "#ffffff", border: "none" }}
          pagination
          pageSize={10}
          rowCount={rowCountState}
          paginationMode="server"
          onPageChange={handle.pageChange}
          page={page - 1 || 0}
          loading={isLoading}
          sx={{
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              width: "7px",
              height: "7px",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
              backgroundColor: "#999",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
              background: "#616161",
            },
          }}
        /> */}
      </Grid>
    </Grid>
  );
}
