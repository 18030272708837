import criteria from "src/utils/criteria";
import { livingArrangementsTypes, typeOfSaleList } from "src/constants";

const { PROPERTY_OWNER, RENTING, BOARDING, LIVING_WITH_PARENTS } =
  livingArrangementsTypes;

const {
  nonGST,
  typeOfSale,
  hasDeposit,
  assetValueBetween,
  assetType,
  ageOfAssetAtEndGreaterThan,
  ageOfAssetAtEndLessThan,
  ageOfAssetAtEndBetween,
  loanAmountLessThan,
  loanAmountBetween,
  timeInBusinessBetween,
  timeInBusinessLessThan,
  timeInBusinessGreaterThan,
  gstRegistrationLessThan,
  gstRegistrationGreaterThan,
  gstRegistrationBetween,
  creditScoreGreaterThan,
  livingArrangements,
  creditScoreLessThan,
} = criteria;

const Auto = [
  "Motor Vehicle (<4.5 tonnes)",
  "Electric Motor Vehicle (<4.5 tonnes)",
];
const AGrade = [
  "Commercial (Small to Medium) Vehicles (<12 Tonnes)",
  "Commercial (Heavy) Vehicles (>12 Tonnes)",
  "Earth Moving & Construction (wheeled or tracked)",
  "Agricultural & Landscaping Equipment (wheeled or tracked)",
  "Trailers and Caravans",
  "Warehouse Equipment (wheeled or tracked)",
  "Mini Buses & Route Buses (<25 seats)",
];
const BGrade = [
  "Medical Equipment",
  "Industrial Plant & Printing",
  "Manufacturing Equipment",
];

const CGrade = [
  "IT, Computer & Audio Equipment",
  "Office Equipment",
  "Office Furniture",
  "Restaurant & Catering Equipment",
  "Health & Beauty Equipment",
  "Solar",
];

function property(l) {
  return l.map((product) => {
    return {
      ...product,
      criteria: [...product.criteria, livingArrangements([PROPERTY_OWNER])],
    };
  });
}

function streamlineNonProperty(l) {
  return l.map((product) => {
    return {
      ...product,
      rate: product.rate + 0.5,
      criteria: [
        ...product.criteria,
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      ],
    };
  });
}

function kickstarterNonProperty(l) {
  return l.map((product) => {
    return {
      ...product,
      rate: product.rate + 1,
      criteria: [
        ...product.criteria,
        hasDeposit(10),
        livingArrangements([RENTING, BOARDING, LIVING_WITH_PARENTS]),
      ],
    };
  });
}

export default {
  date: "01 Jul 2023",
  lender: "Azora Asset Finance",
  productName: "STREAMLINE",
  type: "commercial",
  logoURL: "https://assets.financeable.com.au/lender-logos%2Fazora-logo.png",
  fees: [
    {
      name: "Document fee",
      value: 750,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Private sale")],
    },
    {
      name: "Document fee",
      value: 550,
      capitalised: true,
      frequency: "upfront",
      criteria: [typeOfSale("Authorised seller")],
    },
  ],
  brokerage: [
    {
      value: 4,
      max: 10,
      criteria: [assetValueBetween(10000, 35000)],
    },
    {
      value: 4,
      max: 8,
      criteria: [assetValueBetween(35000, 50000)],
    },
    {
      value: 4,
      max: 6,
      criteria: [assetValueBetween(50000, 150000)],
    },
  ],
  loading: [
    {
      name: "Grade C Asset",
      value: 4,
      valueType: "percentage",
      criteria: [assetType(CGrade)],
    },
    {
      name: "Asset age at EOT >10 yrs",
      value: 1,
      valueType: "percentage",
      criteria: [
        assetType([...AGrade, ...BGrade]),
        ageOfAssetAtEndBetween(10, 15),
      ],
    },
    {
      name: "Asset age at EOT >15 yrs",
      value: 2,
      valueType: "percentage",
      criteria: [
        assetType([...AGrade, ...BGrade]),
        ageOfAssetAtEndBetween(15, 20),
      ],
    },
    {
      name: "Asset age at EOT >20 yrs",
      value: 2.5,
      valueType: "percentage",
      criteria: [
        assetType([...AGrade, ...BGrade]),
        ageOfAssetAtEndGreaterThan(20),
      ],
    },
    {
      name: "No GST registration",
      value: 0.5,
      valueType: "percentage",
      criteria: [nonGST],
    },
    {
      name: "GST registration <12 months ",
      value: 0.5,
      valueType: "percentage",
      criteria: [gstRegistrationLessThan(12)],
    },
    {
      name: "Private sale",
      value: 1,
      capitalised: true,
      valueType: "percentage",
      criteria: [typeOfSale(typeOfSaleList.PRIVATE_SALE)],
    },
  ],
  info: [
    `Existing credit required for Streamlined`,
    `Time in Industry >24 for Kickstarter`,
    `Borrowers under 25yrs for Kickstarter may require additional information`,
  ],
  productTiers: [
    ...property(baseStreamline()),
    ...streamlineNonProperty(baseStreamline()),
    ...property(baseKickstarter()),
    ...kickstarterNonProperty(baseKickstarter()),
    {
      name: "Adverse - Financial default >24 months",
      rate: 17.75,
      productType: "Adverse",
      info: [
        `Comparable credit`,
        `Financial defaults must be >24 months ago`,
        `Non-Financial defaults must be >12 months ago`,
        `Bank statements required for last 6 mths`,
      ],
      criteria: [
        loanAmountBetween(20000, 85000),
        timeInBusinessGreaterThan(48),
        gstRegistrationGreaterThan(24),
        assetType([...Auto, ...AGrade, ...BGrade]),
        ageOfAssetAtEndLessThan(16),
        creditScoreGreaterThan(0),
        hasDeposit(25),
      ],
    },
    {
      name: "Adverse - Financial default >24 months",
      rate: 18.75,
      productType: "Adverse",
      info: [
        `Comparable credit`,
        `Financial defaults must be >24 months ago`,
        `Non-Financial defaults must be >12 months ago`,
        `Bank statements required for last 6 mths`,
      ],
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(48),
        gstRegistrationGreaterThan(24),
        assetType([...Auto, ...AGrade, ...BGrade]),
        ageOfAssetAtEndLessThan(16),
        creditScoreGreaterThan(0),
        hasDeposit(25),
      ],
    },

    {
      name: "Adverse - Non financial default >12 months",
      rate: 17.75,
      productType: "Full doc",
      info: [
        `Comparable credit`,
        `Financial defaults must be >24 months ago`,
        `Non-Financial defaults must be >12 months ago`,
        `Bank statements required for last 6 months`,
      ],
      criteria: [
        loanAmountBetween(20000, 85000),
        timeInBusinessGreaterThan(48),
        gstRegistrationGreaterThan(12),
        assetType([...Auto, ...AGrade, ...BGrade]),
        ageOfAssetAtEndLessThan(16),
        creditScoreGreaterThan(0),
        hasDeposit(15),
      ],
    },
    {
      name: "Adverse - Non financial default >12 months",
      rate: 18.75,
      productType: "Full doc",
      info: [
        `Comparable credit`,
        `Financial defaults must be >24 months ago`,
        `Non-Financial defaults must be >12 months ago`,
        `Bank statements required for last 6 mths`,
      ],
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(48),
        gstRegistrationGreaterThan(12),
        assetType([...Auto, ...AGrade, ...BGrade]),
        ageOfAssetAtEndLessThan(16),
        creditScoreGreaterThan(0),
        hasDeposit(15),
      ],
    },
  ],
};

function baseStreamline() {
  return [
    {
      name: "STREAMLINE",
      rate: 9.8,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 100000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(21),
        assetType(Auto),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "STREAMLINE",
      rate: 9.99,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(50001, 150000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(24),
        ageOfAssetAtEndLessThan(21),
        assetType([...AGrade, ...BGrade]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "STREAMLINE",
      rate: 10.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30001, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(6),
        assetType(Auto),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "STREAMLINE",
      rate: 11.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30001, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(6),
        assetType([...AGrade, ...BGrade]),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "STREAMLINE",
      rate: 15.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(30001, 50000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(6),
        assetType(CGrade),
        creditScoreGreaterThan(550),
        ageOfAssetAtEndLessThan(11),
      ],
    },


    {
      name: "STREAMLINE",
      rate: 10.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
        assetType(Auto),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "STREAMLINE",
      rate: 14.25,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
        assetType(AGrade, BGrade),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "STREAMLINE",
      rate: 16.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(10000, 20000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
        assetType(CGrade),
        creditScoreGreaterThan(550),
        ageOfAssetAtEndLessThan(11),
      ],
    },


    {
      name: "STREAMLINE",
      rate: 10.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 30000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
        assetType(Auto),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "STREAMLINE",
      rate: 11.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 30000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
        assetType(AGrade, BGrade),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "STREAMLINE",
      rate: 15.75,
      productType: "Low doc",
      criteria: [
        loanAmountBetween(20000, 30000),
        timeInBusinessGreaterThan(24),
        gstRegistrationGreaterThan(0),
        assetType(CGrade),
        creditScoreGreaterThan(550),
        ageOfAssetAtEndLessThan(11),
      ],
    },
  ];
}

function baseKickstarter() {
  return [
    {
      name: "KICKSTARTER",
      rate: 12.75,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(20000, 50000),
        timeInBusinessBetween(6, 24),
        gstRegistrationGreaterThan(6),
        assetType(Auto),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "KICKSTARTER",
      rate: 13.25,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(20000, 50000),
        timeInBusinessBetween(6, 24),
        gstRegistrationGreaterThan(6),
        assetType([...AGrade, ...BGrade]),
        creditScoreGreaterThan(550),
      ],
    },

    {
      name: "KICKSTARTER",
      rate: 12.75,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(10000, 30000),
        timeInBusinessBetween(6, 24),
        gstRegistrationLessThan(0),
        assetType(Auto),
        creditScoreGreaterThan(550),
      ],
    },
    {
      name: "KICKSTARTER",
      rate: 14.75,
      productType: "Light doc",
      criteria: [
        loanAmountBetween(10000, 30000),
        timeInBusinessBetween(6, 24),
        gstRegistrationGreaterThan(0),
        assetType([...AGrade, ...BGrade]),
        creditScoreGreaterThan(550),
      ],
    },
  ];
}
